import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { ICompany } from '../CompanyWizard/types'
import { del, get, patch, post, upload } from '../../utils/api'
import type { IAnalytics, IPayment, IPlan, IPromoCode } from './types'
import type { ICompanyProperty } from './types'
import type { IInvitation } from './types'
import type { IEvent } from '../Events/types.ts'
import type { IImage } from '../AdvertisingWizard/types.ts'

export const fetchCompany = (
    company_id: string
): Promise<AxiosResponse<ICompany>> =>
    get<ICompany>(`/admin/companies/${company_id}`, {
        include:
            'businessAccount,businessAccount.user,businessAccount.trialPeriod,businessAccount.planSubscriptions,companyPropertyOptionValues,companyPropertyOptionValues.companyProperty,activePlanSubscription,companyTimetables,mainImage,logo,franchisee,dadataArea,companyTags,updatedByUser,events',
        append: 'summary_status,on_mamado,cached_displayed'
    })

export const fetchCompanyEvents = (
    page: number,
    pageSize: number,
    companyId: string
): Promise<AxiosResponse<IEvent[]>> =>
    get<IEvent[]>('/admin/events', {
        page,
        page_size: pageSize,
        include: 'mainImage',
        sort: '-created_at',
        'filter[company_id]': companyId
    })

export const saveCompany = (
    company_id: string,
    form: Record<string, unknown>
): Promise<AxiosResponse<ICompany>> =>
    patch<ICompany>(`/admin/companies/${company_id}`, form)

export const fetchAnalyticsCompany = (
    company_id: string,
    date_start: string,
    date_end: string
): Promise<AxiosResponse<IAnalytics[]>> =>
    get<IAnalytics[]>('/admin/analytics/company', {
        ids: company_id,
        date_start,
        date_end
    })

export const fetchAnalyticsEvents = (
    event_ids: string,
    date_start: string,
    date_end: string
): Promise<AxiosResponse<IAnalytics[]>> =>
    get<IAnalytics[]>('/admin/analytics/event', {
        ids: event_ids,
        date_start,
        date_end
    })

export const fetchPlanSubscriptions = (
    company_id: string,
    page: number
): Promise<AxiosResponse<IAnalytics[]>> =>
    get<IAnalytics[]>(`/admin/companies/${company_id}/plan-subscriptions`, {
        page,
        include: 'plan'
    })

export const deletePlanSubscriptions = (
    company_id: string,
    plan_subscription_id: string
): Promise<AxiosResponse<void>> =>
    del<void>(
        `/admin/companies/${company_id}/plan-subscriptions/${plan_subscription_id}`
    )

export const createCompanyProperty = (
    company_id: string,
    company_property_id: string,
    selected_value: string
): Promise<AxiosResponse<ICompanyProperty>> =>
    post<ICompanyProperty>(`/admin/companies/${company_id}/properties`, {
        company_property_id,
        selected_value
    })

export const changeCompanyProperty = (
    company_id: string,
    company_property_id: string,
    company_property_option_id: string,
    selected_value: string
): Promise<AxiosResponse<ICompanyProperty>> =>
    patch<ICompanyProperty>(
        `/admin/companies/${company_id}/properties/${company_property_option_id}`,
        {
            company_property_id,
            selected_value
        }
    )

export const clearCompanyProperty = (
    company_id: string,
    company_property_option_id: string
): Promise<AxiosResponse<ICompanyProperty>> =>
    del<ICompanyProperty>(
        `/admin/companies/${company_id}/properties/${company_property_option_id}`
    )

export const changeCompanyOwner = (
    company_id: string,
    business_account_id: string
): Promise<AxiosResponse<ICompany>> =>
    post<ICompany>(`/admin/companies/${company_id}/owner`, {
        business_account_id,
        _method: 'patch'
    })

export const changeCompanyFranchisee = (
    company_id: string,
    franchisee_id: string | null
): Promise<AxiosResponse<ICompany>> =>
    patch<ICompany>(`/admin/companies/${company_id}`, { franchisee_id })

export const addPlanSubscribe = (
    company_id: string,
    plan: string,
    start: string,
    end: string,
    comment: string
): Promise<AxiosResponse<ICompany>> =>
    post<ICompany>(`/admin/companies/${company_id}/plan-subscriptions`, {
        plan,
        start,
        end,
        comment
    })

export const fetchPaymentHistory = (
    company_id: string,
    page: number,
    perPage: number
): Promise<AxiosResponse<IAnalytics[]>> =>
    get<IAnalytics[]>('/admin/order-products', {
        'filter[company_id]': company_id,
        page,
        count: perPage
    })

export const fetchInvitation = (
    company_id: string
): Promise<AxiosResponse<IInvitation>> =>
    get<IInvitation>(`/companies/${company_id}/invitation`)

export const createInvitation = (
    company_id: string,
    invitation_text: string
): Promise<AxiosResponse<IInvitation>> =>
    post<IInvitation>(`/companies/${company_id}/invitation`, {
        invitation_text
    })

export const createShortDynamicLink = (
    refCode: string,
    companySlug: string,
    companyName: string,
    companyAbout: string,
    companyImage: string
): Promise<AxiosResponse<void>> =>
    axios.post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.FIREBASE_API_KEY}`,
        {
            dynamicLinkInfo: {
                domainUriPrefix:
                    process.env.APP_ENV === 'staging'
                        ? 'https://r1.mamado.su'
                        : 'https://r.mamado.su',
                link: `https://mamado.su/${companySlug}?ref=${refCode}`,
                androidInfo: {
                    androidPackageName: 'com.elgrow.mamado'
                },
                iosInfo: {
                    iosBundleId: 'com.elgrow.appmamado',
                    iosAppStoreId: '1479653755'
                },
                socialMetaTagInfo: {
                    socialTitle: companyName,
                    socialDescription: companyAbout.slice(0, 700),
                    socialImageLink: companyImage
                }
            },
            suffix: {
                option: 'SHORT'
            }
        },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )

export const updateInvitation = (
    company_id: string,
    dynamic_code: string
): Promise<AxiosResponse<IInvitation>> =>
    patch<IInvitation>(`/companies/${company_id}/invitation`, {
        dynamic_code
    })

export const fetchPlan = (): Promise<AxiosResponse<IPlan[]>> =>
    get<IPlan[]>('/plan')

export const sendPromoCode = (
    code: string
): Promise<AxiosResponse<IPromoCode>> =>
    get<IPromoCode>('/promocodes', { code, append: 'canRepay' })

export const getPayment = (
    plan_id: string,
    companies: string[],
    promocode_id?: string
): Promise<AxiosResponse<IPayment>> =>
    post<IPayment>('/v2/plan/buy', { plan_id, companies, promocode_id })

export const uploadLogo = (
    company_id: string,
    image: File
): Promise<AxiosResponse<void>> =>
    upload(
        `/admin/companies/${company_id}/media/logo`,
        [{ field: 'image', file: image }],
        undefined,
        undefined,
        true
    )

export const deleteLogo = (company_id: string): Promise<AxiosResponse<void>> =>
    del(`/admin/companies/${company_id}/media/logo`)

export const removeOwner = (company_id: string): Promise<AxiosResponse<void>> =>
    patch(`/admin/companies/${company_id}/owner`, { business_account_id: null })

export const uploadMediaImage = (image: File): Promise<AxiosResponse<IImage>> =>
    upload<IImage>(
        '/files',
        [
            {
                field: 'file',
                file: image
            }
        ],
        undefined,
        undefined,
        true
    )

export const clearAdminMessage = (
    company_id: string
): Promise<AxiosResponse<ICompany>> =>
    patch<ICompany>(`/admin/companies/${company_id}`, { admin_message: '' })

export const paymentFromBalance = (
    plan_id: string,
    companies: string[]
): Promise<AxiosResponse<void>> =>
    post<void>('/admin/plan/buy-from-balance', {
        plan_id,
        companies
    })

export const getCompanyLeadCoast = (
    company_id: string
): Promise<AxiosResponse<void>> =>
    get<void>(`/admin/lead-prices/price-by-company?company_id=${company_id}`)
