import type { IForm } from './types.ts'
import type { AxiosResponse } from 'axios'
import { get, patch, post } from '../../utils/api.ts'

export const fetch = (lead_id: string): Promise<AxiosResponse<void>> =>
    get(`/admin/leads/${lead_id}`, { include: ['company', 'event'] })

export const create = (form: IForm): Promise<AxiosResponse<void>> =>
    post('/admin/leads', { ...form })

export const edit = (
    lead_id: string,
    form: IForm
): Promise<AxiosResponse<void>> => patch(`/admin/leads/${lead_id}`, { ...form })

export const pay = (lead_id: string): Promise<AxiosResponse<void>> =>
    post('/admin/leads/pay', { lead_ids: [lead_id] })
