import { useEffect, useState } from 'react'
import { Button, Table, Modal, Row, Col, Form, Input } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { DeleteOutlined } from '@ant-design/icons'
import NextStepButton from '../../../../components/NextStepButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarthAmericas, faQuestion } from '@fortawesome/free-solid-svg-icons'
import {
    faVk,
    faYoutube,
    faOdnoklassniki,
    faGooglePlay,
    faAppStore
} from '@fortawesome/free-brands-svg-icons'
import TitleStep from '../../../../components/TitleStep'
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companyWizardSelector } from '../../selectors'
import styles from './styles.module.scss'
import isUrl from 'is-url'
import CustomEmpty from '../../../../components/CustomEmpty'

interface DataType {
    icon: IconDefinition
    value: string
    color: string
    type: string
    onRemove: (indexRemove: number) => void
}

const types = [
    {
        type: 'site',
        icon: faEarthAmericas,
        color: '#55B23A',
        name: 'Сайт',
        placeholder: 'http://'
    },
    {
        type: 'vk',
        icon: faVk,
        color: '#4F8ED5',
        name: 'Вконтакте',
        placeholder: 'https://vk.com/'
    },
    // {
    //     type: 'instagram',
    //     icon: faInstagram,
    //     color: '#7a44c2',
    //     name: 'Instagram',
    //     placeholder: 'Введите учетную запись'
    // },
    {
        type: 'youtube',
        icon: faYoutube,
        color: '#EE4141',
        name: 'YouTube',
        placeholder: 'https://www.youtube.com/c/'
    },
    {
        type: 'ok',
        icon: faOdnoklassniki,
        color: '#FF9800',
        name: 'Одноклассники',
        placeholder: 'https://ok.ru/profile/'
    },
    // {
    //     type: 'facebook',
    //     icon: faFacebook,
    //     color: '#4D78CF',
    //     name: 'Facebook',
    //     placeholder: 'https://facebook.com/'
    // },
    {
        type: 'other',
        icon: faQuestion,
        color: '#777',
        name: 'Другая',
        placeholder: 'http://'
    },
    {
        type: 'google-play',
        icon: faGooglePlay,
        color: '#ff312f',
        name: 'Google Play',
        placeholder: 'http://'
    },
    {
        type: 'app-store',
        icon: faAppStore,
        color: '#197cf4',
        name: 'App Store',
        placeholder: 'http://'
    }
]

function formatUrl(value: string): string {
    if (value.startsWith('http')) {
        return value
    }
    return `http://${value}`
}

export default function NetworkStep() {
    const [socialContacts, setSocialContacts] = useState<DataType[]>([])
    const [isModal, setIsModal] = useState(false)
    const [selectedType, setSelectedType] = useState(0)
    const [content, setContent] = useState('')
    const [editIndex, setEditIndex] = useState<false | number>(false)

    const currentType = types[selectedType]

    const { currentCompany, hasStepChanges, isSaving } = useAppSelector(
        companyWizardSelector
    )

    const dispatch = useDispatch()

    const handleRemove = (indexRemove: number) => {
        setSocialContacts(prev =>
            prev.filter((_, index) => index !== indexRemove)
        )
    }

    useEffect(() => {
        if (currentCompany) {
            const filteredCompanyContacts =
                currentCompany.companyContacts.filter(item =>
                    types.some(type => type.type === item.type)
                )

            const hasChanges =
                socialContacts.length !== filteredCompanyContacts.length ||
                !socialContacts.every(item =>
                    filteredCompanyContacts.some(
                        companyContact =>
                            companyContact.type === item.type &&
                            companyContact.content === item.value
                    )
                )

            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentCompany, socialContacts])

    const handleUndoChanges = () => {
        if (currentCompany) {
            const result: DataType[] = []

            for (const item of currentCompany.companyContacts) {
                const currentItem = types.find(type => type.type === item.type)

                if (currentItem) {
                    result.push({
                        icon: currentItem.icon,
                        value: item.content,
                        color: currentItem.color,
                        type: item.type,
                        onRemove: handleRemove
                    })
                }
            }

            setSocialContacts(result)
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleCloseModal = (): void => {
        setSelectedType(0)
        setContent('')
        setIsModal(false)
        setEditIndex(false)
    }

    const handleSaveCommunication = (isTrusted: boolean) => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        social_contacts: socialContacts.map(item => ({
                            type: item.type,
                            content: item.value
                        }))
                    },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Тип',
            dataIndex: '',
            key: 'icon',
            align: 'center',
            render: data => (
                <FontAwesomeIcon icon={data.icon} color={data.color} />
            )
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            width: '100%',
            render: (value, data, index) => (
                <span
                    onClick={() => {
                        setContent(data.value)
                        setSelectedType(
                            types.findIndex(({ type }) => type === data.type)
                        )
                        setEditIndex(index)
                    }}
                    className={styles.network}
                >
                    {value}
                </span>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: 'remove',
            render: (data, _, index) => (
                <Button danger onClick={() => data.onRemove(index)}>
                    <DeleteOutlined />
                </Button>
            )
        }
    ]

    return (
        <>
            <TitleStep
                rightElement={
                    <Button onClick={() => setIsModal(true)}>
                        {'Добавить ссылку'}
                    </Button>
                }
            >
                {'Сайт и соц.сети'}
            </TitleStep>
            <Table
                columns={columns}
                dataSource={socialContacts}
                rowKey={record => record.value}
                pagination={false}
                locale={{
                    emptyText: (
                        <CustomEmpty description={'Контакты не добавлены'} />
                    )
                }}
            />
            <NextStepButton
                loading={isSaving}
                disabled={!hasStepChanges}
                onClick={handleSaveCommunication}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            <Modal
                title={
                    typeof editIndex === 'number'
                        ? 'Изменить ссылку'
                        : 'Добавить ссылку'
                }
                open={typeof editIndex === 'number' || isModal}
                onCancel={handleCloseModal}
                onOk={() => {
                    if (typeof editIndex === 'number') {
                        setSocialContacts(prev =>
                            prev.map((item, index) => {
                                if (index === editIndex) {
                                    return {
                                        icon: currentType.icon,
                                        value:
                                            currentType.type === 'instagram' &&
                                            !content.startsWith('http')
                                                ? `https://instagram.com/${content}`
                                                : formatUrl(content),
                                        color: currentType.color,
                                        type: currentType.type,
                                        onRemove: handleRemove
                                    }
                                }
                                return item
                            })
                        )
                    } else {
                        setSocialContacts(prev => [
                            ...prev,
                            {
                                icon: currentType.icon,
                                value:
                                    currentType.type === 'instagram' &&
                                    !content.startsWith('http')
                                        ? `https://instagram.com/${content}`
                                        : formatUrl(content),
                                color: currentType.color,
                                type: currentType.type,
                                onRemove: handleRemove
                            }
                        ])
                    }
                    handleCloseModal()
                }}
                okButtonProps={{
                    disabled:
                        currentType.type === 'instagram'
                            ? !isUrl(content) &&
                              !/^[\w](?!.*?\.{2})[\w.]{1,28}[\w]$/.test(content)
                            : !isUrl(content)
                }}
                okText={typeof editIndex === 'number' ? 'Изменить' : 'Добавить'}
            >
                <Row
                    wrap
                    justify={'center'}
                    gutter={8}
                    style={{ maxWidth: '70%', margin: '30px auto 22px' }}
                >
                    {types.map((item, index) => (
                        <Col
                            key={`type-${index}`}
                            span={4}
                            style={{ marginBottom: 8 }}
                        >
                            <Button
                                danger={selectedType === index}
                                style={
                                    selectedType === index
                                        ? {
                                              width: '100%',
                                              backgroundColor: '#FFF'
                                          }
                                        : { width: '100%' }
                                }
                                onClick={() => {
                                    setSelectedType(index)
                                    setContent('')
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={item.icon}
                                    color={item.color}
                                />
                            </Button>
                        </Col>
                    ))}
                </Row>
                <Form.Item label={currentType.name}>
                    <Input
                        value={content}
                        placeholder={currentType.placeholder}
                        style={{ background: '#FFF' }}
                        onChange={({ target }) => setContent(target.value)}
                    />
                </Form.Item>
            </Modal>
        </>
    )
}
