import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { SearchPayload } from './types.ts'

export function* handleFetch(
    action: PayloadAction<SearchPayload>
): SagaIterator {
    try {
        const { data } = yield call(api.fetchCompanies, action.payload.search)
        yield put(actions.searchSuccess({ companies: data.data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.searchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.search, handleFetch)
}

export default function* watchMyRequestsCompanySelector(): SagaIterator {
    yield all([fork(watchFetch)])
}
