import type { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import type { SelectProps } from 'antd'
import { Select } from 'antd'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { myRequestsOwnerSelector } from './selectors.ts'
import { useDebounce } from '../../hooks/useDebounce.ts'
import LoadingSelector from '../../components/LoadingSelector'

export default function MyRequestsOwnerSelector(
    props: SelectProps
): ReactElement {
    const [search, setSearch] = useState('')

    const dispatch = useDispatch()

    const debounceSearch = useDebounce(search, 600)

    const { owners, isFetching } = useAppSelector(myRequestsOwnerSelector)

    useEffect(() => {
        if (debounceSearch) {
            dispatch(actions.search({ search: debounceSearch }))
        }
    }, [debounceSearch])

    return (
        <Select
            {...props}
            allowClear={true}
            placeholder={'Начните вводить'}
            loading={isFetching}
            showSearch={true}
            searchValue={search}
            onSearch={setSearch}
            notFoundContent={
                isFetching ? <LoadingSelector /> : <>{'Не найдено'}</>
            }
            options={owners.map(owner => {
                const label = [owner.user.full_name, owner.phone, owner.email]
                    .filter(Boolean)
                    .join(', ')
                return {
                    label: label,
                    value: `${label}{d}${owner.businessAccountId}`
                }
            })}
        />
    )
}
