import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api.ts'
import type { IMeta } from '../../types'
import type { ICompany } from '../Companies/types.ts'

export const fetchCompanies = (
    search: string
): Promise<AxiosResponse<IMeta<ICompany>>> =>
    get('/admin/leads/company-autocomplete', {
        'filter[query]': search
    })
