import type { ReactElement } from 'react'
import { Empty } from 'antd'

interface Props {
    description?: string
    children?: ReactElement
}

export default function CustomEmpty({
    description = 'Нет данных',
    children
}: Props): ReactElement {
    return <Empty description={description}>{children}</Empty>
}
