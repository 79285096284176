import type { ReactElement } from 'react'
import { Pagination, Row, Table, Typography } from 'antd'
import styles from './styles.module.scss'
import type { ColumnsType } from 'antd/es/table'
import type { ICompany } from '../Companies/types.ts'
import AdvertisingReportFilter from '../../components/AdvertisingReportFilter'
import CustomEmpty from "../../components/CustomEmpty";

const { Title } = Typography

const columns: ColumnsType<ICompany> = [
    {
        title: 'Владелец',
        key: 'owner',
        width: 100
    },
    {
        title: 'Франчайзи',
        dataIndex: '',
        key: 'franchisee'
    },
    {
        title: 'Общая сумма пополнения кабинета',
        dataIndex: '',
        key: 'total_sum'
    },
    {
        title: 'Общая сумма открутки за период',
        dataIndex: '',
        key: 'total_sum_period'
    }
]

export default function AdvertisingReport(): ReactElement {
    return (
        <>
            <div style={{ marginBottom: 25 }} className={styles.container}>
                <Row justify={'space-between'}>
                    <Title level={3} style={{ margin: 0 }}>
                        {'Отчет пополнения'}
                    </Title>
                </Row>
                <div style={{ height: 25 }} />
                <AdvertisingReportFilter />
            </div>
            <div className={styles.container}>
                <Table
                    dataSource={[]}
                    columns={columns}
                    rowKey={''}
                    loading={false}
                    pagination={false}
                    locale={{
                        emptyText: <CustomEmpty />
                    }}
                    footer={() => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Pagination
                                showQuickJumper
                                // current={page}
                                // pageSize={pageSize}
                                // defaultCurrent={1}
                                // defaultPageSize={pageSize}
                                // total={meta?.total}
                                // onChange={handleChangePagination}
                            />
                        </div>
                    )}
                />
            </div>
        </>
    )
}
