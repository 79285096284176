export const RequestStatus = {
    new: 'Новая',
    processed: 'Обработана',
    in_progress: 'В работе',
    closed: 'Закрыта'
}

export const RequestStatusColor = {
    new: '#005ab7',
    processed: '#046e04',
    in_progress: '#ff9a12',
    closed: '#777'
}

export const TypeStatus = {
    promocode: 'Получение промокода',
    booking: 'Бронирование',
    purchase: 'Покупка'
}
