import type { ReactElement } from 'react'
import { useState } from 'react'
import { Button, InputNumber, Modal, Row, Space, Typography } from 'antd'
import {
    BankOutlined,
    DollarOutlined,
    MinusOutlined,
    PlusOutlined
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/Balance/slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { balanceSelector } from '../../containers/Balance/selectors.ts'

const { Text } = Typography

interface Props {
    visible: boolean
    onClose: () => void
}

export default function AddBalanceModal({
    visible,
    onClose
}: Props): ReactElement {
    const [amount, setAmount] = useState(5000)

    const { isFetchingPaymentUrl } = useAppSelector(balanceSelector)

    const dispatch = useDispatch()

    const handleAddAmount = (type: 'min' | 'plus') => {
        if (type === 'min') {
            setAmount(prev => (prev - 500 >= 5000 ? prev - 500 : 5000))
        } else {
            setAmount(prev => prev + 500)
        }
    }

    const handleFetchPaymentUrl = () => {
        dispatch(actions.fetchPaymentUrl({ amount: amount * 100 }))
    }

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            title={
                <>
                    <BankOutlined style={{ marginRight: 5 }} />
                    {'Пополнить баланс'}
                </>
            }
            onOk={handleFetchPaymentUrl}
            okText={'Пополнить'}
            okButtonProps={{
                icon: <DollarOutlined />,
                loading: isFetchingPaymentUrl
            }}
            zIndex={2000}
        >
            <Row style={{ margin: '20px 0' }} justify={'center'}>
                <Space style={{ marginBottom: 20 }}>
                    <Button
                        disabled={amount === 5000}
                        type={'primary'}
                        icon={<MinusOutlined />}
                        onClick={() => handleAddAmount('min')}
                    />
                    <InputNumber
                        style={{ width: 150 }}
                        value={amount}
                        min={5000}
                        max={10000000}
                        addonAfter={'₽'}
                        onChange={value => setAmount(value ?? 5000)}
                        formatter={value =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        }
                        onKeyDown={event => {
                            if (
                                !/[0-9]/.test(event.key) &&
                                event.key !== 'Backspace'
                            ) {
                                event.preventDefault()
                            }
                        }}
                        controls={false}
                    />
                    <Button
                        type={'primary'}
                        icon={<PlusOutlined />}
                        onClick={() => handleAddAmount('plus')}
                    />
                </Space>
                <Text type={'secondary'}>
                    {'Минимальная сумма пополнения 5 000 руб'}
                </Text>
            </Row>
        </Modal>
    )
}
