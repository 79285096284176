import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPlaceCompaniesPayload,
    FetchPlaceCompaniesSuccessPayload,
    FetchPlaceCompaniesFailurePayload,
    FetchCategoriesPayload,
    FetchCategoriesSuccessPayload,
    FetchCategoriesFailurePayload,
    CreateEventPayload,
    CreateEventSuccessPayload,
    CreateEventFailurePayload,
    FetchEventPayload,
    FetchEventSuccessPayload,
    FetchEventFailurePayload,
    SaveEventFailurePayload,
    SaveEventPayload,
    SaveEventSuccessPayload,
    UploadGalleryFailurePayload,
    UploadGalleryPayload,
    UploadGallerySuccessPayload,
    RemovePhotoFailurePayload,
    RemovePhotoPayload,
    RemovePhotoSuccessPayload,
    SortGalleryFailurePayload,
    SortGalleryPayload,
    SortGallerySuccessPayload,
    SaveVideoFailurePayload,
    SaveVideoPayload,
    SaveVideoSuccessPayload,
    RemoveVideoFailurePayload,
    RemoveVideoPayload,
    RemoveVideoSuccessPayload,
    FetchAddressFailurePayload,
    FetchAddressPayload,
    FetchAddressSuccessPayload,
    CroppingPhotoFailurePayload,
    CroppingPhotoPayload,
    CroppingPhotoSuccessPayload
} from './types'
import type { IPreCreateData } from './types'

const initialState: InitialStateType = {
    step: 0,
    hasStepChanges: false,
    isCreate: false,
    isProcessCreate: false,
    currentEvent: null,
    isFetchingEvent: false,
    isUploadPhoto: false,
    removingPhotos: [],
    removingVideos: [],
    isSortingGallery: false,
    isSavingVideo: false,
    defaultLeadCoast: null,
    preCreateData: {
        type: 'offline',
        companies: [],
        areas: [],
        publication_type: 'poster',
        name: '',
        age_category: 'all',
        age_min: 0,
        age_max: 18
    },
    placeCompanies: [],
    addresses: {},
    isFetchingAddress: false,
    isFetchingPlaceCompanies: false,
    categories: [],
    isFetchingCategories: false,
    isCroppingPhoto: false,
    isSaving: false,
    error: null
}

const eventWizardSlice = createSlice({
    name: 'eventWizard',
    initialState,
    reducers: {
        setCreateMode(state: InitialStateType, action: PayloadAction<boolean>) {
            state.isCreate = action.payload
        },
        setStep(state: InitialStateType, action: PayloadAction<number>) {
            state.step = action.payload
        },
        setHasStepChanges(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.hasStepChanges = action.payload
        },
        setParam(
            state: InitialStateType,
            action: PayloadAction<IPreCreateData>
        ) {
            state.preCreateData = {
                ...state.preCreateData,
                ...action.payload
            }
        },
        fetchPlaceCompanies(
            state: InitialStateType,
            _action: PayloadAction<FetchPlaceCompaniesPayload>
        ) {
            state.isFetchingPlaceCompanies = true
            state.error = null
        },
        fetchPlaceCompaniesSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchPlaceCompaniesSuccessPayload>
        ) {
            state.placeCompanies = action.payload.data
            state.isFetchingPlaceCompanies = false
        },
        fetchPlaceCompaniesFailure(
            state: InitialStateType,
            action: PayloadAction<FetchPlaceCompaniesFailurePayload>
        ) {
            state.isFetchingPlaceCompanies = false
            state.error = action.payload.error
        },
        fetchCategories(
            state: InitialStateType,
            _action: PayloadAction<FetchCategoriesPayload>
        ) {
            state.isFetchingCategories = true
            state.error = null
        },
        fetchCategoriesSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchCategoriesSuccessPayload>
        ) {
            state.categories = action.payload.data
            state.isFetchingCategories = false
        },
        fetchCategoriesFailure(
            state: InitialStateType,
            action: PayloadAction<FetchCategoriesFailurePayload>
        ) {
            state.isFetchingCategories = false
            state.error = action.payload.error
        },
        createEvent(
            state: InitialStateType,
            _action: PayloadAction<CreateEventPayload>
        ) {
            state.isProcessCreate = true
            state.error = null
        },
        createEventSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateEventSuccessPayload>
        ) {
            state.isProcessCreate = false
        },
        createEventFailure(
            state: InitialStateType,
            action: PayloadAction<CreateEventFailurePayload>
        ) {
            state.isProcessCreate = false
            state.error = action.payload.error
        },
        fetchEvent(
            state: InitialStateType,
            _action: PayloadAction<FetchEventPayload>
        ) {
            state.isFetchingEvent = true
            state.error = null
        },
        fetchEventSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchEventSuccessPayload>
        ) {
            state.defaultLeadCoast = action.payload.leadCoast
            state.currentEvent = action.payload.data
            state.isFetchingEvent = false
        },
        fetchEventFailure(
            state: InitialStateType,
            action: PayloadAction<FetchEventFailurePayload>
        ) {
            state.isFetchingEvent = false
            state.error = action.payload.error
        },
        saveEvent(
            state: InitialStateType,
            _action: PayloadAction<SaveEventPayload>
        ) {
            state.isSaving = true
            state.error = null
        },
        saveEventSuccess(
            state: InitialStateType,
            _action: PayloadAction<SaveEventSuccessPayload>
        ) {
            state.isSaving = false
        },
        saveEventFailure(
            state: InitialStateType,
            action: PayloadAction<SaveEventFailurePayload>
        ) {
            state.isSaving = false
            state.error = action.payload.error
        },
        uploadGallery(
            state: InitialStateType,
            _action: PayloadAction<UploadGalleryPayload>
        ) {
            state.isUploadPhoto = true
        },
        uploadGallerySuccess(
            state: InitialStateType,
            action: PayloadAction<UploadGallerySuccessPayload>
        ) {
            state.isUploadPhoto = false
            if (state.currentEvent) {
                state.currentEvent.gallery = action.payload.gallery
            }
        },
        uploadGalleryFailure(
            state: InitialStateType,
            action: PayloadAction<UploadGalleryFailurePayload>
        ) {
            state.isUploadPhoto = false
            state.error = action.payload.error
        },
        removePhoto(
            state: InitialStateType,
            action: PayloadAction<RemovePhotoPayload>
        ) {
            state.removingPhotos = [
                ...state.removingPhotos,
                action.payload.media_id
            ]
        },
        removePhotoSuccess(
            state: InitialStateType,
            action: PayloadAction<RemovePhotoSuccessPayload>
        ) {
            state.removingPhotos = state.removingPhotos.filter(
                item => item !== action.payload.media_id
            )
            if (state.currentEvent) {
                state.currentEvent.gallery = state.currentEvent.gallery.filter(
                    item => item.id !== action.payload.media_id
                )
            }
        },
        removePhotoFailure(
            state: InitialStateType,
            action: PayloadAction<RemovePhotoFailurePayload>
        ) {
            state.removingPhotos = state.removingPhotos.filter(
                item => item !== action.payload.media_id
            )
            state.error = action.payload.error
        },
        sortGallery(
            state: InitialStateType,
            _action: PayloadAction<SortGalleryPayload>
        ) {
            state.isSortingGallery = true
        },
        sortGallerySuccess(
            state: InitialStateType,
            _action: PayloadAction<SortGallerySuccessPayload>
        ) {
            state.isSortingGallery = false
        },
        sortGalleryFailure(
            state: InitialStateType,
            action: PayloadAction<SortGalleryFailurePayload>
        ) {
            state.isSortingGallery = false
            state.error = action.payload.error
        },
        saveVideo(
            state: InitialStateType,
            _action: PayloadAction<SaveVideoPayload>
        ) {
            state.isSavingVideo = true
        },
        saveVideoSuccess(
            state: InitialStateType,
            action: PayloadAction<SaveVideoSuccessPayload>
        ) {
            state.isSavingVideo = false
            if (state.currentEvent) {
                state.currentEvent.videos = action.payload.videos
            }
        },
        saveVideoFailure(
            state: InitialStateType,
            action: PayloadAction<SaveVideoFailurePayload>
        ) {
            state.isSavingVideo = false
            state.error = action.payload.error
        },
        removeVideo(
            state: InitialStateType,
            action: PayloadAction<RemoveVideoPayload>
        ) {
            state.removingVideos = [
                ...state.removingVideos,
                action.payload.media_id
            ]
        },
        removeVideoSuccess(
            state: InitialStateType,
            action: PayloadAction<RemoveVideoSuccessPayload>
        ) {
            state.removingVideos = state.removingVideos.filter(
                item => item !== action.payload.media_id
            )
            if (state.currentEvent) {
                state.currentEvent.videos = state.currentEvent.videos.filter(
                    item => item.id !== action.payload.media_id
                )
            }
        },
        removeVideoFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveVideoFailurePayload>
        ) {
            state.removingVideos = state.removingVideos.filter(
                item => item !== action.payload.media_id
            )
            state.error = action.payload.error
        },
        fetchAddress(
            state: InitialStateType,
            _action: PayloadAction<FetchAddressPayload>
        ) {
            state.isFetchingAddress = true
        },
        fetchAddressSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchAddressSuccessPayload>
        ) {
            state.addresses = {
                ...state.addresses,
                [action.payload.company_id]: action.payload.data
            }
            state.isFetchingAddress = false
        },
        fetchAddressFailure(
            state: InitialStateType,
            action: PayloadAction<FetchAddressFailurePayload>
        ) {
            state.isFetchingAddress = false
            state.error = action.payload.error
        },
        croppingPhoto(
            state: InitialStateType,
            _action: PayloadAction<CroppingPhotoPayload>
        ) {
            state.isCroppingPhoto = true
        },
        croppingPhotoSuccess(
            state: InitialStateType,
            _action: PayloadAction<CroppingPhotoSuccessPayload>
        ) {
            state.isCroppingPhoto = false
        },
        croppingPhotoFailure(
            state: InitialStateType,
            action: PayloadAction<CroppingPhotoFailurePayload>
        ) {
            state.isCroppingPhoto = false
            state.error = action.payload.error
        },
        destroy(_state: InitialStateType, _action: PayloadAction<undefined>) {
            return initialState
        }
    }
})

export const actions = eventWizardSlice.actions

export default eventWizardSlice.reducer
