import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    SearchPayload,
    SearchSuccessPayload,
    SearchFailurePayload
} from './types'

const initialState: InitialStateType = {
    names: [],
    isFetching: false,
    error: null
}

const myRequestsClientNameSelectorSlice = createSlice({
    name: 'myRequestsClientNameSelector',
    initialState,
    reducers: {
        search(state: InitialStateType, _action: PayloadAction<SearchPayload>) {
            state.isFetching = true
            state.error = null
        },
        searchSuccess(
            state: InitialStateType,
            action: PayloadAction<SearchSuccessPayload>
        ) {
            state.names = action.payload.names
            state.isFetching = false
        },
        searchFailure(
            state: InitialStateType,
            action: PayloadAction<SearchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        }
    }
})

export const actions = myRequestsClientNameSelectorSlice.actions

export default myRequestsClientNameSelectorSlice.reducer
