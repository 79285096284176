import type { ReactElement } from 'react'
import { Col, Row, Typography } from 'antd'

const { Title, Paragraph } = Typography

interface Props {
    isFirst: boolean
    icon: ReactElement
    title: string
    value: string | ReactElement
    copyable?: boolean
}

export default function FieldView({
    isFirst,
    icon,
    title,
    value,
    copyable = true
}: Props): ReactElement {
    return (
        <Row align={'middle'} style={{ margin: isFirst ? '10px 0 0' : '0' }}>
            <Col span={1}>{icon}</Col>
            <Col span={12}>
                <Title
                    level={5}
                    style={{
                        margin: 0,
                        fontSize: 13,
                        color: 'rgba(0, 0, 0, 0.6)'
                    }}
                >
                    {title}
                </Title>
                <Paragraph copyable={copyable}>{value}</Paragraph>
            </Col>
        </Row>
    )
}
