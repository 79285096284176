import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import {
    Button,
    Divider,
    Pagination,
    Popconfirm,
    Row,
    Space,
    Table,
    type TableProps,
    Typography
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import BottomButtonWizardModal from '../../components/BottomButtonWizardModal'
import type { Dispatch } from 'redux'
import type { IElement } from './types.ts'
import dayjs from 'dayjs'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { useDispatch } from 'react-redux'
import { settingsBottomButtonSelector } from './selectors.ts'
import CustomEmpty from '../../components/CustomEmpty'

const { Title } = Typography

const columns = (dispatch: Dispatch): TableProps<IElement>['columns'] => [
    {
        title: 'Название',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Период активности',
        key: 'period',
        render: ({ start_at, end_at }) =>
            start_at && end_at
                ? `с ${dayjs(start_at).format('DD.MM.YYYY HH:mm')} по ${dayjs(end_at).format('DD.MM.YYYY HH:mm')}`
                : '—'
    },
    {
        title: 'Дата создания',
        key: 'created_at',
        dataIndex: 'created_at',
        render: value => dayjs(value).format('DD.MM.YYYY HH:mm')
    },
    {
        title: '',
        dataIndex: '',
        key: 'remove',
        align: 'end',
        render: data => (
            <Space>
                <Button
                    icon={<EditOutlined />}
                    onClick={() =>
                        dispatch(
                            actions.toggleModalWizard({
                                visible: true,
                                selectedButton: data
                            })
                        )
                    }
                />
                <Popconfirm
                    title={'Удаление ярмарки'}
                    description={'Вы уверены, что хотите удалить фон?'}
                    onConfirm={() =>
                        dispatch(
                            actions.remove({
                                element_id: data.element_id
                            })
                        )
                    }
                    okText={'Да'}
                    cancelText={'Нет'}
                >
                    <Button danger type={'primary'} icon={<DeleteOutlined />} />
                </Popconfirm>
            </Space>
        )
    }
]

export default function SettingsBottomButton(): ReactElement {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)

    const dispatch = useDispatch()

    const { bottomButtons, meta, isFetching } = useAppSelector(
        settingsBottomButtonSelector
    )

    useEffect(() => {
        dispatch(actions.fetch({ page, pageSize }))
    }, [page, pageSize])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        pageValue && setPage(pageValue)
        pageSizeValue && setPageSize(pageSizeValue)
    }

    return (
        <>
            <Row justify={'space-between'}>
                <Title level={3} style={{ margin: 0 }}>
                    {'Нижняя кнопка на страницах'}
                </Title>
                <div style={{ marginLeft: 'auto' }}>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() =>
                            dispatch(
                                actions.toggleModalWizard({ visible: true })
                            )
                        }
                    >
                        {'Создать кнопку'}
                    </Button>
                </div>
            </Row>
            <Divider />
            <Table
                dataSource={bottomButtons}
                columns={columns(dispatch)}
                loading={isFetching}
                rowKey={'element_id'}
                pagination={false}
                locale={{
                    emptyText: <CustomEmpty description={'Нет данных'} />
                }}
                footer={() => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Pagination
                            showQuickJumper
                            current={page}
                            pageSize={pageSize}
                            defaultCurrent={1}
                            defaultPageSize={pageSize}
                            total={meta?.total}
                            onChange={handleChangePagination}
                        />
                    </div>
                )}
            />
            <BottomButtonWizardModal />
        </>
    )
}
