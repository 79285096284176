import type { SagaIterator } from '@redux-saga/core'
import {
    all,
    call,
    fork,
    getContext,
    put,
    select,
    takeEvery
} from 'redux-saga/effects'
import type {
    CreatePayload,
    EditPayload,
    FetchPayload,
    RemovePayload,
    SetDefaultPricePayload
} from './types.ts'
import * as api from './api.ts'
import { actions } from './slice.ts'
import { formatApiError, sagaNotificationError } from '../../utils/helpers.ts'
import { salesSettingsSelector } from './selectors.ts'

export function* handleFetch(action: { payload: FetchPayload }): SagaIterator {
    try {
        const { page, pageSize } = action.payload
        const { data } = yield call(api.fetch, page, pageSize)
        const { data: dataPrice } = yield call(api.getDefaultPrice)
        yield put(
            actions.fetchSuccess({
                data: data.data,
                meta: data.meta,
                defaultPrice: dataPrice.data.price
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleSetDefaultPrice(action: {
    payload: SetDefaultPricePayload
}): SagaIterator {
    try {
        const { data } = yield call(api.setDefaultPrice, action.payload.coast)
        yield put(actions.setDefaultPriceSuccess({ coast: data.data.price }))
        const notification = yield getContext('notification')
        notification.success({
            message: 'Цена по умолчанию',
            description: 'Изменений сохранены',
            placemen: 'topRight'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.setDefaultPriceFailure({
                error: message
            })
        )
    }
}

export function* watchSetDefaultPrice(): SagaIterator {
    yield takeEvery(actions.setDefaultPrice, handleSetDefaultPrice)
}

export function* handleCreate(action: {
    payload: CreatePayload
}): SagaIterator {
    try {
        yield call(api.create, action.payload.form)
        yield put(actions.createSuccess())
        const { meta } = yield select(salesSettingsSelector)
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createFailure({
                error: message
            })
        )
    }
}

export function* watchCreate(): SagaIterator {
    yield takeEvery(actions.create, handleCreate)
}

export function* handleEdit(action: { payload: EditPayload }): SagaIterator {
    try {
        const { lead_price_id, form } = action.payload
        yield call(api.edit, lead_price_id, form)
        yield put(actions.editSuccess())
        const { meta } = yield select(salesSettingsSelector)
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editFailure({
                error: message
            })
        )
    }
}

export function* watchEdit(): SagaIterator {
    yield takeEvery(actions.edit, handleEdit)
}

export function* handleRemove(action: {
    payload: RemovePayload
}): SagaIterator {
    const { lead_price_id } = action.payload
    try {
        yield call(api.remove, lead_price_id)
        yield put(actions.removeSuccess({ lead_price_id }))
        const { meta } = yield select(salesSettingsSelector)
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeFailure({
                lead_price_id,
                error: message
            })
        )
    }
}

export function* watchRemove(): SagaIterator {
    yield takeEvery(actions.remove, handleRemove)
}

export default function* watchSalesSettings(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchSetDefaultPrice),
        fork(watchCreate),
        fork(watchEdit),
        fork(watchRemove)
    ])
}
