import type { ReactElement } from 'react'
import { useState, useEffect } from 'react'
import {
    Button,
    Divider,
    InputNumber,
    Pagination,
    Popconfirm,
    Row,
    Space,
    Table,
    Typography
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import SalesSettingsWizardModal from '../../components/SalesSettingsWizardModal'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { useSearchParams } from 'react-router-dom'
import { salesSettingsSelector } from './selectors.ts'
import type { IAreaSetting } from './types.ts'
import type { Dispatch } from 'redux'
import { fromRuble, toRuble } from '../../utils/helpers.ts'
import CustomEmpty from '../../components/CustomEmpty'

const { Title } = Typography

const columns = (dispatch: Dispatch): ColumnsType<IAreaSetting> => [
    {
        title: 'Регион / Город',
        dataIndex: '',
        key: 'area',
        width: 500,
        render: data => data.area?.name
    },
    {
        title: 'Цена',
        dataIndex: 'coast',
        key: 'coast',
        align: 'left',
        render: data => `${toRuble(data).toLocaleString('ru')} ₽`
    },
    {
        title: '',
        dataIndex: '',
        key: 'nav',
        width: 150,
        render: data => (
            <Space>
                <Button
                    onClick={() =>
                        dispatch(
                            actions.toggleModalWizardOpen({
                                isOpen: true,
                                areaSetting: data
                            })
                        )
                    }
                    type={'primary'}
                    icon={<EditOutlined />}
                />
                <Popconfirm
                    title={'Удаление настройки'}
                    description={'Вы уверены, что хотите удалить настройку?'}
                    onConfirm={() =>
                        dispatch(
                            actions.remove({
                                lead_price_id: data.lead_price_id
                            })
                        )
                    }
                    okText={'Да'}
                    cancelText={'Нет'}
                >
                    <Button
                        type={'primary'}
                        danger={true}
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
            </Space>
        )
    }
]

export default function SalesSettings(): ReactElement {
    const [defaultPriceValue, setDefaultPriceValue] = useState<null | number>(
        null
    )

    const dispatch = useDispatch()

    const { defaultPrice, data, isFetching, isProcessSetDefaultPrice, meta } =
        useAppSelector(salesSettingsSelector)

    const [searchParams, setSearchParams] = useSearchParams()

    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    useEffect(() => {
        if (defaultPrice) {
            setDefaultPriceValue(toRuble(defaultPrice))
        }
    }, [defaultPrice])

    useEffect(() => {
        dispatch(actions.fetch({ page, pageSize }))
        setSearchParams({
            ...(page !== 1 ? { page: page.toString() } : {}),
            ...(pageSize !== 10 ? { pageSize: pageSize.toString() } : {})
        })
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        setSearchParams({
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    const handleSaveDefaultPrice = (): void => {
        if (typeof defaultPriceValue === 'number') {
            dispatch(
                actions.setDefaultPrice({ coast: fromRuble(defaultPriceValue) })
            )
        }
    }

    return (
        <>
            <Row style={{ marginBottom: 20 }} justify={'space-between'}>
                <Title level={3} style={{ margin: 0 }}>
                    {'Настройка продаж'}
                </Title>
                <Space>
                    <Typography>{'Цена по умолчанию: '}</Typography>
                    <InputNumber
                        value={defaultPriceValue}
                        addonBefore={'₽'}
                        addonAfter={
                            <Button
                                disabled={
                                    defaultPriceValue ===
                                        toRuble(defaultPrice ?? 0) ||
                                    (typeof defaultPriceValue === 'number' &&
                                        defaultPriceValue < 0) ||
                                    defaultPriceValue === null
                                }
                                loading={isProcessSetDefaultPrice}
                                type={'text'}
                                style={{ margin: '0 -10px' }}
                                onClick={handleSaveDefaultPrice}
                            >
                                {'Сохранить'}
                            </Button>
                        }
                        onChange={setDefaultPriceValue}
                        placeholder={'Укажите цену'}
                        style={{ width: '100%' }}
                    />
                    <Button
                        icon={<PlusOutlined />}
                        type={'primary'}
                        onClick={() =>
                            dispatch(
                                actions.toggleModalWizardOpen({ isOpen: true })
                            )
                        }
                    >
                        {'Добавить настройку продаж'}
                    </Button>
                </Space>
            </Row>
            <Divider style={{ background: '#ff57a5' }} />
            <Table
                dataSource={data}
                columns={columns(dispatch)}
                rowKey={'lead_price_id'}
                loading={isFetching}
                pagination={false}
                sticky={{ offsetHeader: 0 }}
                locale={{
                    emptyText: <CustomEmpty description={'Нет данных'} />
                }}
                footer={() => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Pagination
                            showQuickJumper
                            current={page}
                            pageSize={pageSize}
                            defaultCurrent={1}
                            defaultPageSize={pageSize}
                            total={meta?.total}
                            onChange={handleChangePagination}
                        />
                    </div>
                )}
            />
            <SalesSettingsWizardModal />
        </>
    )
}
