import type { ReactElement } from 'react'
import { useEffect } from 'react'
import BlogFilter from '../../components/BlogFilter'
import { Button, Pagination, Popconfirm, Row, Table, Typography } from 'antd'
import styles from './styles.module.scss'
import type { ColumnsType } from 'antd/es/table'
import type { IPost } from './types.ts'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { blogStatus, getRole, ignoreKeys } from '../../utils/helpers.ts'
import { actions } from './slice.ts'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { blogSelector } from './selectors.ts'
import dayjs from 'dayjs'
import type { IFranchisee } from '../FranchiseeSelector/types.ts'
import type { IUser } from '../Users/types.ts'
import { authorizationSelector } from '../Authorization/selectors.ts'
import CustomEmpty from "../../components/CustomEmpty";

const { Title } = Typography

const columns = (
    onRemove: (id: string) => void,
    removing: string[],
    isAdminOrFranchisee: boolean
): ColumnsType<IPost> => [
    {
        title: 'Название статьи',
        key: 'title',
        dataIndex: '',
        width: 300,
        render: (data: IPost) => (
            <Link to={`/blog/${data.post_id}`}>{data.title}</Link>
        )
    },
    {
        title: 'Дата создания',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        render: (value: string) => dayjs(value).format('DD.MM.YYYY HH:mm')
    },
    {
        title: 'Владелец',
        dataIndex: 'user',
        key: 'user',
        align: 'center',
        render: (data: IUser) =>
            data ? data.full_name || data.email || data.phone : 'Не установлен'
    },
    {
        title: 'Франчайзи',
        dataIndex: 'franchisee',
        key: 'franchisee',
        align: 'center',
        render: (data: IFranchisee | null) =>
            data ? data.name : 'Не установлен'
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (value: '1' | '3' | '4') => blogStatus[value]
    },
    ...(isAdminOrFranchisee
        ? ([
              {
                  title: 'Реальные просмотры',
                  dataIndex: 'views_count',
                  key: 'views_count',
                  align: 'center',
                  render: value => value.toLocaleString('ru')
              },
              {
                  title: 'Количество к отображению',
                  dataIndex: 'summary_views_count',
                  key: 'summary_views_count',
                  align: 'center',
                  render: value => value.toLocaleString('ru')
              }
          ] as ColumnsType<IPost>)
        : ([
              {
                  title: 'Просмотры',
                  dataIndex: 'summary_views_count',
                  key: 'summary_views_count',
                  align: 'center',
                  render: value => value.toLocaleString('ru')
              }
          ] as ColumnsType<IPost>)),
    {
        title: 'Дата публикации',
        dataIndex: 'published_at',
        key: 'published_at',
        align: 'center',
        render: value =>
            value ? dayjs(value).format('DD.MM.YYYY') : 'Не установлена'
    },
    {
        title: '',
        dataIndex: '',
        key: 'remove',
        align: 'center',
        width: 50,
        render: (data: IPost) => (
            <Popconfirm
                title={'Удаление статьи'}
                description={'Вы уверены, что хотите удалить?'}
                onConfirm={() => onRemove(data.post_id)}
                okText={'Да'}
                cancelText={'Нет'}
            >
                <Button
                    loading={removing.includes(data.post_id)}
                    icon={<DeleteOutlined />}
                    danger={true}
                />
            </Popconfirm>
        )
    }
]

export default function Blog(): ReactElement {
    const [searchParams, setSearchParams] = useSearchParams()

    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    const { data, isFetching, removing, meta } = useAppSelector(blogSelector)
    const { profile } = useAppSelector(authorizationSelector)

    const isAdminOrFranchisee = profile
        ? getRole(profile.roles) === 'admin' ||
          getRole(profile.roles) === 'franchisee'
        : false

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        const filter: Record<string, string> = {}

        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })

        dispatch(
            actions.fetch({
                page,
                pageSize,
                filter
            })
        )

        setSearchParams({
            ...filter,
            ...(page !== 1 ? { page: page.toString() } : {}),
            ...(pageSize !== 10 ? { pageSize: pageSize.toString() } : {})
        })
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        setSearchParams({
            ...filter,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    const handleRemove = (post_id: string) => {
        dispatch(actions.remove({ post_id }))
    }

    return (
        <>
            <Row justify={'end'} style={{ marginBottom: 20 }}>
                <Row>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => void navigate('/blog/create')}
                        type={'primary'}
                    >
                        {'Добавить статью'}
                    </Button>
                </Row>
            </Row>
            <div style={{ marginBottom: 25 }} className={styles.container}>
                <Title level={3} style={{ margin: '0 0 25px' }}>
                    {'Мои статьи'}
                </Title>
                <BlogFilter />
            </div>
            <div className={styles.container}>
                <Table
                    dataSource={data}
                    columns={columns(
                        handleRemove,
                        removing,
                        isAdminOrFranchisee
                    ).filter(column =>
                        isAdminOrFranchisee ? true : column.key !== 'franchisee'
                    )}
                    rowKey={'post_id'}
                    locale={{
                        emptyText: <CustomEmpty />
                    }}
                    loading={isFetching}
                    pagination={false}
                    footer={() => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Pagination
                                showQuickJumper
                                current={page}
                                pageSize={pageSize}
                                defaultCurrent={1}
                                defaultPageSize={pageSize}
                                total={meta?.total}
                                onChange={handleChangePagination}
                            />
                        </div>
                    )}
                />
            </div>
        </>
    )
}
