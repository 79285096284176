import type { ReactElement } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import {
    Flex,
    Form,
    Input,
    Modal,
    Select,
    Upload,
    type UploadProps
} from 'antd'
import type { IYarmarkaBlock } from '../YarmarkaWizardModal'
import { useForm, useWatch } from 'antd/es/form/Form'
import { PlusOutlined } from '@ant-design/icons'
import TagsSelector from '../../containers/TagsSelector'
import BlocksSelector from '../../containers/BlocksSelector'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { authorizationSelector } from '../../containers/Authorization/selectors.ts'
import CompanyTagsSelector from '../../containers/CompanyTagsSelector'
import EventTagsSelector from '../../containers/EventTagsSelector'
import PostCategorySelector from '../../containers/PostCategorySelector'
import CompanyCategoriesSelector from '../../containers/CompanyCategoriesSelector'
import EventCategoriesSelector from '../../containers/EventCategoriesSelector'

interface Props {
    items: IYarmarkaBlock[]
    selectedBlock: number | false
    onClose: () => void
    onSave: (items: IYarmarkaBlock[]) => void
}

export default function YarmarkaBlockWizardModal({
    items,
    selectedBlock,
    onClose,
    onSave
}: Props): ReactElement {
    const [image, setImage] = useState<string | null>(null)

    const [form] = useForm()

    const { token } = useAppSelector(authorizationSelector)

    const watchType = useWatch('type', form)
    const watchCategory = useWatch('category', form)
    const watchThemes = useWatch('themes', form)

    useEffect(() => {
        if (selectedBlock) {
            const findBlock = items.find(block => block.id === selectedBlock)
            if (findBlock) {
                for (const key in findBlock) {
                    const value = findBlock[key as keyof typeof findBlock]
                    if (key === 'image' && typeof value === 'string') {
                        setImage(value)
                        continue
                    }
                    form.setFieldValue(key, value)
                }
            }
        }
    }, [selectedBlock])

    const handleSaveForm = (form: Record<string, unknown>) => {
        onSave(
            items.map(block =>
                block.id === selectedBlock
                    ? {
                          ...block,
                          ...form,
                          image
                      }
                    : block
            )
        )
        onClose()
    }

    const handleChangeFormItem = (
        key: string,
        value: string | string[]
    ): void => {
        form.setFieldValue(key, value)
    }

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setImage(
            newFileList.at(-1)?.response
                ? newFileList.at(-1)!.response.url
                : null
        )

    return (
        <Modal
            open={typeof selectedBlock === 'number'}
            title={'Редактирование блока'}
            onCancel={onClose}
            okText={'Сохранить'}
            cancelText={'Закрыть'}
            afterClose={() => {
                form.resetFields()
                setImage(null)
            }}
            onOk={form.submit}
            destroyOnClose={true}
        >
            <Flex justify={'center'}>
                <Upload
                    listType={'picture-card'}
                    showUploadList={false}
                    action={`${process.env.API_URL}/files`}
                    headers={{
                        Authorization: `Bearer ${token}`
                    }}
                    accept={'image/*'}
                    multiple={false}
                    onChange={handleChange}
                >
                    {image ? (
                        <img
                            src={image}
                            alt={'avatar'}
                            style={{ width: '100%' }}
                        />
                    ) : (
                        <button
                            style={{
                                border: 0,
                                outline: 0,
                                background: 'none',
                                cursor: 'pointer'
                            }}
                            type={'button'}
                        >
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>
                                {'Изображение блока'}
                            </div>
                        </button>
                    )}
                </Upload>
            </Flex>
            <Form
                form={form}
                layout={'vertical'}
                onFinish={handleSaveForm}
                onValuesChange={(e, values) => {
                    if (e.type) {
                        form.setFieldsValue({
                            category: null,
                            themes: null
                        })
                    }
                    if (
                        e.category &&
                        (values.type === 'companies' ||
                            values.type === 'journal')
                    ) {
                        form.setFieldValue('themes', null)
                    }
                }}
            >
                <Form.Item
                    name={'title'}
                    label={'Название блока'}
                    style={{ margin: '10px 0' }}
                    rules={[{ required: true }, { type: 'string', min: 6 }]}
                >
                    <Input placeholder={'Название блока'} />
                </Form.Item>
                <Form.Item
                    name={'type'}
                    label={'Тип блока'}
                    style={{ margin: '10px 0' }}
                    rules={[{ required: true }]}
                >
                    <Select
                        options={[
                            { label: 'Компании', value: 'companies' },
                            { label: 'Предложения', value: 'events' },
                            { label: 'Журнал', value: 'journal' },
                            { label: 'Баннер', value: 'banner' },
                            {
                                label: 'Мобильный блок рекламы',
                                value: 'mobile-ad'
                            },
                            {
                                label: 'Параллакс реклама',
                                value: 'parallax-ad'
                            }
                        ]}
                        placeholder={'Тип блока'}
                    />
                </Form.Item>
                {watchType ? (
                    <>
                        {watchType !== 'banner' &&
                        watchType !== 'mobile-ad' &&
                        watchType !== 'parallax-ad' ? (
                            <>
                                <Form.Item
                                    name={'size'}
                                    label={'Размер элементов'}
                                    style={{ margin: '10px 0' }}
                                >
                                    <Select
                                        options={[
                                            { label: 'Большой', value: 'big' },
                                            { label: 'Маленький', value: 'min' }
                                        ]}
                                        placeholder={'Размер элементов'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={'category'}
                                    label={'Категория'}
                                    style={{ margin: '10px 0' }}
                                >
                                    {watchType === 'journal' ? (
                                        <PostCategorySelector />
                                    ) : watchType === 'companies' ? (
                                        <CompanyCategoriesSelector
                                            level={3}
                                            isSingleMode={true}
                                        />
                                    ) : (
                                        <EventCategoriesSelector
                                            isSingleMode={true}
                                        />
                                    )}
                                </Form.Item>
                                {watchType === 'journal' ? (
                                    <Form.Item
                                        name={'themes'}
                                        label={'Темы'}
                                        style={{ margin: '10px 0' }}
                                    >
                                        <TagsSelector
                                            key={watchCategory}
                                            disabled={!watchCategory}
                                            isOneCategory={true}
                                            selectedCategory={form.getFieldValue(
                                                'category'
                                            )}
                                            initialValue={watchThemes}
                                            onChange={value =>
                                                handleChangeFormItem(
                                                    'themes',
                                                    value
                                                )
                                            }
                                        />
                                    </Form.Item>
                                ) : null}
                                {watchType === 'companies' ? (
                                    <Form.Item
                                        name={'themes'}
                                        label={'Темы'}
                                        style={{ margin: '10px 0' }}
                                    >
                                        <CompanyTagsSelector
                                            key={watchCategory}
                                            filterCategory={form.getFieldValue(
                                                'category'
                                            )}
                                        />
                                    </Form.Item>
                                ) : null}
                                {watchType === 'events' ? (
                                    <Form.Item
                                        name={'themes'}
                                        label={'Темы'}
                                        style={{ margin: '10px 0' }}
                                    >
                                        <EventTagsSelector />
                                    </Form.Item>
                                ) : null}
                                <Form.Item
                                    name={'filter'}
                                    label={'Объект фильтра запроса'}
                                    style={{ marginBottom: 10 }}
                                >
                                    <Input.TextArea
                                        placeholder={'{}'}
                                        style={{ minHeight: 150 }}
                                    />
                                </Form.Item>
                            </>
                        ) : null}
                        {watchType === 'banner' ? (
                            <Form.Item
                                name={'banner_code'}
                                style={{ marginBottom: 10 }}
                                label={'Блок баннера'}
                            >
                                <BlocksSelector />
                            </Form.Item>
                        ) : null}
                        {watchType !== 'banner' &&
                        watchType !== 'mobile-ad' &&
                        watchType !== 'parallax-ad' ? (
                            <Form.Item
                                name={'url_category'}
                                label={'Адрес категории'}
                                style={{ margin: '10px 0 20px' }}
                                rules={[{ required: true }]}
                                extra={
                                    <>
                                        {
                                            'Текущий город в ссылке указывается, через [city].'
                                        }
                                        <br />
                                        {
                                            'Пример внутренней ссылки: /[city]/eda/theme-eda'
                                        }
                                    </>
                                }
                            >
                                <Input placeholder={'Адрес категории'} />
                            </Form.Item>
                        ) : null}
                    </>
                ) : null}
            </Form>
        </Modal>
    )
}
