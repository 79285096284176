import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    SetDefaultPriceFailurePayload,
    SetDefaultPricePayload,
    SetDefaultPriceSuccessPayload,
    CreateFailurePayload,
    CreatePayload,
    CreateSuccessPayload,
    EditFailurePayload,
    EditPayload,
    EditSuccessPayload,
    FetchFailurePayload,
    FetchPayload,
    FetchSuccessPayload,
    RemoveFailurePayload,
    RemovePayload,
    RemoveSuccessPayload,
    IAreaSetting
} from './types.ts'

const initialState: InitialStateType = {
    data: [],
    meta: null,
    defaultPrice: null,
    selectedAreaSettings: null,
    isFetching: false,
    isModalWizardOpen: false,
    isProcessSetDefaultPrice: false,
    isProcessWizard: false,
    removing: [],
    error: null
}

const salesSettingsSlice = createSlice({
    name: 'salesSettings',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.defaultPrice = action.payload.defaultPrice
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        setDefaultPrice(
            state: InitialStateType,
            _action: PayloadAction<SetDefaultPricePayload>
        ) {
            state.isProcessSetDefaultPrice = true
            state.error = null
        },
        setDefaultPriceSuccess(
            state: InitialStateType,
            action: PayloadAction<SetDefaultPriceSuccessPayload>
        ) {
            state.defaultPrice = action.payload.coast
            state.isProcessSetDefaultPrice = false
        },
        setDefaultPriceFailure(
            state: InitialStateType,
            action: PayloadAction<SetDefaultPriceFailurePayload>
        ) {
            state.isProcessSetDefaultPrice = false
            state.error = action.payload.error
        },
        create(state: InitialStateType, _action: PayloadAction<CreatePayload>) {
            state.isProcessWizard = true
            state.error = null
        },
        createSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateSuccessPayload>
        ) {
            state.isModalWizardOpen = false
            state.isProcessWizard = false
        },
        createFailure(
            state: InitialStateType,
            action: PayloadAction<CreateFailurePayload>
        ) {
            state.isProcessWizard = false
            state.error = action.payload.error
        },
        edit(state: InitialStateType, _action: PayloadAction<EditPayload>) {
            state.isProcessWizard = true
            state.error = null
        },
        editSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditSuccessPayload>
        ) {
            state.isModalWizardOpen = false
            state.isProcessWizard = false
        },
        editFailure(
            state: InitialStateType,
            action: PayloadAction<EditFailurePayload>
        ) {
            state.isProcessWizard = false
            state.error = action.payload.error
        },
        remove(state: InitialStateType, action: PayloadAction<RemovePayload>) {
            state.removing.push(action.payload.lead_price_id)
            state.error = null
        },
        removeSuccess(
            state: InitialStateType,
            action: PayloadAction<RemoveSuccessPayload>
        ) {
            state.removing = state.removing.filter(
                id => id !== action.payload.lead_price_id
            )
        },
        removeFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveFailurePayload>
        ) {
            state.removing = state.removing.filter(
                id => id !== action.payload.lead_price_id
            )
            state.error = action.payload.error
        },
        toggleModalWizardOpen: (
            state,
            action: PayloadAction<{
                isOpen: boolean
                areaSetting?: IAreaSetting
            }>
        ): void => {
            state.isModalWizardOpen = action.payload.isOpen
            state.selectedAreaSettings = action.payload.areaSetting || null
        }
    }
})

export const actions = salesSettingsSlice.actions

export default salesSettingsSlice.reducer
