import type { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { promoCodesSelector } from './selectors'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import type { IReportPromoCode } from './types'
import { Pagination, Result, Table, Typography } from 'antd'
import { Link, useSearchParams } from 'react-router-dom'
import PromoCodesReportFilter from '../../components/PromoCodesReportFilter'
import { authorizationSelector } from '../Authorization/selectors.ts'
import {
    checkFranchiseeRole,
    getCompanySlug,
    getRole,
    ignoreKeys
} from '../../utils/helpers.ts'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { CloseCircleOutlined } from '@ant-design/icons'
import { actions as actionsPromoCodes } from '../PromoCodes/slice.ts'
import PromoCodesWizardModal from '../../components/PromoCodesWizardModal/PromoCodesWizardModal.tsx'

// Styles
import styles from './styles.module.scss'
import CustomEmpty from '../../components/CustomEmpty'

const { Title, Paragraph } = Typography

const columns = (
    isAdmin: boolean,
    openPromoCode: (promoCodeId: string) => void
): ColumnsType<IReportPromoCode> => [
    {
        title: 'Компания',
        key: 'company',
        render: (_, data: IReportPromoCode) =>
            data.related_company ? (
                isAdmin ? (
                    <Link
                        to={`/companies/${data.related_company.company_id}`}
                        className={styles.link}
                    >
                        {data.related_company.name}
                    </Link>
                ) : (
                    <span>{data.related_company.name}</span>
                )
            ) : (
                <Paragraph
                    style={{ margin: 0, fontSize: 12 }}
                    type={'secondary'}
                >
                    {'Не найдена'}
                </Paragraph>
            )
    },
    {
        title: 'Адрес компании',
        key: 'address',
        render: (_, data: IReportPromoCode) => {
            if (
                data.related_company?.text_address ||
                data.related_company?.additional_text_address
            ) {
                const replaceAdditionalAddress =
                    data.related_company.additional_text_address?.replace(
                        ', Россия',
                        ''
                    )

                return (
                    <span>
                        {`${
                            replaceAdditionalAddress
                                ? `${replaceAdditionalAddress}, `
                                : ''
                        }${data.related_company.text_address}`}
                    </span>
                )
            }
            return <span>{'Адрес не указан'}</span>
        }
    },
    {
        title: 'Ссылка на лендинг компании',
        key: 'landing',
        align: 'center',
        render: (_, data: IReportPromoCode) => {
            if (data.related_company && data.related_company.cached_displayed) {
                const { slug, short_id, company_id } = data.related_company
                const companySlug = getCompanySlug(slug, short_id, company_id)
                return (
                    <a
                        href={`${process.env.SITE_URL}/${companySlug}`}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >
                        {'Открыть ссылку'}
                    </a>
                )
            }
            return (
                <Paragraph type={'danger'} style={{ margin: 0 }}>
                    {'Не опубликован'}
                </Paragraph>
            )
        }
    },
    {
        title: 'Промокод',
        key: 'order',
        align: 'center',
        render: (_, data: IReportPromoCode) => {
            if (data.order.promocode?.code) {
                return (
                    <span
                        className={styles.link}
                        onClick={() =>
                            data.order.promocode?.promocode_id
                                ? openPromoCode(
                                      data.order.promocode.promocode_id
                                  )
                                : null
                        }
                    >
                        {data.order.promocode.code}
                    </span>
                )
            }
            return <CloseCircleOutlined style={{ color: 'red' }} />
        }
    },
    {
        title: 'Скидка % промокода',
        key: 'percent',
        align: 'center',
        render: (_, data: IReportPromoCode) => {
            if (data.order.promocode?.discount_percents) {
                return (
                    <span>
                        {data.order.promocode.discount_percents}
                        {'%'}
                    </span>
                )
            }
            return <CloseCircleOutlined style={{ color: 'red' }} />
        }
    },
    {
        title: 'Сумма оплаты на расчетный счет',
        key: 'amount',
        align: 'center',
        render: (_, data: IReportPromoCode) => {
            if (data.order.amount) {
                const parseAmount = data.order.amount / 100
                return (
                    <span>
                        {parseAmount.toLocaleString('ru-RU')}
                        {' ₽'}
                    </span>
                )
            }
            return <CloseCircleOutlined style={{ color: 'red' }} />
        }
    },
    {
        title: 'Владелец',
        key: 'owner',
        dataIndex: 'owner',
        align: 'center'
    },
    {
        title: 'Дата оплаты',
        key: 'createdAt',
        dataIndex: 'createdAt',
        align: 'center',
        render: (date: string) => (
            <span>{dayjs(date).format('DD.MM.YYYY HH:mm:ss')}</span>
        )
    }
]

export default function PromoCodesReport(): ReactElement {
    const [isLoaded, setIsLoaded] = useState(false)
    const [isOpenReport, setIsOpenReport] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams()

    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')
    const promocodeQuery = searchParams.get('promocode')
    const periodQuery = searchParams.get('period')
    const ownerQuery = searchParams.get('owner')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 20

    const { profile } = useAppSelector(authorizationSelector)

    const isAdmin = profile ? getRole(profile.roles) === 'admin' : false
    const isFranchisee = checkFranchiseeRole(profile)

    const dispatch = useDispatch()

    const { data, isFetching, meta, promoCode, isFetchingPromoCode } =
        useAppSelector(promoCodesSelector)

    const getHistoryParam = () => {
        const historyParams: { [key: string]: string } = {}

        for (const [key, value] of searchParams.entries()) {
            if (ignoreKeys.includes(key)) {
                continue
            }
            historyParams[key] = value
        }

        return historyParams
    }

    useEffect(() => {
        setIsOpenReport(
            isAdmin ? !!periodQuery : !!periodQuery && !!promocodeQuery
        )

        const historyParams = getHistoryParam()

        setSearchParams({
            ...historyParams,
            ...(page !== 1 ? { page: page.toString() } : {}),
            ...(pageSize !== 20 ? { pageSize: pageSize.toString() } : {})
        })

        setIsLoaded(true)
    }, [
        isAdmin,
        pageQuery,
        pageSizeQuery,
        periodQuery,
        promocodeQuery,
        ownerQuery
    ])

    useEffect(() => {
        if (isOpenReport && periodQuery) {
            const parsePeriodQuery = periodQuery.split(',')

            dispatch(
                actions.fetch({
                    page: pageQuery ? parseInt(pageQuery) : 1,
                    pageSize: pageSizeQuery ? parseInt(pageSizeQuery) : 10,
                    filter: {
                        promocode: promocodeQuery?.toString(),
                        from: parsePeriodQuery[0],
                        to: parsePeriodQuery[1],
                        createdId: ownerQuery
                            ? ownerQuery.split('{d}')[1]
                            : undefined
                    }
                })
            )
        }
    }, [isOpenReport, pageQuery, pageSizeQuery, periodQuery, promocodeQuery])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const historyParams = getHistoryParam()

        setSearchParams({
            ...historyParams,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 20
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    const handleOpenPromoCode = (promoCode: string) => {
        dispatch(actions.fetchPromoCode(promoCode))
    }

    const handleClearPromoCode = () => {
        dispatch(actions.clearPromoCode())
        dispatch(actionsPromoCodes.setWizardModal(false))
    }

    return (
        <>
            <div style={{ marginBottom: 25 }} className={styles.container}>
                <Title level={3} style={{ margin: '0 0 25px' }}>
                    {'Отчет по промокодам'}
                </Title>
                {/*<Button*/}
                {/*    icon={<PlusOutlined />}*/}
                {/*    onClick={() => null}*/}
                {/*    type={'primary'}*/}
                {/*>*/}
                {/*    {'Создать промокод'}*/}
                {/*</Button>*/}
                <PromoCodesReportFilter isAdmin={isAdmin} />
            </div>
            <div className={styles.container}>
                {isLoaded ? (
                    isOpenReport ? (
                        <Table
                            dataSource={data}
                            columns={columns(isAdmin, handleOpenPromoCode)}
                            rowKey={'orderProductId'}
                            loading={isFetching || isFetchingPromoCode}
                            pagination={false}
                            sticky={{ offsetHeader: 0 }}
                            locale={{
                                emptyText: (
                                    <CustomEmpty description={'Нет данных'} />
                                )
                            }}
                            footer={() => (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Pagination
                                        showQuickJumper
                                        current={page}
                                        pageSize={pageSize}
                                        defaultCurrent={1}
                                        defaultPageSize={pageSize}
                                        total={meta?.total}
                                        onChange={handleChangePagination}
                                    />
                                </div>
                            )}
                        />
                    ) : (
                        <Result
                            icon={<div className={styles.clouds} />}
                            status={'warning'}
                            title={
                                isAdmin
                                    ? 'Для формирования отчета, необходимо нажать кнопку «Сформировать»'
                                    : 'Для формирования отчета, необходимо заполнить поле "Промокод" и нажать кнопку «Сформировать»'
                            }
                        />
                    )
                ) : null}
            </div>
            <PromoCodesWizardModal
                selectedPromoCode={promoCode}
                onClose={handleClearPromoCode}
                isAdmin={isAdmin}
                isFranchisee={isFranchisee}
            />
        </>
    )
}
