import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api.ts'
import type { IBusinessAccount } from './types.ts'
import type { IMeta } from '../../types'

export const fetchOwners = (
    search: string
): Promise<AxiosResponse<IMeta<IBusinessAccount>>> =>
    get('/admin/leads/owner-autocomplete', {
        'filter[autocomplete]': search,
        include: ['user']
    })
