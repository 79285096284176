import {
    Button,
    Form,
    Modal,
    Pagination,
    Row,
    Select,
    Table,
    Typography,
    Input,
    DatePicker,
    Popconfirm
} from 'antd'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companySelector } from '../../selectors'
import { actions } from '../../slice'
import type { ColumnsType } from 'antd/es/table'
import type { IPaymentHistoryItem, IPlanSubscriptionsItem } from '../../types'
import {
    CheckCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons'
import FormItemWithLabel from '../../../../components/FormItemWithLabel'
import dayjs from 'dayjs'
import { applicationSelector } from '../../../Application/selectors'
import CustomEmpty from '../../../../components/CustomEmpty'

const { Title } = Typography
const { TextArea } = Input

const historyColumns: ColumnsType<IPaymentHistoryItem> = [
    {
        title: 'Название',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Успех',
        dataIndex: 'success',
        key: 'success',
        align: 'center',
        render: value =>
            value ? (
                <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
                <MinusCircleOutlined style={{ color: 'red' }} />
            )
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        align: 'center'
    },
    {
        title: 'Создано',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: value => dayjs(value).format('DD.MM.YYYY HH:mm')
    },
    {
        title: 'Обновлено',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        align: 'center',
        render: value => dayjs(value).format('DD.MM.YYYY HH:mm')
    }
]

export default function TabSubscriptions() {
    const [submittable, setSubmittable] = useState(false)

    const {
        currentCompany,
        planSubscriptions,
        isFetchingPlanSubscriptions,
        isProcessAddPlanSubscribe,
        isModalAddPlanSubscribe,
        paymentHistory,
        isFetchingPaymentHistory,
        removePlanSubscriptions
    } = useAppSelector(companySelector)
    const { plans, plansIsFetching } = useAppSelector(applicationSelector)

    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const values = Form.useWatch([], form)
    const watchPlan = Form.useWatch('plan', form)

    useEffect(() => {
        if (watchPlan) {
            const findPlan = plans.find(item => item.planId === watchPlan)
            if (findPlan) {
                form.setFieldsValue({
                    start: dayjs(),
                    end: dayjs().add(
                        findPlan.invoice_period,
                        findPlan.invoice_interval
                    )
                })
            }
        }
    }, [watchPlan])

    const subscriptionColumns: ColumnsType<IPlanSubscriptionsItem> = [
        {
            title: 'Тарифный план',
            dataIndex: 'plan',
            width: '40%',
            render: data => data.name.title
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            align: 'center',
            width: '20%'
        },
        {
            title: 'Срок действия',
            dataIndex: 'endsAt',
            align: 'center',
            width: '25%',
            render: date => dayjs(date).format('DD.MM.YYYY HH:mm')
        },
        {
            title: '',
            align: 'center',
            dataIndex: 'planSubscriptionId',
            width: '15%',
            render: planSubscriptionId => (
                <Popconfirm
                    title={'Вы уверены, что хотите удалить?'}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={() =>
                        currentCompany
                            ? dispatch(
                                  actions.deletePlanSubscriptions({
                                      company_id: currentCompany.company_id,
                                      plan_subscription_id: planSubscriptionId
                                  })
                              )
                            : null
                    }
                    okButtonProps={{
                        danger: true
                    }}
                >
                    <Button
                        loading={removePlanSubscriptions.includes(
                            planSubscriptionId
                        )}
                        type={'primary'}
                        danger={true}
                    >
                        {'Удалить'}
                    </Button>
                </Popconfirm>
            )
        }
    ]

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true)
            },
            () => {
                setSubmittable(false)
            }
        )
    }, [values])

    useEffect(() => {
        if (currentCompany) {
            dispatch(
                actions.fetchPlanSubscriptions({
                    company_id: currentCompany.company_id,
                    page: 1
                })
            )
            dispatch(
                actions.fetchPaymentHistory({
                    company_id: currentCompany.company_id,
                    page: 1,
                    perPage: 20
                })
            )
        }
    }, [])

    const handleAddSubscribe = (data: {
        plan: string
        start: string
        end: string
        comment: string
    }) => {
        if (currentCompany) {
            dispatch(
                actions.addPlanSubscribe({
                    company_id: currentCompany.company_id,
                    ...data
                })
            )
            form.resetFields()
        }
    }

    return (
        <>
            <Row
                style={{ marginBottom: 15 }}
                justify={'space-between'}
                align={'middle'}
            >
                <Title style={{ margin: 0 }} level={4}>
                    {'Активные подписки'}
                </Title>
                <Button
                    onClick={() =>
                        dispatch(actions.toggleModalAddPlanSubscribe(true))
                    }
                    icon={<PlusOutlined />}
                >
                    {'Добавить подписку'}
                </Button>
            </Row>
            <Table
                dataSource={planSubscriptions.data}
                columns={subscriptionColumns}
                loading={isFetchingPlanSubscriptions}
                style={{ marginBottom: 30 }}
                rowKey={'planSubscriptionId'}
                locale={{
                    emptyText: (
                        <CustomEmpty
                            description={'Активные подписки не найдены!'}
                        />
                    )
                }}
                pagination={false}
                footer={() => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Pagination
                            showQuickJumper
                            current={planSubscriptions?.meta?.currentPage}
                            pageSize={25}
                            defaultCurrent={1}
                            defaultPageSize={25}
                            total={planSubscriptions?.meta?.total}
                            onChange={page =>
                                currentCompany
                                    ? dispatch(
                                          actions.fetchPlanSubscriptions({
                                              company_id:
                                                  currentCompany.company_id,
                                              page
                                          })
                                      )
                                    : null
                            }
                        />
                    </div>
                )}
            />
            <Title style={{ margin: '0 0 15px' }} level={4}>
                {'История оплат'}
            </Title>
            <Table
                dataSource={paymentHistory.data}
                loading={isFetchingPaymentHistory}
                columns={historyColumns}
                rowKey={'orderProductId'}
                locale={{
                    emptyText: (
                        <CustomEmpty
                            description={'История оплат не найдена!'}
                        />
                    )
                }}
                pagination={false}
                footer={() => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Pagination
                            showQuickJumper
                            current={paymentHistory?.meta?.currentPage}
                            pageSize={paymentHistory?.meta?.perPage ?? 20}
                            defaultCurrent={1}
                            defaultPageSize={20}
                            total={paymentHistory?.meta?.total}
                            onChange={(page, perPage) =>
                                currentCompany
                                    ? dispatch(
                                          actions.fetchPaymentHistory({
                                              company_id:
                                                  currentCompany.company_id,
                                              page,
                                              perPage
                                          })
                                      )
                                    : null
                            }
                        />
                    </div>
                )}
            />
            <Modal
                title={'Добавить подписку'}
                open={isModalAddPlanSubscribe}
                onOk={form.submit}
                okButtonProps={{
                    disabled: !submittable,
                    loading: isProcessAddPlanSubscribe,
                    icon: <PlusOutlined />
                }}
                okText={'Добавить'}
                onCancel={() =>
                    dispatch(actions.toggleModalAddPlanSubscribe(false))
                }
            >
                <Form
                    form={form}
                    layout={'vertical'}
                    autoComplete={'off'}
                    onFinish={handleAddSubscribe}
                >
                    <FormItemWithLabel
                        name={'plan'}
                        required={true}
                        label={'Тарифный план'}
                        requiredText={'Пожалуйста выберите тарифный план.'}
                    >
                        <Select
                            options={plans.map(plan => ({
                                label: plan.name.title,
                                value: plan.planId
                            }))}
                            allowClear={false}
                            loading={plansIsFetching}
                        />
                    </FormItemWithLabel>
                    <FormItemWithLabel
                        name={'start'}
                        required={true}
                        label={'Дата начала'}
                        requiredText={'Пожалуйста выберите дату начала.'}
                    >
                        <DatePicker
                            allowClear={false}
                            format={'DD.MM.YYYY'}
                            style={{ width: '100%' }}
                        />
                    </FormItemWithLabel>
                    <FormItemWithLabel
                        name={'end'}
                        required={true}
                        label={'Дата окончания'}
                        requiredText={'Пожалуйста выберите дату окончания.'}
                    >
                        <DatePicker
                            allowClear={false}
                            format={'DD.MM.YYYY'}
                            style={{ width: '100%' }}
                        />
                    </FormItemWithLabel>
                    <FormItemWithLabel
                        name={'comment'}
                        required={true}
                        label={'Комментарий'}
                        requiredText={'Пожалуйста введите комментарий.'}
                    >
                        <TextArea style={{ minHeight: 100 }} />
                    </FormItemWithLabel>
                </Form>
            </Modal>
        </>
    )
}
