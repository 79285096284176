import type { ReactElement } from 'react'
import { cloneElement, useState, useEffect } from 'react'
import {
    Button,
    Pagination,
    Popconfirm,
    Row,
    Space,
    Spin,
    Table,
    Typography
} from 'antd'
import {
    AuditOutlined,
    CheckOutlined,
    CheckSquareOutlined,
    CloudDownloadOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    MinusOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    QuestionOutlined,
    UserOutlined
} from '@ant-design/icons'
import styles from './styles.module.scss'
import SalesMyRequestsFilter from '../../components/SalesMyRequestsFilter'
import type { ColumnsType } from 'antd/es/table'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { declOfNum, getRole, ignoreKeys, toRuble } from '../../utils/helpers.ts'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { salesMyRequestsSelector } from './selectors.ts'
import dayjs from 'dayjs'
import type { Dispatch } from 'redux'
import type { ILead } from './types.ts'
import { RequestStatus, RequestStatusColor, TypeStatus } from './consts.ts'
import type { NavigateFunction } from 'react-router'
import CustomEmpty from '../../components/CustomEmpty'
import { authorizationSelector } from '../Authorization/selectors.ts'

const { Title } = Typography

const columns = (
    navigate: NavigateFunction,
    dispatch: Dispatch,
    removing: string[],
    paid: string[],
    isAdminOrFranchisee: boolean
): ColumnsType<ILead> => [
    {
        title: 'Тип заявки',
        dataIndex: 'type',
        key: 'type',
        render: value => TypeStatus[value as keyof typeof TypeStatus]
    },
    {
        title: 'Компания',
        dataIndex: 'company',
        key: 'company',
        align: 'center',
        render: company =>
            company ? (
                <Link
                    className={styles.link}
                    style={{ fontSize: 14 }}
                    to={`/companies/${company.company_id}`}
                    target={'_blank'}
                >
                    {company.name}
                </Link>
            ) : (
                <MinusOutlined />
            )
    },
    {
        title: 'Предложение',
        dataIndex: 'event',
        key: 'event',
        align: 'center',
        render: event =>
            event ? (
                <Link
                    className={styles.link}
                    style={{ fontSize: 14 }}
                    to={`/events/${event.event_id}`}
                    target={'_blank'}
                >
                    {event.name}
                </Link>
            ) : (
                <MinusOutlined />
            )
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: value => (
            <span
                style={{
                    color: RequestStatusColor[
                        value as keyof typeof RequestStatusColor
                    ]
                }}
            >
                {RequestStatus[value as keyof typeof RequestStatus]}
            </span>
        )
    },
    {
        title: 'Имя, email или телефон',
        dataIndex: 'user_data',
        key: 'user_data',
        align: 'center',
        render: user_data =>
            user_data ? (
                <p style={{ whiteSpace: 'break-spaces' }}>
                    {[user_data.name, user_data.email, user_data.phone]
                        .filter(Boolean)
                        .join('\n')}
                </p>
            ) : (
                <span
                    style={{
                        fontSize: 12,
                        lineHeight: '10px',
                        color: 'rgba(0, 0, 0, 0.5)'
                    }}
                >
                    {'Данные будут доступны после оплаты'}
                </span>
            )
    },
    {
        title: 'Стоимость заявки, ₽',
        dataIndex: '',
        key: 'coast',
        align: 'center',
        render: data =>
            data.company_id || data.event_id ? (
                `${toRuble(data.coast).toLocaleString('ru')} ₽`
            ) : (
                <MinusOutlined />
            )
    },
    {
        title: 'Оплачено',
        dataIndex: '',
        key: 'payed',
        align: 'center',
        render: data =>
            paid.includes(data.lead_id) ? (
                <Spin />
            ) : data.paid_at !== null ? (
                <CheckOutlined style={{ color: 'green' }} />
            ) : data.company_id || data.event_id ? (
                <>
                    <MinusOutlined style={{ color: 'red' }} />
                    <br />
                    <span
                        onClick={() =>
                            dispatch(actions.pay({ lead_ids: [data.lead_id] }))
                        }
                        className={styles.link}
                    >
                        {'Оплатить'}
                    </span>
                </>
            ) : (
                <UserOutlined />
            )
    },
    {
        title: 'Дата и время',
        dataIndex: 'lead_at',
        key: 'lead_at',
        align: 'center',
        render: data => dayjs(data).format('DD.MM.YYYY HH:mm')
    },
    {
        title: '',
        dataIndex: '',
        key: 'nav',
        align: 'center',
        render: data => (
            <Space>
                <Button
                    type={'primary'}
                    icon={
                        data.company_id || data.event_id ? (
                            <EyeOutlined />
                        ) : (
                            <EditOutlined />
                        )
                    }
                    disabled={paid.includes(data.lead_id)}
                    onClick={() =>
                        void navigate(`/sales/my-requests/${data.lead_id}`)
                    }
                />
                {isAdminOrFranchisee || !(data.company_id || data.event_id) ? (
                    <Popconfirm
                        title={'Удаление заявки'}
                        description={'Вы уверены, что хотите удалить заявку?'}
                        onConfirm={() =>
                            dispatch(actions.remove({ lead_id: data.lead_id }))
                        }
                        okText={'Да'}
                        cancelText={'Нет'}
                    >
                        <Button
                            disabled={paid.includes(data.lead_id)}
                            loading={removing.includes(data.lead_id)}
                            icon={<DeleteOutlined />}
                            danger={true}
                        />
                    </Popconfirm>
                ) : null}
            </Space>
        )
    }
]

function AboutPage(): ReactElement {
    return (
        <Button
            icon={<QuestionOutlined />}
            onClick={() =>
                window.open(
                    'https://mamado.su/journal/vopros-otvet-sobiraem-zayavki-i-lidi-na-mamado-id630',
                    '_blank'
                )
            }
        >
            {'Подробнее о разделе'}
        </Button>
    )
}

export default function SalesMyRequests(): ReactElement {
    const [isShowCheckboxes, setIsShowCheckboxes] = useState(false)
    const [selectedLeads, setSelectedLeads] = useState<string[]>([])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { profile } = useAppSelector(authorizationSelector)
    const { data, isFetching, meta, removing, paid, isDownloadExcel } =
        useAppSelector(salesMyRequestsSelector)

    const isAdminOrFranchisee = profile
        ? getRole(profile.roles) === 'admin' ||
          getRole(profile.roles) === 'franchisee'
        : false

    const [searchParams, setSearchParams] = useSearchParams()

    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    useEffect(() => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        dispatch(actions.fetch({ page, pageSize, filter }))
        setSearchParams({
            ...filter,
            ...(page !== 1 ? { page: page.toString() } : {}),
            ...(pageSize !== 10 ? { pageSize: pageSize.toString() } : {})
        })
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        setSearchParams({
            ...filter,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
        setSelectedLeads([])
        setIsShowCheckboxes(false)
    }

    const handleDownloadExcel = () => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        dispatch(actions.downloadExcel({ page, pageSize, filter }))
    }

    const handlePaySelectedRequests = () => {
        dispatch(actions.pay({ lead_ids: selectedLeads }))
        setSelectedLeads([])
        setIsShowCheckboxes(false)
    }

    const handleToggleSelectLeads = () => {
        if (isShowCheckboxes) {
            setIsShowCheckboxes(false)
            setSelectedLeads([])
        } else {
            setIsShowCheckboxes(true)
            setSelectedLeads(
                data
                    .filter(
                        lead =>
                            !(
                                lead.paid_at ||
                                !(lead.company_id || lead.event_id)
                            )
                    )
                    .map(lead => lead.lead_id)
            )
        }
    }

    return (
        <>
            <Row justify={'end'} style={{ marginBottom: 20 }}>
                <Row>
                    <Button
                        icon={<PlusOutlined />}
                        type={'primary'}
                        onClick={() =>
                            void navigate('/sales/my-requests/create')
                        }
                    >
                        {'Создать заявку'}
                    </Button>
                </Row>
            </Row>
            <div style={{ marginBottom: 25 }} className={styles.container}>
                <Title level={3} style={{ margin: '0 0 25px' }}>
                    {'Мои заявки'}
                </Title>
                <SalesMyRequestsFilter />
            </div>
            <div className={styles.container}>
                <Row justify={'end'} style={{ marginBottom: 15 }}>
                    <Space>
                        <Button
                            onClick={handleToggleSelectLeads}
                            icon={
                                isShowCheckboxes ? (
                                    <MinusOutlined />
                                ) : (
                                    <CheckSquareOutlined />
                                )
                            }
                            disabled={data.every(
                                lead =>
                                    lead.paid_at ||
                                    !(lead.company_id || lead.event_id)
                            )}
                            type={isShowCheckboxes ? 'default' : 'primary'}
                        >
                            {isShowCheckboxes
                                ? 'Скрыть выбор неоплаченных заявок'
                                : 'Выбрать неоплаченные заявки для оплаты'}
                        </Button>
                        <Popconfirm
                            title={`Вы уверены, что хотите оплатить ${selectedLeads.length} ${declOfNum(selectedLeads.length, ['заявку', 'заявки', 'заявок'])}?`}
                            onConfirm={handlePaySelectedRequests}
                            icon={
                                <QuestionCircleOutlined
                                    style={{ color: 'red' }}
                                />
                            }
                            okText={'Да'}
                            cancelText={'Нет'}
                        >
                            <Button
                                disabled={
                                    !isShowCheckboxes ||
                                    selectedLeads.length === 0
                                }
                                loading={
                                    paid.length > 0 &&
                                    isShowCheckboxes &&
                                    selectedLeads.length > 0
                                }
                                type={'primary'}
                                icon={<AuditOutlined />}
                            >
                                {'Оплатить'}
                            </Button>
                        </Popconfirm>
                        <Button
                            loading={isDownloadExcel}
                            onClick={handleDownloadExcel}
                            type={'primary'}
                            icon={<CloudDownloadOutlined />}
                        >
                            {'Скачать (.xlsx)'}
                        </Button>
                        <AboutPage />
                    </Space>
                </Row>
                <Table
                    dataSource={data}
                    columns={columns(
                        navigate,
                        dispatch,
                        removing,
                        paid,
                        isAdminOrFranchisee
                    )}
                    rowKey={'lead_id'}
                    loading={isFetching}
                    pagination={false}
                    locale={{
                        emptyText: (
                            <CustomEmpty>
                                <AboutPage />
                            </CustomEmpty>
                        )
                    }}
                    rowSelection={
                        isShowCheckboxes
                            ? {
                                  hideSelectAll: true,
                                  type: 'checkbox',
                                  renderCell: (
                                      _value,
                                      lead,
                                      _index,
                                      originNode
                                  ) =>
                                      cloneElement(originNode as ReactElement, {
                                          checked: selectedLeads.includes(
                                              lead.lead_id
                                          ),
                                          disabled:
                                              lead.paid_at ||
                                              !(
                                                  lead.company_id ||
                                                  lead.event_id
                                              )
                                      }),
                                  onSelect: lead =>
                                      selectedLeads.includes(lead.lead_id)
                                          ? setSelectedLeads(prev =>
                                                prev.filter(
                                                    id => id !== lead.lead_id
                                                )
                                            )
                                          : setSelectedLeads(prev => [
                                                ...prev,
                                                lead.lead_id
                                            ])
                              }
                            : undefined
                    }
                    sticky={{ offsetHeader: 0 }}
                    footer={() => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Pagination
                                showQuickJumper
                                current={page}
                                pageSize={pageSize}
                                defaultCurrent={1}
                                defaultPageSize={pageSize}
                                showSizeChanger={true}
                                total={meta?.total}
                                onChange={handleChangePagination}
                            />
                        </div>
                    )}
                />
            </div>
        </>
    )
}
