import type { SagaIterator } from '@redux-saga/core'
import { all, call, put, takeEvery, fork } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { actions as profileActions } from '../Authorization/slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'

export function* handleInitialize(): SagaIterator {
    yield put(profileActions.fetchProfile())
}

export function* watchInitialize(): SagaIterator {
    yield takeEvery(actions.initialize, handleInitialize)
}

export function* handleFetchCompanyProperties(): SagaIterator {
    try {
        const { data } = yield call(api.fetchCompanyProperties)
        yield put(actions.fetchCompanyPropertiesSuccess({ data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchCompanyPropertiesFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCompanyProperties(): SagaIterator {
    yield takeEvery(
        actions.fetchCompanyProperties,
        handleFetchCompanyProperties
    )
}

export function* handleFetchPlans(): SagaIterator {
    try {
        const { data } = yield call(api.fetchPlans)
        yield put(actions.fetchPlansSuccess({ data: data.data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchPlansFailure({
                error: message
            })
        )
    }
}

export function* watchFetchPlans(): SagaIterator {
    yield takeEvery(actions.fetchPlans, handleFetchPlans)
}

export default function* watchApplication(): SagaIterator {
    yield all([
        fork(watchInitialize),
        fork(watchFetchCompanyProperties),
        fork(watchFetchPlans)
    ])
}
