import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    RemovePayload,
    RemoveSuccessPayload,
    RemoveFailurePayload,
    PayPayload,
    PaySuccessPayload,
    PayFailurePayload,
    DownloadExcelPayload,
    DownloadExcelSuccessPayload,
    DownloadExcelFailurePayload
} from './types.ts'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
const initialState: InitialStateType = {
    data: [],
    meta: null,
    filter: {},
    isFetching: false,
    isDownloadExcel: false,
    removing: [],
    paid: [],
    error: null
}

const salesMyRequestsSlice = createSlice({
    name: 'salesMyRequests',
    initialState,
    reducers: {
        fetch(state: InitialStateType, action: PayloadAction<FetchPayload>) {
            state.filter = action.payload.filter
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        remove(state: InitialStateType, action: PayloadAction<RemovePayload>) {
            state.removing.push(action.payload.lead_id)
            state.error = null
        },
        removeSuccess(
            state: InitialStateType,
            action: PayloadAction<RemoveSuccessPayload>
        ) {
            state.removing = state.removing.filter(
                id => id !== action.payload.lead_id
            )
        },
        removeFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveFailurePayload>
        ) {
            state.removing = state.removing.filter(
                id => id !== action.payload.lead_id
            )
            state.error = action.payload.error
        },
        pay(state: InitialStateType, action: PayloadAction<PayPayload>) {
            state.paid = [...state.paid, ...action.payload.lead_ids]
            state.error = null
        },
        paySuccess(
            state: InitialStateType,
            action: PayloadAction<PaySuccessPayload>
        ) {
            state.paid = state.paid.filter(
                id => !action.payload.lead_ids.includes(id)
            )
        },
        payFailure(
            state: InitialStateType,
            action: PayloadAction<PayFailurePayload>
        ) {
            state.paid = state.paid.filter(
                id => !action.payload.lead_ids.includes(id)
            )
            state.error = action.payload.error
        },
        downloadExcel(
            state: InitialStateType,
            _action: PayloadAction<DownloadExcelPayload>
        ) {
            state.isDownloadExcel = true
        },
        downloadExcelSuccess(
            state: InitialStateType,
            _action: PayloadAction<DownloadExcelSuccessPayload>
        ) {
            state.isDownloadExcel = false
        },
        downloadExcelFailure(
            state: InitialStateType,
            action: PayloadAction<DownloadExcelFailurePayload>
        ) {
            state.isDownloadExcel = false
            state.error = action.payload.error
        }
    }
})

export const actions = salesMyRequestsSlice.actions

export default salesMyRequestsSlice.reducer
