import type { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { eventWizardSelector } from '../../selectors'
import {
    Button,
    Checkbox,
    DatePicker,
    Input,
    Radio,
    Space,
    Typography
} from 'antd'
import RadioDescription from '../../../../components/RadioDescription'
import NextStepButton from '../../../../components/NextStepButton'
import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import isUrl from 'is-url'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

const { Paragraph, Text } = Typography
const { TextArea } = Input

const MOSCOW_TIMEZONE = 'Europe/Moscow'

const variants = [
    {
        value: 'booking',
        title: 'Забронировать',
        description: 'Переход на сторонний ресурс с бронированием'
    },
    {
        value: 'pay',
        title: 'Перейти к оплате',
        description: 'Переход на сторонний ресурс с оплатой'
    },
    {
        value: 'sign-up',
        title: 'Записаться',
        description: 'Переход на сторонний ресурс с возможностью записи'
    },
    {
        value: 'website',
        title: 'Перейти на сайт',
        description:
            'Если проводите вебинар, оформляете бронирование/оплату в стороннем ресурсе'
    }
]

const values = {
    favorite: {
        selected: true,
        value: undefined,
        params: {}
    },
    booking: {
        selected: false,
        value: '',
        params: {}
    },
    pay: {
        selected: false,
        value: '',
        params: {}
    },
    'sign-up': {
        selected: false,
        value: '',
        params: {}
    },
    'business-promocode': {
        selected: false,
        value: '',
        params: {}
    },
    website: {
        selected: false,
        value: '',
        params: {}
    }
}

function SiteCheckedInput({
    value,
    handleChangeValue,
    setIsConfirmed,
    isAllowEmpty
}: {
    value?: string
    handleChangeValue: (value: string) => void
    setIsConfirmed: (isConfirmed: boolean) => void
    isAllowEmpty?: boolean
}): ReactElement {
    const [prevValue, setPrevValue] = useState(value)
    const [typeCheck, setTypeCheck] = useState<false | 'success' | 'failure'>(
        isUrl(value ?? '') ? 'success' : false
    )
    const [isTouched, setIsTouched] = useState(false)

    useEffect(() => {
        setIsConfirmed(typeCheck === 'success')
    }, [typeCheck])

    const handleConfirmUrl = (value?: string) => {
        if (isAllowEmpty && !value) {
            setTypeCheck('success')
            return
        }
        const valueWithProtocol = value?.startsWith('http')
            ? value
            : `http://${value}`
        const isChecked = isUrl(valueWithProtocol)
        setTypeCheck(isChecked ? 'success' : 'failure')
        handleChangeValue(valueWithProtocol)
        queueMicrotask(() => setPrevValue(valueWithProtocol))
    }

    return (
        <>
            <label htmlFor={'input-checked-site'}>
                {'Ссылка на сайт, другой ресурс, мессенджер'}
            </label>
            <Input
                id={'input-checked-site'}
                suffix={
                    <Button
                        style={{
                            display:
                                prevValue === value || (isAllowEmpty && !value)
                                    ? 'none'
                                    : 'block'
                        }}
                        onClick={() => handleConfirmUrl(value)}
                    >
                        {'Ок'}
                    </Button>
                }
                value={value}
                style={{ marginTop: 5, height: 42 }}
                placeholder={'https://'}
                onChange={({ target }) => {
                    handleChangeValue(target.value)
                    setTypeCheck(false)
                    setIsTouched(true)
                }}
            />
            {typeCheck && isTouched ? (
                <div
                    style={{
                        fontSize: 14,
                        color: typeCheck === 'success' ? 'green' : 'red'
                    }}
                >
                    {typeCheck === 'success'
                        ? 'Адрес введен корректно'
                        : 'Адрес введен не верно!'}
                </div>
            ) : null}
        </>
    )
}

export default function TargetStep(): ReactElement {
    const [targetActions, setTargetActions] = useState(values)
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [prevPromocodeParams, setPrevPromocodeParams] = useState<Record<
        string,
        unknown
    > | null>(null)

    const dispatch = useDispatch()

    const { currentEvent, hasStepChanges, isSaving } =
        useAppSelector(eventWizardSelector)

    const selectedTargetAction: keyof typeof targetActions | undefined =
        useMemo(() => {
            for (const item in targetActions) {
                if (
                    targetActions[item as keyof typeof targetActions].selected
                ) {
                    return item as keyof typeof targetActions
                }
            }
        }, [targetActions])

    const selectedParams: Record<string, undefined | string> =
        selectedTargetAction ? targetActions[selectedTargetAction].params : {}

    const businessPromocode =
        currentEvent && currentEvent.business_promocodes?.length > 0
            ? currentEvent.business_promocodes[0]
            : null

    const promocodeParams = businessPromocode
        ? {
              promocode: businessPromocode.promocode,
              url: businessPromocode.url,
              description: businessPromocode.description,
              lead_generation: businessPromocode.lead_generation ? '1' : '0',
              expiration_at: businessPromocode.expiration_at
                  ? dayjs(businessPromocode.expiration_at)
                  : null
          }
        : null

    useEffect(() => {
        if (currentEvent) {
            let selectedTargetKey, selectedTargetValue

            for (const key in targetActions) {
                if (targetActions[key as keyof typeof targetActions].selected) {
                    selectedTargetKey = key
                    selectedTargetValue =
                        targetActions[key as keyof typeof targetActions].value
                    break
                }
            }

            const hasChange =
                currentEvent.target_action != selectedTargetKey ||
                (selectedTargetKey !== 'favorite' &&
                selectedTargetKey !== 'business-promocode'
                    ? currentEvent.target_action_url != selectedTargetValue
                    : selectedTargetKey === 'business-promocode'
                      ? JSON.stringify(prevPromocodeParams) !==
                        JSON.stringify(selectedParams)
                      : false)

            if (prevPromocodeParams === null && promocodeParams) {
                setPrevPromocodeParams(promocodeParams)
                setTargetActions(prev => ({
                    ...prev,
                    'business-promocode': {
                        ...prev['business-promocode'],
                        params: { ...promocodeParams }
                    }
                }))
            }

            dispatch(actions.setHasStepChanges(hasChange))
        }
    }, [currentEvent, targetActions])

    // useEffect(() => {
    //     api.info({
    //         message: 'Информация',
    //         description:
    //             'По умолчанию, без добавленного "Премиум статуса" к компании, будет выводиться кнопка "Добавить в избранное"',
    //         placement: 'bottomRight',
    //         duration: 5000
    //     })
    // }, [])

    const handleUndoChanges = () => {
        if (currentEvent) {
            const newValues = { ...targetActions }
            for (const item in newValues) {
                const isCurrent = currentEvent.target_action === item
                newValues[item as keyof typeof newValues].selected = isCurrent
                if (
                    isCurrent &&
                    !['favorite', 'business-promocode'].includes(item)
                ) {
                    newValues[item as keyof typeof newValues].value =
                        currentEvent.target_action_url ?? undefined
                }

                if (item === 'business-promocode') {
                    newValues[item as keyof typeof newValues].params = {
                        ...promocodeParams
                    }
                }
            }
            setTargetActions({ ...newValues })
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    const handleChangeTargetActions = (value: keyof typeof targetActions) => {
        const newValues = targetActions
        for (const item in targetActions) {
            newValues[item as keyof typeof targetActions].selected =
                value === item
        }
        setTargetActions({ ...newValues })
    }

    const handleChangeValue = (value: string) => {
        const newValues = targetActions
        newValues[selectedTargetAction as keyof typeof newValues].value = value
        setTargetActions({ ...newValues })
    }

    const handleChangeParams = (
        type: keyof typeof values,
        key: string,
        value: Dayjs | string
    ) => {
        const newValues = { ...targetActions }
        newValues[type].params = {
            ...newValues[type].params,
            [key]: value
        }
        setTargetActions({ ...newValues })
    }

    const handleSaveTarget = () => {
        if (currentEvent) {
            let form
            if (selectedTargetAction === 'business-promocode') {
                form = {
                    target_action: 'business-promocode',
                    business_promocodes: [
                        {
                            promocode: selectedParams.promocode,
                            url: selectedParams.url,
                            description: selectedParams.description,
                            lead_generation:
                                selectedParams.lead_generation === '1',
                            expiration_at: dayjs(
                                selectedParams.expiration_at
                            ).isValid()
                                ? dayjs(
                                      selectedParams.expiration_at?.toString()
                                  )
                                      .tz(MOSCOW_TIMEZONE)
                                      .utc()
                                : null
                        }
                    ]
                }
            } else {
                form = {
                    target_action: selectedTargetAction,
                    target_action_url:
                        targetActions[
                            selectedTargetAction as keyof typeof targetActions
                        ].value
                }
            }

            setPrevPromocodeParams(null)

            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form
                })
            )
        }
    }

    return (
        <>
            <Space direction={'vertical'}>
                <Radio.Group
                    onChange={({ target }) =>
                        handleChangeTargetActions(target.value)
                    }
                    value={selectedTargetAction}
                >
                    <Space direction={'vertical'}>
                        {variants.map(radio => (
                            <Radio key={radio.value} value={radio.value}>
                                {radio.title}
                                <RadioDescription>
                                    {radio.description}
                                </RadioDescription>
                            </Radio>
                        ))}
                    </Space>
                </Radio.Group>
                {selectedTargetAction &&
                selectedTargetAction !== 'business-promocode' &&
                targetActions[
                    selectedTargetAction as keyof typeof targetActions
                ].value != null ? (
                    <SiteCheckedInput
                        value={targetActions[selectedTargetAction].value}
                        handleChangeValue={handleChangeValue}
                        setIsConfirmed={setIsConfirmed}
                    />
                ) : null}
                <Radio
                    checked={selectedTargetAction === 'business-promocode'}
                    onClick={() =>
                        handleChangeTargetActions('business-promocode')
                    }
                >
                    {'Получить промокод на скидку'}
                    <RadioDescription>
                        {
                            'Промокод на скидку, при получении фиксируется заявка с контактами в разделе «Мои заявки»'
                        }
                    </RadioDescription>
                </Radio>
                {selectedTargetAction === 'business-promocode' ? (
                    <>
                        <label
                            htmlFor={'input-promo'}
                            className={classNames([
                                styles.label,
                                styles.label__required
                            ])}
                        >
                            {'Промокод'}
                        </label>
                        <Input
                            id={'input-promo'}
                            placeholder={'Промокод'}
                            addonAfter={
                                selectedParams.promocode &&
                                selectedParams.promocode.length > 3 ? (
                                    <CheckCircleOutlined
                                        style={{ color: 'green' }}
                                    />
                                ) : (
                                    <CloseCircleOutlined
                                        style={{ color: 'red' }}
                                    />
                                )
                            }
                            value={selectedParams.promocode}
                            maxLength={15}
                            className={styles.input}
                            onChange={event =>
                                handleChangeParams(
                                    'business-promocode',
                                    'promocode',
                                    event.target.value
                                )
                            }
                        />
                        <SiteCheckedInput
                            value={selectedParams.url}
                            isAllowEmpty={true}
                            setIsConfirmed={setIsConfirmed}
                            handleChangeValue={value =>
                                handleChangeParams(
                                    'business-promocode',
                                    'url',
                                    value
                                )
                            }
                        />
                        <Text type={'secondary'} className={styles.description}>
                            {
                                'Ссылка, по которой можно узнать подробности или активировать промокод'
                            }
                        </Text>
                        <label
                            htmlFor={'input-description'}
                            className={styles.label}
                        >
                            {'Описание'}
                        </label>
                        <TextArea
                            id={'input-description'}
                            placeholder={'Описание'}
                            value={selectedParams.description}
                            onChange={event =>
                                handleChangeParams(
                                    'business-promocode',
                                    'description',
                                    event.target.value
                                )
                            }
                        />
                        <Text type={'secondary'} className={styles.description}>
                            {'Укажите условия применения промокода'}
                        </Text>
                        <label htmlFor={'input-date'} className={styles.label}>
                            {'Дата окончания действия промокода'}
                        </label>
                        <DatePicker
                            id={'input-date'}
                            style={{ width: '100%' }}
                            placeholder={'Дата окончания действия промокода'}
                            format={'DD.MM.YYYY HH:mm'}
                            showTime
                            value={
                                selectedParams.expiration_at
                                    ? dayjs(selectedParams.expiration_at)
                                    : undefined
                            }
                            onChange={date =>
                                handleChangeParams(
                                    'business-promocode',
                                    'expiration_at',
                                    date
                                )
                            }
                        />
                        <Checkbox
                            checked={selectedParams.lead_generation === '1'}
                            onChange={event =>
                                handleChangeParams(
                                    'business-promocode',
                                    'lead_generation',
                                    event.target.checked ? '1' : '0'
                                )
                            }
                        >
                            {'Собирать заявки по полученным промокодам'}
                        </Checkbox>
                        <Paragraph style={{ fontSize: 12 }} type={'secondary'}>
                            {'Заявки будут собираться в разделе Продажи '}
                            <Link to={'/sales/my-requests'} target={'_blank'}>
                                {'«Мои заявки»'}
                            </Link>
                            <br />
                            {'Заявки оплачиваются отдельно с баланса'}
                        </Paragraph>
                    </>
                ) : null}
                {/*<Title level={5} style={{ margin: '10px 0 0' }}>*/}
                {/*    {'По умолчанию'}*/}
                {/*</Title>*/}
                {/*<Radio*/}
                {/*    checked={selectedTargetAction === 'favorite'}*/}
                {/*    onClick={() => handleChangeTargetActions('favorite')}*/}
                {/*>*/}
                {/*    {'Добавить в избранное'}*/}
                {/*    <RadioDescription>*/}
                {/*        {*/}
                {/*            'Добавление предложения в список избранного пользователя'*/}
                {/*        }*/}
                {/*    </RadioDescription>*/}
                {/*</Radio>*/}
            </Space>
            <NextStepButton
                disabled={
                    !selectedTargetAction ||
                    !hasStepChanges ||
                    (selectedTargetAction === 'business-promocode'
                        ? (selectedParams.url ? !isConfirmed : false) ||
                          !selectedParams.promocode ||
                          (!!selectedParams.promocode &&
                              selectedParams.promocode.length < 4)
                        : !isConfirmed)
                }
                loading={isSaving}
                onClick={handleSaveTarget}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            {/*{contextHolder}*/}
        </>
    )
}
