import type {
    CreateFailurePayload,
    CreatePayload,
    CreateSuccessPayload,
    EditFailurePayload,
    EditPayload,
    EditSuccessPayload,
    FetchFailurePayload,
    FetchPayload,
    FetchSuccessPayload,
    InitialStateType,
    PayFailurePayload,
    PayPayload,
    PaySuccessPayload
} from './types.ts'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

const initialState: InitialStateType = {
    currentRequest: null,
    isFetching: false,
    isProcessWizard: false,
    isProcessPaid: false,
    error: null
}

const salesMyRequestsWizardSlice = createSlice({
    name: 'salesMyRequestsWizard',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.currentRequest = action.payload.data
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        create(state: InitialStateType, _action: PayloadAction<CreatePayload>) {
            state.isProcessWizard = true
            state.error = null
        },
        createSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateSuccessPayload>
        ) {
            state.isProcessWizard = false
        },
        createFailure(
            state: InitialStateType,
            action: PayloadAction<CreateFailurePayload>
        ) {
            state.isProcessWizard = false
            state.error = action.payload.error
        },
        edit(state: InitialStateType, _action: PayloadAction<EditPayload>) {
            state.isProcessWizard = true
            state.error = null
        },
        editSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditSuccessPayload>
        ) {
            state.isProcessWizard = false
        },
        editFailure(
            state: InitialStateType,
            action: PayloadAction<EditFailurePayload>
        ) {
            state.isProcessWizard = false
            state.error = action.payload.error
        },
        pay(state: InitialStateType, _action: PayloadAction<PayPayload>) {
            state.isProcessPaid = true
            state.error = null
        },
        paySuccess(
            state: InitialStateType,
            _action: PayloadAction<PaySuccessPayload>
        ) {
            state.isProcessPaid = false
        },
        payFailure(
            state: InitialStateType,
            action: PayloadAction<PayFailurePayload>
        ) {
            state.isProcessPaid = false
            state.error = action.payload.error
        },
        destroy(_state: InitialStateType, _action: PayloadAction<undefined>) {
            return initialState
        }
    }
})

export const actions = salesMyRequestsWizardSlice.actions

export default salesMyRequestsWizardSlice.reducer
