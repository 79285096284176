import { Button, Table } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import TitleStep from '../../../../components/TitleStep'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companyWizardSelector } from '../../selectors'
import { useEffect, useState } from 'react'
import type { ColumnsType } from 'antd/es/table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity } from '@fortawesome/free-solid-svg-icons'
import NextStepButton from '../../../../components/NextStepButton'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import AreaSelector from '../../../AreaSelector'
import CustomEmpty from '../../../../components/CustomEmpty'

interface DataType {
    area_id: string
    name: string
    onRemove: () => void
}

const columns: ColumnsType<DataType> = [
    {
        title: '',
        dataIndex: '',
        key: 'icon',
        align: 'center',
        render: () => <FontAwesomeIcon icon={faCity} color={'#29A71A'} />
    },
    {
        title: 'Выбранный город',
        dataIndex: 'name',
        key: 'name',
        width: '100%'
    },
    {
        title: '',
        dataIndex: '',
        key: 'remove',
        render: (data, _, index) => (
            <Button danger onClick={() => data.onRemove(index)}>
                <DeleteOutlined />
            </Button>
        )
    }
]

export default function GeneralCityStep() {
    const [generalCity, setGeneralCity] = useState<DataType[]>([])
    const [isModal, setIsModal] = useState(false)

    const dispatch = useDispatch()

    const { isSaving, hasStepChanges, currentCompany } = useAppSelector(
        companyWizardSelector
    )

    const onRemove = (): void => {
        setGeneralCity([])
    }

    useEffect(() => {
        if (currentCompany && currentCompany.area) {
            const hasChanges = !generalCity.find(
                item => item.area_id === currentCompany.area!.area_id
            )
            dispatch(actions.setHasStepChanges(hasChanges))
        } else {
            dispatch(actions.setHasStepChanges(generalCity.length > 0))
        }
    }, [currentCompany, generalCity])

    const handleUndoChanges = () => {
        if (currentCompany && currentCompany.area) {
            setGeneralCity([
                {
                    name: currentCompany.area.name,
                    area_id: currentCompany.area.area_id,
                    onRemove
                }
            ])
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleSaveGeneralCity = (isTrusted: boolean): void => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        area_id: generalCity[0].area_id
                    },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    const handleAddGeneralCity = (
        data: { area_id: string; title: string }[]
    ): void => {
        setGeneralCity([
            {
                name: data[0].title,
                area_id: data[0].area_id,
                onRemove
            }
        ])
        setIsModal(false)
    }

    return (
        <>
            <TitleStep
                rightElement={
                    <Button
                        onClick={() => setIsModal(true)}
                        icon={<PlusOutlined />}
                        loading={isSaving}
                    >
                        {generalCity.length == 0
                            ? 'Добавить город'
                            : 'Изменить город'}
                    </Button>
                }
            >
                {'Основной город'}
            </TitleStep>
            <Table
                columns={columns}
                dataSource={generalCity}
                rowKey={record => record.area_id}
                pagination={false}
                locale={{
                    emptyText: (
                        <CustomEmpty
                            description={'Основной город не добавлен'}
                        />
                    )
                }}
            />
            <NextStepButton
                disabled={generalCity.length === 0 || !hasStepChanges}
                loading={isSaving}
                onClick={handleSaveGeneralCity}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            <AreaSelector
                showModal={isModal}
                onClose={() => setIsModal(false)}
                onAdd={handleAddGeneralCity}
                isTreeCheckable={false}
                title={
                    generalCity.length == 0
                        ? 'Добавить город'
                        : 'Изменить город'
                }
                okButtonText={generalCity.length == 0 ? 'Добавить' : 'Изменить'}
            />
        </>
    )
}
