import { useEffect, useState } from 'react'
import type { ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Col, DatePicker, Form, Row, Select } from 'antd'
import dayjs from 'dayjs'
import ruRU from 'antd/es/date-picker/locale/ru_RU'
import { SearchOutlined } from '@ant-design/icons'
import FormItemWithLabel from '../FormItemWithLabel'
import MyRequestsClientEmailSelector from '../../containers/MyRequestsClientEmailSelector'
import MyRequestsClientPhoneSelector from '../../containers/MyRequestsClientPhoneSelector'
import MyRequestsClientNameSelector from '../../containers/MyRequestsClientNameSelector'
import MyRequestsOwnerSelector from '../../containers/MyRequestsOwnerSelector'
import MyRequestsCompanySelector from '../../containers/MyRequestsCompanySelector'
import MyRequestsEventSelector from '../../containers/MyRequestsEventSelector'

const { RangePicker } = DatePicker

export default function SalesMyRequestsFilter(): ReactElement {
    const [dateUpdate, setDateUpdate] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    const [form] = Form.useForm()

    useEffect(() => {
        form.resetFields()
        const result: Record<string, unknown> = {}
        searchParams.forEach((value, key) => {
            if (key === 'lead') {
                const [from, to] = value.split(',')

                const regExp = /(\d{4})(\d{2})(\d{2})/

                const formatFrom = from.replace(regExp, '$1-$2-$3')
                const formatTo = to.replace(regExp, '$1-$2-$3')

                result[key] = [dayjs(formatFrom), dayjs(formatTo)]
            } else {
                result[key] = value.includes('{d}')
                    ? value.split('{d}')[0]
                    : value
            }
        })
        form.setFieldsValue(result)
        setDateUpdate(Date.now().toString())
    }, [searchParams])

    const handleChangeFormItem = (
        key: string,
        value: string | string[] | dayjs.ConfigType[]
    ): void => {
        form.setFieldValue(key, value)
    }

    const handleFinishForm = (values: Record<string, unknown>): void => {
        const result: Record<string, unknown> = {}

        for (const param in values) {
            if (param === 'lead' && Array.isArray(values[param])) {
                const [from, to] = values[param] as dayjs.ConfigType[]
                const formatFrom = dayjs(from).format('YYYYMMDD')
                const formatTo = dayjs(to).format('YYYYMMDD')

                result[param] = `${formatFrom},${formatTo}`
                continue
            } else if (Array.isArray(values[param])) {
                const valueArray = (values[param] as string[]).join(',')
                if (valueArray.length > 0) {
                    result[param] = valueArray
                }
                continue
            }

            const isEmptyString =
                typeof values[param] === 'string' &&
                (values[param] as string).length === 0

            if (values[param] != null && !isEmptyString) {
                result[param] = values[param]
            }
        }

        setSearchParams(result as Record<string, string>)
    }

    return (
        <Form
            key={dateUpdate}
            form={form}
            layout={'vertical'}
            onFinish={handleFinishForm}
            autoComplete={'off'}
        >
            <Row gutter={10}>
                <Col push={0} span={24}>
                    <Row gutter={10}>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'lead'}
                                label={'Период (получения заявки)'}
                            >
                                <RangePicker
                                    style={{ width: '100%' }}
                                    format={'DD.MM.YYYY'}
                                    locale={ruRU}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'lead',
                                            value as dayjs.ConfigType[]
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={3}>
                            <FormItemWithLabel name={'status'} label={'Статус'}>
                                <Select
                                    placeholder={'Статус'}
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    options={[
                                        {
                                            value: 'new',
                                            label: 'Новая'
                                        },
                                        {
                                            value: 'processed',
                                            label: 'Обработана'
                                        },
                                        {
                                            value: 'in_progress',
                                            label: 'В работе'
                                        },
                                        {
                                            value: 'closed',
                                            label: 'Закрыта'
                                        }
                                    ]}
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={3}>
                            <FormItemWithLabel
                                name={'type'}
                                label={'Тип заявки'}
                            >
                                <Select
                                    placeholder={'Тип заявки'}
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    options={[
                                        {
                                            value: 'promocode',
                                            label: 'Получение промокода'
                                        },
                                        {
                                            value: 'booking',
                                            label: 'Бронирование'
                                        },
                                        {
                                            value: 'purchase',
                                            label: 'Покупка'
                                        }
                                    ]}
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'is_paid'}
                                label={'Оплата'}
                            >
                                <Select
                                    placeholder={'Оплата'}
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    options={[
                                        {
                                            value: '1',
                                            label: 'Оплачено'
                                        },
                                        {
                                            value: '0',
                                            label: 'Не оплачено'
                                        }
                                    ]}
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'business_account_id'}
                                label={'Владелец'}
                            >
                                <MyRequestsOwnerSelector />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'client_email'}
                                label={'Email клиента'}
                            >
                                <MyRequestsClientEmailSelector />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'client_phone'}
                                label={'Телефон клиента'}
                            >
                                <MyRequestsClientPhoneSelector />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'client_name'}
                                label={'Имя клиента'}
                            >
                                <MyRequestsClientNameSelector />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'company_id'}
                                label={'Компания'}
                            >
                                <MyRequestsCompanySelector />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'event_id'}
                                label={'Предложение'}
                            >
                                <MyRequestsEventSelector />
                            </FormItemWithLabel>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Form.Item>
                    <Button
                        type={'primary'}
                        icon={<SearchOutlined />}
                        size={'middle'}
                        htmlType={'submit'}
                    >
                        {'Искать заявки'}
                    </Button>
                </Form.Item>
                <Button
                    style={{ marginLeft: 10 }}
                    size={'middle'}
                    onClick={() => {
                        setSearchParams({})
                        form.resetFields()
                    }}
                >
                    {'Сбросить'}
                </Button>
            </Row>
        </Form>
    )
}
