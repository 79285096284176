import {
    Button,
    Modal,
    Table,
    notification,
    Typography,
    Row,
    Tooltip,
    message
} from 'antd'
import {
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons'
import type { DaDataAddress, DaDataSuggestion } from 'react-dadata'
import TitleStep from '../../../../components/TitleStep'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companyWizardSelector } from '../../selectors'
import { useEffect, useState } from 'react'
import {
    mapDadataGeoObjects,
    shortYandexGeocoder
} from '../../../../utils/helpers'
import type { ColumnsType } from 'antd/es/table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import NextStepButton from '../../../../components/NextStepButton'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { YMaps, Map, ZoomControl, Placemark } from '@pbe/react-yandex-maps'
import axios from 'axios'
import { AddressSuggestions } from 'react-dadata'
import * as Sentry from '@sentry/react'
import type { IYandexGeoCoder } from './types.ts'
import CustomEmpty from '../../../../components/CustomEmpty'

const { Paragraph } = Typography

interface DataType {
    lat: string | null
    lng: string | null
    text_address: string
    additional_text_address: string
    onRemove?: (indexRemove: number) => void
}

const columns: ColumnsType<DataType> = [
    {
        title: '',
        dataIndex: '',
        key: 'icon',
        align: 'center',
        render: () => <FontAwesomeIcon icon={faLocationDot} color={'#419FD9'} />
    },
    {
        title: 'Адрес',
        dataIndex: '',
        key: 'address',
        width: '100%',
        render: data => (
            <>
                <b>{data.text_address}</b>
                <br />
                {data.additional_text_address}
            </>
        )
    },
    {
        title: '',
        dataIndex: '',
        key: 'remove',
        render: (data, _, index) =>
            data.onRemove ? (
                <Button danger onClick={() => data.onRemove(index)}>
                    <DeleteOutlined />
                </Button>
            ) : (
                <></>
            )
    }
]

export default function AddressesStep() {
    const [selectedAddress, setSelectedAddress] =
        useState<DaDataSuggestion<DaDataAddress>>()
    const [addresses, setAddresses] = useState<DataType[]>([])
    const [additionalAddresses, setAdditionalAddresses] = useState<DataType[]>(
        []
    )
    const [isModal, setIsModal] = useState(false)
    const [isModalAdditional, setIsModalAdditional] = useState(false)
    const [selectedCoords, setSelectedCoords] = useState<
        undefined | [number, number]
    >(undefined)

    const dispatch = useDispatch()

    const [messageApi, contextHolder] = message.useMessage()

    const { isSaving, hasStepChanges, currentCompany } = useAppSelector(
        companyWizardSelector
    )

    // const isFilial = currentCompany?.is_filial
    const isFilial = true

    const onRemove = (indexRemove: number): void => {
        setAddresses(prev => prev.filter((_, index) => index !== indexRemove))
    }

    const onRemoveAdditional = (indexRemove: number): void => {
        setAdditionalAddresses(prev =>
            prev.filter((_, index) => index !== indexRemove)
        )
    }

    useEffect(() => {
        if (currentCompany) {
            const result: { lat: string | null; lng: string | null }[] =
                currentCompany.lat && currentCompany.lng
                    ? [
                          {
                              lat: currentCompany.lat,
                              lng: currentCompany.lng
                          }
                      ]
                    : []

            if (Array.isArray(currentCompany.children)) {
                for (const item of currentCompany.children) {
                    result.push({
                        lat: item.lat,
                        lng: item.lng
                    })
                }
            }

            const parseAdditionalAddresses = JSON.parse(
                currentCompany.additional_addresses as string
            )

            const hasAdditionalChanges = parseAdditionalAddresses
                ? parseAdditionalAddresses?.length !==
                      additionalAddresses.length ||
                  !parseAdditionalAddresses?.every((item: DataType) =>
                      additionalAddresses.some(
                          address =>
                              address.lat === item.lat &&
                              address.lng === item.lng
                      )
                  )
                : additionalAddresses.length > 0

            const hasChanges =
                addresses.length !== result.length ||
                !addresses.every(address =>
                    result.some(
                        item =>
                            item.lat === address.lat && item.lng === address.lng
                    )
                ) ||
                hasAdditionalChanges

            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentCompany, addresses, additionalAddresses])

    const handleUndoChanges = () => {
        if (currentCompany && currentCompany.lat && currentCompany.lng) {
            const isFilial = currentCompany.is_filial

            const result: DataType[] = [
                {
                    lat: currentCompany.lat,
                    lng: currentCompany.lng,
                    text_address: currentCompany.text_address ?? '',
                    additional_text_address:
                        currentCompany.additional_text_address ?? '',
                    onRemove: isFilial ? undefined : onRemove
                }
            ]

            if (Array.isArray(currentCompany.children)) {
                for (const item of currentCompany.children) {
                    result.push({
                        lat: item.lat,
                        lng: item.lng,
                        text_address: item.text_address ?? '',
                        additional_text_address:
                            item.additional_text_address ?? '',
                        onRemove: isFilial ? undefined : onRemove
                    })
                }
            }

            setAddresses(result)

            if (currentCompany.additional_addresses) {
                const additionalAddresses: undefined | DataType[] = JSON.parse(
                    currentCompany.additional_addresses
                )
                if (additionalAddresses) {
                    setAdditionalAddresses(
                        additionalAddresses.map(address => ({
                            ...address,
                            onRemove: onRemoveAdditional
                        }))
                    )
                }
            }
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleAddAddress = () => {
        if (selectedAddress) {
            const hasAddress = addresses.some(
                item =>
                    item.lat === selectedAddress.data.geo_lat &&
                    item.lng === selectedAddress.data.geo_lon
            )

            if (hasAddress) {
                notification.open({
                    type: 'error',
                    message: 'Ошибка',
                    description: 'Такой адрес уже добавлен!',
                    placement: 'top'
                })
                setSelectedAddress(undefined)
                return
            }

            const { unrestricted_value, data } = selectedAddress

            let { name, value } = mapDadataGeoObjects(data)

            if (unrestricted_value.includes('{d}')) {
                const arrName = unrestricted_value.split('{d}')
                name = arrName[1]
                value = arrName[0]
            }

            if (isFilial) {
                setAddresses([
                    {
                        lat: data.geo_lat,
                        lng: data.geo_lon,
                        text_address: name ?? '',
                        additional_text_address: value ?? '',
                        onRemove
                    }
                ])
            } else {
                setAddresses(prev => [
                    ...prev,
                    {
                        lat: data.geo_lat,
                        lng: data.geo_lon,
                        text_address: name ?? '',
                        additional_text_address: value ?? '',
                        onRemove
                    }
                ])
            }

            setSelectedAddress(undefined)
            setIsModal(false)
        }
    }

    const handleAddAdditionalAddress = () => {
        if (selectedAddress) {
            const hasAddress = additionalAddresses.some(
                item =>
                    item.lat === selectedAddress.data.geo_lat &&
                    item.lng === selectedAddress.data.geo_lon
            )

            if (hasAddress) {
                notification.open({
                    type: 'error',
                    message: 'Ошибка',
                    description: 'Такой адрес уже добавлен!',
                    placement: 'top'
                })
                setSelectedAddress(undefined)
                return
            }

            const { unrestricted_value, data } = selectedAddress

            let { name, value } = mapDadataGeoObjects(data)

            if (unrestricted_value.includes('{d}')) {
                const arrName = unrestricted_value.split('{d}')
                name = arrName[1]
                value = arrName[0]
            }

            setAdditionalAddresses(prev => [
                ...prev,
                {
                    lat: data.geo_lat,
                    lng: data.geo_lon,
                    text_address: name ?? '',
                    additional_text_address: value ?? '',
                    onRemove: onRemoveAdditional
                }
            ])

            setSelectedAddress(undefined)
            setIsModalAdditional(false)
        }
    }

    const handleSaveAddress = (isTrusted: boolean): void => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        addresses: addresses.map(item => ({
                            lat: item.lat,
                            lng: item.lng,
                            text_address: item.text_address,
                            additional_text_address:
                                item.additional_text_address
                        })),
                        additional_addresses:
                            additionalAddresses.length > 0
                                ? additionalAddresses
                                : null
                    },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    const fetchMapAddress = async (lat: number, lon: number) => {
        try {
            const { data: yandexData }: { data: IYandexGeoCoder } =
                await axios.get('https://geocode-maps.yandex.ru/1.x/', {
                    params: {
                        apikey: process.env.YANDEX_API_KEY,
                        format: 'json',
                        results: 1,
                        geocode: `${lon},${lat}`
                    }
                })
            const findYandexCoords =
                yandexData.response.GeoObjectCollection.featureMember.find(
                    item => !!item.GeoObject.Point.pos
                )
            if (findYandexCoords) {
                setSelectedCoords([lat, lon])
                setSelectedAddress(prev => {
                    return {
                        ...prev,
                        value: shortYandexGeocoder(
                            findYandexCoords.GeoObject.metaDataProperty.GeocoderMetaData.text.replace(
                                'Россия, ',
                                ''
                            )
                        ),
                        unrestricted_value: shortYandexGeocoder(
                            `${findYandexCoords.GeoObject.description}{d}${findYandexCoords.GeoObject.name}`
                        ),
                        data: {
                            ...(prev?.data || {}),
                            geo_lat: lat.toString(),
                            geo_lon: lon.toString()
                        }
                    } as DaDataSuggestion<DaDataAddress>
                })
            }
        } catch (error) {
            messageApi.open({
                type: 'warning',
                content: 'Не удалось найти адрес!'
            })
            Sentry.captureException(error)
        }
    }

    return (
        <>
            <TitleStep
                rightElement={
                    <Button
                        onClick={() => setIsModal(true)}
                        icon={<PlusOutlined />}
                        loading={isSaving}
                    >
                        {isFilial ? 'Выбрать основной адрес' : 'Добавить адрес'}
                    </Button>
                }
            >
                {isFilial ? 'Адрес компании' : 'Адреса компании'}
            </TitleStep>
            <Row align={'middle'}>
                <Paragraph
                    type={'secondary'}
                    style={{ fontSize: 12, marginRight: 7 }}
                >
                    {
                        'Укажите  основной адрес компании, для которой заполняете данные.'
                    }
                </Paragraph>
                <Tooltip
                    title={
                        'Оплата статуса "Премиум" производится по каждому основному адресу компании, так как на каждый основной адрес создается отдельный лендинг.  Ниже вы можете указать все ваши филиалы - они будут выведены справочно на лендинге.'
                    }
                >
                    <QuestionCircleOutlined
                        style={{ cursor: 'pointer', marginBottom: 11 }}
                    />
                </Tooltip>
            </Row>
            <Table
                columns={columns}
                dataSource={addresses}
                rowKey={record =>
                    record.additional_text_address + Math.random()
                }
                pagination={false}
                locale={{
                    emptyText: (
                        <CustomEmpty description={'Адреса не добавлены'} />
                    )
                }}
                style={{ marginBottom: 30 }}
            />
            <TitleStep
                rightElement={
                    <Button
                        onClick={() => setIsModalAdditional(true)}
                        icon={<PlusOutlined />}
                        loading={isSaving}
                    >
                        {'Добавить дополнительный адрес'}
                    </Button>
                }
            >
                {'Дополнительные адреса (выводятся справочно)'}
            </TitleStep>
            <Paragraph type={'secondary'} style={{ fontSize: 12 }}>
                {
                    'Укажите все ваши филиалы. Все адреса будут выведены в качестве справочной информации на лендинге.'
                }
            </Paragraph>
            <Table
                columns={columns}
                dataSource={additionalAddresses}
                rowKey={record =>
                    record.additional_text_address + Math.random()
                }
                pagination={false}
                locale={{
                    emptyText: (
                        <CustomEmpty
                            description={'Дополнительные адреса не добавлены'}
                        />
                    )
                }}
            />
            <NextStepButton
                disabled={
                    addresses.length === 0 ||
                    (!!currentCompany &&
                        !!currentCompany.lat &&
                        !!currentCompany.lng &&
                        !hasStepChanges)
                }
                loading={isSaving}
                onClick={handleSaveAddress}
                showReturnChangesButton={
                    !!currentCompany &&
                    !!currentCompany.lat &&
                    !!currentCompany.lng &&
                    hasStepChanges
                }
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            <Modal
                open={isModal}
                title={isFilial ? 'Изменить адрес' : 'Добавить адрес'}
                okButtonProps={{
                    disabled: !selectedAddress
                }}
                okText={'Выбрать'}
                onOk={handleAddAddress}
                onCancel={() => setIsModal(false)}
                destroyOnClose
            >
                <div style={{ margin: '20px 0' }}>
                    <AddressSuggestions
                        token={process.env.DADATA_KEY ?? ''}
                        value={selectedAddress}
                        onChange={value => {
                            void (async () => {
                                setSelectedAddress(value)
                                if (
                                    value?.data?.geo_lat &&
                                    value?.data?.geo_lon
                                ) {
                                    setSelectedCoords([
                                        parseFloat(value.data.geo_lat),
                                        parseFloat(value.data.geo_lon)
                                    ])
                                } else {
                                    try {
                                        const {
                                            data: yandexData
                                        }: { data: IYandexGeoCoder } =
                                            await axios.get(
                                                'https://geocode-maps.yandex.ru/1.x/',
                                                {
                                                    params: {
                                                        format: 'json',
                                                        apikey: process.env
                                                            .YANDEX_API_KEY,
                                                        results: 1,
                                                        geocode: value?.value
                                                    }
                                                }
                                            )
                                        const findYandexCoords =
                                            yandexData.response.GeoObjectCollection.featureMember.find(
                                                item =>
                                                    !!item.GeoObject.Point.pos
                                            )
                                        if (findYandexCoords) {
                                            const coords =
                                                findYandexCoords.GeoObject.Point.pos.split(
                                                    ' '
                                                )
                                            setSelectedCoords([
                                                parseFloat(coords[1]),
                                                parseFloat(coords[0])
                                            ])
                                            setSelectedAddress(prev => {
                                                return {
                                                    ...prev,
                                                    data: {
                                                        ...(prev?.data || {}),
                                                        geo_lat: coords[1],
                                                        geo_lon: coords[0]
                                                    }
                                                } as DaDataSuggestion<DaDataAddress>
                                            })
                                        }
                                    } catch (error) {
                                        messageApi.open({
                                            type: 'warning',
                                            content: 'Не удалось найти адрес!'
                                        })
                                        Sentry.captureException(error)
                                    }
                                }
                            })()
                        }}
                        filterLanguage={'ru'}
                        inputProps={{
                            placeholder: 'Начните вводить название города'
                        }}
                    />
                </div>
                <YMaps
                    query={{
                        apikey: process.env.YANDEX_API_KEY,
                        mode: 'release',
                        lang: 'ru_RU'
                    }}
                >
                    <Map
                        width={'100%'}
                        height={350}
                        options={{
                            copyrightUaVisible: false,
                            copyrightLogoVisible: false,
                            copyrightProvidersVisible: false
                        }}
                        defaultState={{
                            center: [55.755864, 37.617698],
                            zoom: 8
                        }}
                        state={
                            selectedCoords
                                ? {
                                      center: selectedCoords,
                                      zoom: 8
                                  }
                                : undefined
                        }
                        onClick={(event: {
                            get: (type: string) => Array<number>
                        }) => {
                            const [latitude, longitude] = event.get('coords')
                            fetchMapAddress(latitude, longitude)
                        }}
                    >
                        <ZoomControl />
                        {selectedAddress ? (
                            <Placemark
                                geometry={[
                                    selectedAddress.data.geo_lat,
                                    selectedAddress.data.geo_lon
                                ]}
                            />
                        ) : null}
                    </Map>
                </YMaps>
            </Modal>
            <Modal
                open={isModalAdditional}
                title={'Добавить адрес'}
                okButtonProps={{
                    disabled: !selectedAddress
                }}
                okText={'Выбрать'}
                onOk={handleAddAdditionalAddress}
                onCancel={() => setIsModalAdditional(false)}
                destroyOnClose
            >
                <div style={{ margin: '20px 0' }}>
                    <AddressSuggestions
                        token={process.env.DADATA_KEY ?? ''}
                        value={selectedAddress}
                        onChange={value => {
                            void (async () => {
                                setSelectedAddress(value)
                                if (
                                    value?.data?.geo_lat &&
                                    value?.data?.geo_lon
                                ) {
                                    setSelectedCoords([
                                        parseFloat(value.data.geo_lat),
                                        parseFloat(value.data.geo_lon)
                                    ])
                                } else {
                                    try {
                                        const {
                                            data: yandexData
                                        }: { data: IYandexGeoCoder } =
                                            await axios.get(
                                                'https://geocode-maps.yandex.ru/1.x/',
                                                {
                                                    params: {
                                                        format: 'json',
                                                        apikey: process.env
                                                            .YANDEX_API_KEY,
                                                        results: 1,
                                                        geocode: value?.value
                                                    }
                                                }
                                            )
                                        const findYandexCoords =
                                            yandexData.response.GeoObjectCollection.featureMember.find(
                                                item =>
                                                    !!item.GeoObject.Point.pos
                                            )
                                        if (findYandexCoords) {
                                            const coords =
                                                findYandexCoords.GeoObject.Point.pos.split(
                                                    ' '
                                                )
                                            setSelectedCoords([
                                                parseFloat(coords[1]),
                                                parseFloat(coords[0])
                                            ])
                                            setSelectedAddress(prev => {
                                                return {
                                                    ...prev,
                                                    data: {
                                                        ...(prev?.data || {}),
                                                        geo_lat: coords[1],
                                                        geo_lon: coords[0]
                                                    }
                                                } as DaDataSuggestion<DaDataAddress>
                                            })
                                        }
                                    } catch (error) {
                                        messageApi.open({
                                            type: 'warning',
                                            content: 'Не удалось найти адрес!'
                                        })
                                        Sentry.captureException(error)
                                    }
                                }
                            })()
                        }}
                        filterLanguage={'ru'}
                        inputProps={{
                            placeholder: 'Начните вводить название города'
                        }}
                    />
                </div>
                <YMaps
                    query={{
                        apikey: process.env.YANDEX_API_KEY,
                        mode: 'release',
                        lang: 'ru_RU'
                    }}
                >
                    <Map
                        width={'100%'}
                        height={350}
                        options={{
                            copyrightUaVisible: false,
                            copyrightLogoVisible: false,
                            copyrightProvidersVisible: false
                        }}
                        defaultState={{
                            center: [55.755864, 37.617698],
                            zoom: 8
                        }}
                        state={
                            selectedCoords
                                ? {
                                      center: selectedCoords,
                                      zoom: 8
                                  }
                                : undefined
                        }
                        onClick={(event: {
                            get: (type: string) => Array<number>
                        }) => {
                            const [latitude, longitude] = event.get('coords')
                            fetchMapAddress(latitude, longitude)
                        }}
                    >
                        <ZoomControl />
                        {selectedAddress ? (
                            <Placemark
                                geometry={[
                                    selectedAddress.data.geo_lat,
                                    selectedAddress.data.geo_lon
                                ]}
                            />
                        ) : null}
                    </Map>
                </YMaps>
            </Modal>
            {contextHolder}
        </>
    )
}
