import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, getContext, put, takeEvery } from 'redux-saga/effects'
import type {
    CreatePayload,
    EditPayload,
    FetchPayload,
    PayPayload
} from './types.ts'
import * as api from './api.ts'
import { actions } from './slice.ts'
import { formatApiError, sagaNotificationError } from '../../utils/helpers.ts'
import type { createBrowserRouter } from 'react-router-dom'

export function* handleFetch(action: { payload: FetchPayload }): SagaIterator {
    try {
        const { data } = yield call(api.fetch, action.payload.lead_id)
        yield put(actions.fetchSuccess({ data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleCreate(action: {
    payload: CreatePayload
}): SagaIterator {
    try {
        yield call(api.create, action.payload.form)
        yield put(actions.createSuccess())
        const router: ReturnType<typeof createBrowserRouter> =
            yield getContext('router')
        void router.navigate('/sales/my-requests')
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createFailure({
                error: message
            })
        )
    }
}

export function* watchCreate(): SagaIterator {
    yield takeEvery(actions.create, handleCreate)
}

export function* handleEdit(action: { payload: EditPayload }): SagaIterator {
    try {
        const { lead_id, form } = action.payload
        yield call(api.edit, lead_id, form)
        yield put(actions.editSuccess())
        const notification = yield getContext('notification')
        notification.success({
            message: 'Изменение заявки',
            description: 'Заявка была успешно сохранена',
            placemen: 'topRight'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editFailure({
                error: message
            })
        )
    }
}

export function* watchEdit(): SagaIterator {
    yield takeEvery(actions.edit, handleEdit)
}

export function* handlePay(action: { payload: PayPayload }): SagaIterator {
    const { lead_id } = action.payload
    try {
        yield call(api.pay, lead_id)
        yield put(actions.paySuccess())
        yield put(actions.fetch({ lead_id }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.payFailure({
                error: message
            })
        )
    }
}

export function* watchPay(): SagaIterator {
    yield takeEvery(actions.pay, handlePay)
}

export default function* watchSalesMyRequestsWizard(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchCreate),
        fork(watchEdit),
        fork(watchPay)
    ])
}
