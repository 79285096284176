import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import type {
    FetchPayload,
    PayPayload,
    RemovePayload,
    DownloadExcelPayload
} from './types.ts'
import * as api from './api.ts'
import { actions } from './slice.ts'
import { formatApiError, sagaNotificationError } from '../../utils/helpers.ts'
import { salesMyRequestsSelector } from './selectors.ts'

export function* handleFetch(action: { payload: FetchPayload }): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload
        const { data } = yield call(api.fetch, page, pageSize, filter)
        yield put(actions.fetchSuccess({ data: data.data, meta: data.meta }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleRemove(action: {
    payload: RemovePayload
}): SagaIterator {
    const { lead_id } = action.payload
    try {
        yield call(api.remove, lead_id)
        yield put(actions.removeSuccess({ lead_id }))
        const { meta, filter } = yield select(salesMyRequestsSelector)
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage,
                    filter
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeFailure({
                lead_id,
                error: message
            })
        )
    }
}

export function* watchRemove(): SagaIterator {
    yield takeEvery(actions.remove, handleRemove)
}

export function* handlePay(action: { payload: PayPayload }): SagaIterator {
    const { lead_ids } = action.payload
    try {
        yield call(api.pay, lead_ids)
        yield put(actions.paySuccess({ lead_ids }))
        const { meta, filter } = yield select(salesMyRequestsSelector)
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage,
                    filter
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.payFailure({
                lead_ids,
                error: message
            })
        )
    }
}

export function* watchPay(): SagaIterator {
    yield takeEvery(actions.pay, handlePay)
}

export function* handleDownloadExcel(action: {
    payload: DownloadExcelPayload
}): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload
        yield call(api.downloadExcel, page, pageSize, filter)
        yield put(actions.downloadExcelSuccess())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.downloadExcelFailure({
                error: message
            })
        )
    }
}

export function* watchDownloadExcel(): SagaIterator {
    yield takeEvery(actions.downloadExcel, handleDownloadExcel)
}

export default function* watchSalesMyRequests(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchRemove),
        fork(watchPay),
        fork(watchDownloadExcel)
    ])
}
