import type { ReactElement } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { companyWizardSelector } from '../../selectors'
import {
    Button,
    Input,
    Radio,
    Space,
    Typography,
    DatePicker,
    Checkbox
} from 'antd'
import RadioDescription from '../../../../components/RadioDescription'
import NextStepButton from '../../../../components/NextStepButton'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import isUrl from 'is-url'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

dayjs.extend(utc)

const MOSCOW_TIMEZONE = 'Europe/Moscow'

const { Paragraph, Text } = Typography
const { TextArea } = Input

const variants = [
    {
        value: 'website',
        title: 'Перейти на сайт',
        description: 'Переход на сайт или другой ресурс компании'
    },
    {
        value: 'booking',
        title: 'Перейти к бронированию',
        description: 'Переход на внешнюю ссылку бронирования'
    },
    {
        value: 'purchase',
        title: 'Перейти к покупке',
        description: 'Переход на внешнюю ссылку покупки'
    }
]

const values = {
    offers: {
        selected: true,
        value: undefined,
        params: {}
    },
    website: {
        selected: false,
        value: '',
        params: {}
    },
    booking: {
        selected: false,
        value: '',
        params: {}
    },
    purchase: {
        selected: false,
        value: '',
        params: {}
    },
    'business-promocode': {
        selected: false,
        value: '',
        params: {}
    }
}

function SiteCheckedInput({
    value,
    handleChangeValue,
    setIsConfirmed,
    isAllowEmpty
}: {
    value?: string
    handleChangeValue: (value: string) => void
    setIsConfirmed: (isConfirmed: boolean) => void
    isAllowEmpty?: boolean
}): ReactElement {
    const [prevValue, setPrevValue] = useState(value)
    const [typeCheck, setTypeCheck] = useState<false | 'success' | 'failure'>(
        isUrl(value ?? '') ? 'success' : false
    )
    const [isTouched, setIsTouched] = useState(false)

    useEffect(() => {
        setIsConfirmed(typeCheck === 'success')
    }, [typeCheck])

    const handleConfirmUrl = (value?: string) => {
        if (isAllowEmpty && !value) {
            setTypeCheck('success')
            return
        }
        const valueWithProtocol = value?.startsWith('http')
            ? value
            : `http://${value}`
        const isChecked = isUrl(valueWithProtocol)
        setTypeCheck(isChecked ? 'success' : 'failure')
        handleChangeValue(valueWithProtocol)
        queueMicrotask(() => setPrevValue(valueWithProtocol))
    }

    return (
        <>
            <label htmlFor={'input-checked-site'}>
                {'Ссылка на сайт, другой ресурс, мессенджер'}
            </label>
            <Input
                id={'input-checked-site'}
                suffix={
                    <Button
                        style={{
                            display:
                                prevValue === value || (isAllowEmpty && !value)
                                    ? 'none'
                                    : 'block'
                        }}
                        onClick={() => handleConfirmUrl(value)}
                    >
                        {'Ок'}
                    </Button>
                }
                value={value}
                style={{ marginTop: 5, height: 42 }}
                placeholder={'https://'}
                onChange={({ target }) => {
                    handleChangeValue(target.value)
                    setTypeCheck(false)
                    setIsTouched(true)
                }}
            />
            {typeCheck && isTouched ? (
                <div
                    style={{
                        fontSize: 14,
                        color: typeCheck === 'success' ? 'green' : 'red'
                    }}
                >
                    {typeCheck === 'success'
                        ? 'Адрес введен корректно'
                        : 'Адрес введен не верно!'}
                </div>
            ) : null}
        </>
    )
}

export default function TargetStep(): ReactElement {
    const [targetActions, setTargetActions] = useState(values)
    const [isConfirmed, setIsConfirmed] = useState(false)

    const [prevPromocodeParams, setPrevPromocodeParams] = useState<Record<
        string,
        unknown
    > | null>(null)

    const dispatch = useDispatch()

    const { currentCompany, hasStepChanges, isSaving } = useAppSelector(
        companyWizardSelector
    )

    const selectedTargetAction: keyof typeof targetActions | undefined =
        useMemo(() => {
            for (const item in targetActions) {
                if (
                    targetActions[item as keyof typeof targetActions].selected
                ) {
                    return item as keyof typeof targetActions
                }
            }
        }, [targetActions])

    const selectedParams: Record<string, undefined | string> =
        selectedTargetAction ? targetActions[selectedTargetAction].params : {}

    const businessPromocode =
        currentCompany && currentCompany.business_promocodes?.length > 0
            ? currentCompany.business_promocodes[0]
            : null

    const promocodeParams = businessPromocode
        ? {
              promocode: businessPromocode.promocode,
              url: businessPromocode.url,
              description: businessPromocode.description,
              lead_generation: businessPromocode.lead_generation ? '1' : '0',
              expiration_at: businessPromocode.expiration_at
                  ? dayjs(businessPromocode.expiration_at)
                  : null
          }
        : null

    const handleChangeValue = (value: string) => {
        const newValues = { ...targetActions }
        newValues[selectedTargetAction as keyof typeof newValues].value = value
        setTargetActions({ ...newValues })
    }

    const handleChangeParams = (
        type: keyof typeof values,
        key: string,
        value: string | Dayjs
    ) => {
        const newValues = { ...targetActions }
        newValues[type].params = {
            ...newValues[type].params,
            [key]: value
        }
        setTargetActions({ ...newValues })
    }

    useEffect(() => {
        if (currentCompany) {
            let selectedTargetKey, selectedTargetValue

            for (const key in targetActions) {
                if (targetActions[key as keyof typeof targetActions].selected) {
                    selectedTargetKey = key
                    selectedTargetValue =
                        targetActions[key as keyof typeof targetActions].value
                    break
                }
            }

            const hasChange =
                currentCompany.target_action != selectedTargetKey ||
                (selectedTargetKey === 'business-promocode'
                    ? JSON.stringify(prevPromocodeParams) !==
                      JSON.stringify(selectedParams)
                    : false) ||
                (currentCompany.target_action !== 'offers' &&
                currentCompany.target_action !== 'business-promocode'
                    ? currentCompany.target_action_url != selectedTargetValue
                    : false)

            if (prevPromocodeParams === null && promocodeParams) {
                setPrevPromocodeParams(promocodeParams)
                setTargetActions(prev => ({
                    ...prev,
                    'business-promocode': {
                        ...prev['business-promocode'],
                        params: { ...promocodeParams }
                    }
                }))
            }

            dispatch(actions.setHasStepChanges(hasChange))
        }
    }, [currentCompany, targetActions])

    const handleUndoChanges = () => {
        if (currentCompany) {
            const newValues: typeof targetActions = { ...targetActions }
            for (const item in newValues) {
                const isCurrent =
                    currentCompany.target_action != null &&
                    currentCompany.target_action === item
                newValues[item as keyof typeof newValues].selected = isCurrent
                if (isCurrent) {
                    if (currentCompany.target_action_url) {
                        newValues[item as keyof typeof newValues].value =
                            currentCompany.target_action_url
                    }
                }
                if (item === 'business-promocode') {
                    newValues[item as keyof typeof newValues].params = {
                        ...promocodeParams
                    }
                }
            }
            setTargetActions({ ...newValues })
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleChangeTargetActions = (value: keyof typeof targetActions) => {
        const newValues = targetActions
        for (const item in targetActions) {
            newValues[item as keyof typeof targetActions].selected =
                value === item
        }
        setTargetActions({ ...newValues })
    }

    const handleSaveTarget = (isTrusted: boolean) => {
        if (currentCompany) {
            setPrevPromocodeParams(null)
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        target_action: selectedTargetAction,
                        target_action_url:
                            selectedTargetAction !== 'business-promocode'
                                ? targetActions[
                                      selectedTargetAction as keyof typeof targetActions
                                  ].value
                                : undefined,
                        ...(selectedTargetAction === 'business-promocode'
                            ? {
                                  business_promocodes: [
                                      {
                                          promocode: selectedParams.promocode,
                                          url: selectedParams.url,
                                          description:
                                              selectedParams.description,
                                          lead_generation:
                                              selectedParams.lead_generation ===
                                              '1',
                                          expiration_at: dayjs(
                                              selectedParams.expiration_at
                                          ).isValid()
                                              ? dayjs(
                                                    selectedParams.expiration_at?.toString()
                                                )
                                                    .tz(MOSCOW_TIMEZONE)
                                                    .utc()
                                              : null
                                      }
                                  ]
                              }
                            : undefined)
                    },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    return (
        <>
            <Paragraph style={{ fontSize: 12 }} type={'secondary'}>
                {'Укажите какую кнопку выводить в карточке компании.'}
            </Paragraph>
            <Space direction={'vertical'}>
                <Radio.Group
                    onChange={({ target }) =>
                        handleChangeTargetActions(target.value)
                    }
                    value={selectedTargetAction}
                >
                    <Space direction={'vertical'}>
                        {variants.map(radio => (
                            <Radio key={radio.value} value={radio.value}>
                                {radio.title}
                                <RadioDescription>
                                    {radio.description}
                                </RadioDescription>
                            </Radio>
                        ))}
                    </Space>
                </Radio.Group>
                {selectedTargetAction &&
                !['offers', 'business-promocode'].includes(
                    selectedTargetAction
                ) &&
                targetActions[
                    selectedTargetAction as keyof typeof targetActions
                ].value != null ? (
                    <SiteCheckedInput
                        value={targetActions[selectedTargetAction].value}
                        handleChangeValue={handleChangeValue}
                        setIsConfirmed={setIsConfirmed}
                    />
                ) : null}
                <Radio
                    checked={selectedTargetAction === 'business-promocode'}
                    onClick={() =>
                        handleChangeTargetActions('business-promocode')
                    }
                >
                    {'Получить промокод на скидку'}
                    <RadioDescription>
                        {
                            'Промокод на скидку, при получении фиксируется заявка с контактами в разделе «Мои заявки»'
                        }
                    </RadioDescription>
                </Radio>
                {selectedTargetAction === 'business-promocode' ? (
                    <>
                        <label
                            htmlFor={'input-promo'}
                            className={classNames([
                                styles.label,
                                styles.label__required
                            ])}
                        >
                            {'Промокод'}
                        </label>
                        <Input
                            id={'input-promo'}
                            placeholder={'Промокод'}
                            addonAfter={
                                selectedParams.promocode &&
                                selectedParams.promocode.length > 3 ? (
                                    <CheckCircleOutlined
                                        style={{ color: 'green' }}
                                    />
                                ) : (
                                    <CloseCircleOutlined
                                        style={{ color: 'red' }}
                                    />
                                )
                            }
                            value={selectedParams.promocode}
                            maxLength={15}
                            className={styles.input}
                            onChange={event =>
                                handleChangeParams(
                                    'business-promocode',
                                    'promocode',
                                    event.target.value
                                )
                            }
                        />
                        <SiteCheckedInput
                            value={selectedParams.url}
                            isAllowEmpty={true}
                            setIsConfirmed={setIsConfirmed}
                            handleChangeValue={value =>
                                handleChangeParams(
                                    'business-promocode',
                                    'url',
                                    value
                                )
                            }
                        />
                        <Text type={'secondary'} className={styles.description}>
                            {
                                'Ссылка, по которой можно узнать подробности или активировать промокод'
                            }
                        </Text>
                        <label
                            htmlFor={'input-description'}
                            className={styles.label}
                        >
                            {'Описание'}
                        </label>
                        <TextArea
                            id={'input-description'}
                            placeholder={'Описание'}
                            value={selectedParams.description}
                            onChange={event =>
                                handleChangeParams(
                                    'business-promocode',
                                    'description',
                                    event.target.value
                                )
                            }
                        />
                        <Text type={'secondary'} className={styles.description}>
                            {'Укажите условия применения промокода'}
                        </Text>
                        <label htmlFor={'input-date'} className={styles.label}>
                            {'Дата окончания действия промокода'}
                        </label>
                        <DatePicker
                            id={'input-date'}
                            style={{ width: '100%' }}
                            placeholder={'Дата окончания действия промокода'}
                            format={'DD.MM.YYYY HH:mm'}
                            showTime
                            value={
                                selectedParams.expiration_at
                                    ? dayjs(selectedParams.expiration_at)
                                    : undefined
                            }
                            onChange={date =>
                                handleChangeParams(
                                    'business-promocode',
                                    'expiration_at',
                                    date
                                )
                            }
                        />
                        <Checkbox
                            checked={selectedParams.lead_generation === '1'}
                            onChange={event =>
                                handleChangeParams(
                                    'business-promocode',
                                    'lead_generation',
                                    event.target.checked ? '1' : '0'
                                )
                            }
                        >
                            {'Собирать заявки по полученным промокодам'}
                        </Checkbox>
                        <Paragraph style={{ fontSize: 12 }} type={'secondary'}>
                            {'Заявки будут собираться в разделе Продажи '}
                            <Link to={'/sales/my-requests'} target={'_blank'}>
                                {'«Мои заявки»'}
                            </Link>
                            <br />
                            {'Заявки оплачиваются отдельно с баланса'}
                        </Paragraph>
                    </>
                ) : null}
                {/*<Title level={5} style={{ margin: '10px 0 0' }}>*/}
                {/*    {'По умолчанию'}*/}
                {/*</Title>*/}
                {/*<Radio*/}
                {/*    checked={selectedTargetAction === 'offers'}*/}
                {/*    onClick={() => handleChangeTargetActions('offers')}*/}
                {/*>*/}
                {/*    {'Перейти к предложениям'}*/}
                {/*    <RadioDescription>*/}
                {/*        {'Переход в список предложений компании'}*/}
                {/*    </RadioDescription>*/}
                {/*</Radio>*/}
            </Space>
            <NextStepButton
                disabled={
                    !selectedTargetAction ||
                    !hasStepChanges ||
                    (selectedTargetAction === 'business-promocode'
                        ? (selectedParams.url ? !isConfirmed : false) ||
                          !selectedParams.promocode ||
                          (!!selectedParams.promocode &&
                              selectedParams.promocode.length < 4)
                        : !isConfirmed)
                }
                loading={isSaving}
                onClick={handleSaveTarget}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
        </>
    )
}
