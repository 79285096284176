import {
    Button,
    Card,
    Checkbox,
    Col,
    Collapse,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Row,
    Spin,
    Switch,
    Typography,
    Upload
} from 'antd'
import NextStepButton from '../../../../components/NextStepButton'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companySelector } from '../../selectors'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import FormItemWithLabel from '../../../../components/FormItemWithLabel'
import { applicationSelector } from '../../../Application/selectors'
import CompanyPropertySelector from '../../../CompanyPropertySelector'
import BusinessUserSelector from '../../../BusinessUserSelector'
import { fromRuble, getRole, youTubeRegExp } from '../../../../utils/helpers'
import {
    CopyOutlined,
    DeleteOutlined,
    EyeOutlined,
    FileImageOutlined,
    LinkOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    SyncOutlined,
    VideoCameraAddOutlined
} from '@ant-design/icons'
import copy from 'copy-text-to-clipboard'
import FranchiseeSelector from '../../../FranchiseeSelector'
import styles from './styles.module.scss'
import ImgCrop from 'antd-img-crop'
import getYouTubeID from 'get-youtube-id'
import ReactPlayer from 'react-player'
import type { IMediaItem } from '../../types.ts'
import { authorizationSelector } from '../../../Authorization/selectors.ts'

const { TextArea } = Input
const { Text, Paragraph } = Typography

function UserFiled({
    title,
    value,
    emptyText
}: {
    title: string
    value?: string | null | number
    emptyText?: string
}) {
    return (
        <>
            <Paragraph
                type={'warning'}
                style={{ margin: 0, fontSize: 12, color: 'gray' }}
            >
                {title}
            </Paragraph>
            <Paragraph
                style={{ whiteSpace: 'pre-line' }}
                type={value != null ? undefined : 'danger'}
            >
                {value ?? emptyText ?? 'Не установлено'}
            </Paragraph>
        </>
    )
}

const invitation_text =
    'Добрый день! Теперь за всеми акциями, закрытыми скидками, розыгрышами и предложениями нашей компании - {{name}}, вы можете следить в приложении MAMADO. По ссылке ниже скачайте приложение MAMADO - маркетплейс услуг и предложений нашего города.'

export default function TabGeneral() {
    const [selectedBusinessUserId, setSelectedBusinessUserId] = useState('')
    const [selectedFranchisee, setSelectedFranchisee] = useState('')
    const [isAddMediaModal, setIsAddMediaModal] = useState(false)
    const [isAddVideoModal, setIsAddVideoModal] = useState(false)
    const [youTubeLink, setYouTubeLink] = useState('')

    const [selectedVideo, setSelectedVideo] = useState('')

    const [messageApi, contextHolder] = message.useMessage()

    const {
        general,
        currentCompany,
        isSaving,
        isFetchingCompany,
        sendChangeCompanyProperties,
        isProcessChangeOwner,
        isModalChangeOwner,
        isProcessChangeFranchisee,
        defaultLeadCoast,
        isModalChangeFranchisee,
        invitation,
        isProcessInvitation,
        isProcessRemoveOwner,
        isUploadingMediaImage,
        generalMedia,
        tempLandingData
    } = useAppSelector(companySelector)
    const { companyProperties, companyPropertiesIsFetching, plans } =
        useAppSelector(applicationSelector)
    const { profile } = useAppSelector(authorizationSelector)

    const dispatch = useDispatch()

    const profileRole = profile ? getRole(profile.roles) : undefined
    const isAdmin = profileRole === 'admin'
    const isAdminOrFranchisee = profile
        ? getRole(profile.roles) === 'admin' ||
          getRole(profile.roles) === 'franchisee'
        : false

    const hasChangesMedia = useMemo(() => {
        if (tempLandingData?.choice_mamado_media) {
            const media = tempLandingData.choice_mamado_media as IMediaItem[]

            return !(
                media.length === generalMedia.length &&
                media.every(item =>
                    generalMedia.some(media => media.url === item.url)
                )
            )
        }

        return generalMedia.length > 0
    }, [tempLandingData, generalMedia])

    const isDisabledSaveButton = useMemo(() => {
        let noChanges = true

        if (currentCompany) {
            for (const item in general) {
                if (item === 'media') {
                    continue
                }
                if (
                    item === 'lead_coast' &&
                    general[item as keyof typeof general] !== defaultLeadCoast
                ) {
                    noChanges = false
                    break
                }
                if (
                    general[item as keyof typeof general] !==
                    currentCompany[item as keyof typeof currentCompany]
                ) {
                    noChanges = false
                    break
                }
            }
        }

        return noChanges
    }, [general, currentCompany])

    if (!currentCompany) {
        return <></>
    }

    const changeGeneralField = (
        key: keyof typeof general,
        value: boolean | string | number | null
    ) => {
        dispatch(actions.setGeneralParam({ key, value }))
    }

    const handleSaveGeneral = () => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        ...general,
                        lead_coast:
                            typeof general.lead_coast === 'number'
                                ? fromRuble(general.lead_coast)
                                : undefined,
                        ...(hasChangesMedia
                            ? {
                                  landing: {
                                      ...tempLandingData,
                                      choiceMamadoMedia: generalMedia
                                  }
                              }
                            : {})
                    }
                })
            )
        }
    }

    const handleChangeCompanyProperty = (
        company_property_id: string,
        selected_value: string
    ) => {
        if (!selected_value && currentCompany) {
            const findOption = currentCompany.companyPropertyOptionValues?.find(
                item => item.company_property_id === company_property_id
            )

            if (findOption) {
                dispatch(
                    actions.clearCompanyProperty({
                        company_id: currentCompany.company_id,
                        company_property_id,
                        company_property_option_id:
                            findOption.company_property_option_value_id
                    })
                )
            }
            return
        }

        if (currentCompany) {
            const findOption = currentCompany.companyPropertyOptionValues?.find(
                item => item.company_property_id === company_property_id
            )

            if (findOption) {
                dispatch(
                    actions.changeCompanyProperty({
                        company_id: currentCompany.company_id,
                        company_property_id: findOption.company_property_id,
                        company_property_option_id:
                            findOption.company_property_option_value_id,
                        value: selected_value
                    })
                )
            } else {
                dispatch(
                    actions.createCompanyProperty({
                        company_id: currentCompany.company_id,
                        company_property_id,
                        value: selected_value
                    })
                )
            }
        }
    }

    const handleChangeUser = () => {
        if (currentCompany && selectedBusinessUserId) {
            dispatch(
                actions.changeCompanyOwner({
                    company_id: currentCompany.company_id,
                    business_account_id: selectedBusinessUserId.split('{d}')[1]
                })
            )
            setSelectedBusinessUserId('')
        }
    }

    const handleChangeFranchisee = () => {
        if (currentCompany) {
            dispatch(
                actions.changeCompanyFranchisee({
                    company_id: currentCompany.company_id,
                    franchisee_id: selectedFranchisee
                        ? selectedFranchisee.split('{d}')[1]
                        : null
                })
            )
            setSelectedFranchisee('')
        }
    }

    const handleCreateInvitation = () => {
        dispatch(
            actions.createInvitation({
                company_id: currentCompany.company_id,
                invitation_text
            })
        )
    }

    const handleCopyLink = (link: string) => {
        copy(link)
        messageApi.open({
            type: 'success',
            content: 'Ссылка была скопирована'
        })
    }

    const dynamicLink = invitation?.dynamic_code
        ? `https://${
              process.env.APP_ENV === 'staging' ? 'r1' : 'r'
          }.mamado.su/${invitation.dynamic_code}`
        : ''

    const landingLink =
        !currentCompany.displayed && currentCompany.slug_title
            ? `${process.env.SITE_URL}/${currentCompany.dadata_area ? (currentCompany.dadata_area.short_slug ?? currentCompany.dadata_area.slug) : 'rossiya'}/${currentCompany.slug_title}`
            : ''

    const handleChangeParam = (key: string, value: unknown) =>
        dispatch(
            actions.saveCompany({
                company_id: currentCompany.company_id,
                form: {
                    [key]: value
                }
            })
        )

    return (
        <>
            <Row gutter={10}>
                <Col span={6}>
                    <Card
                        title={''}
                        variant={'borderless'}
                        style={{
                            width: '100%',
                            height: '100%',
                            background: '#FFF'
                        }}
                        styles={{
                            header: { padding: '12px 15px', minHeight: 0 },
                            body: { padding: 15 }
                        }}
                    >
                        <div
                            style={{ fontWeight: '600', fontSize: 16 }}
                            className={'ant-card-head-title'}
                        >
                            {'Здесь рады детям'}
                        </div>
                        <FormItemWithLabel
                            name={'kf_drinking_water'}
                            label={'Вода'}
                            style={{ margin: 0 }}
                        >
                            <Switch
                                defaultChecked={general.kf_drinking_water}
                                checked={general.kf_drinking_water}
                                onChange={value =>
                                    changeGeneralField(
                                        'kf_drinking_water',
                                        value
                                    )
                                }
                            />
                        </FormItemWithLabel>
                        <FormItemWithLabel
                            name={'kf_recreation_area'}
                            label={'Отдых'}
                            style={{ margin: 0 }}
                        >
                            <Switch
                                defaultChecked={general.kf_recreation_area}
                                checked={general.kf_recreation_area}
                                onChange={value =>
                                    changeGeneralField(
                                        'kf_recreation_area',
                                        value
                                    )
                                }
                            />
                        </FormItemWithLabel>
                        <FormItemWithLabel
                            name={'kf_wc'}
                            label={'Туалет'}
                            style={{ margin: 0 }}
                        >
                            <Switch
                                defaultChecked={general.kf_wc}
                                checked={general.kf_wc}
                                onChange={value =>
                                    changeGeneralField('kf_wc', value)
                                }
                            />
                        </FormItemWithLabel>
                        <div style={{ marginTop: 10 }}>
                            <Checkbox
                                defaultChecked={general.need_address_in_title}
                                checked={general.need_address_in_title}
                                onChange={event =>
                                    changeGeneralField(
                                        'need_address_in_title',
                                        event.target.checked
                                    )
                                }
                            >
                                {
                                    'Добавлять адрес компании в заголовок страницы'
                                }
                            </Checkbox>
                        </div>
                        <div
                            style={{
                                marginTop: 15,
                                fontWeight: '600',
                                fontSize: 16
                            }}
                            className={'ant-card-head-title'}
                        >
                            {'Ссылки на компании'}
                        </div>
                        <Form key={dynamicLink} layout={'vertical'}>
                            <FormItemWithLabel
                                label={'Реферальная ссылка для приглашения'}
                                name={'referral'}
                                style={{ marginBottom: 8 }}
                            >
                                <Input
                                    defaultValue={dynamicLink}
                                    value={dynamicLink}
                                    placeholder={
                                        isProcessInvitation
                                            ? 'Получение ссылки...'
                                            : 'У компании еще нет приглашения'
                                    }
                                    addonAfter={
                                        dynamicLink ? (
                                            <CopyOutlined
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    handleCopyLink(dynamicLink)
                                                }
                                            />
                                        ) : undefined
                                    }
                                />
                            </FormItemWithLabel>
                            {currentCompany.activePlanSubscription ? (
                                <Button
                                    size={'small'}
                                    style={{ width: '100%', marginBottom: 5 }}
                                    onClick={handleCreateInvitation}
                                    loading={isProcessInvitation}
                                    icon={<LinkOutlined />}
                                >
                                    {invitation?.dynamic_code
                                        ? 'Создать новую ссылку'
                                        : 'Создать приглашение'}
                                </Button>
                            ) : !invitation?.dynamic_code ? (
                                <div
                                    style={{
                                        marginTop: -5,
                                        marginBottom: 5,
                                        fontSize: 9,
                                        color: 'gray'
                                    }}
                                >
                                    {
                                        'Для создания реферальной ссылки нужна активная подписка'
                                    }
                                </div>
                            ) : null}
                            <FormItemWithLabel
                                label={'Ссылка на лендинг'}
                                name={'landing'}
                                style={{ margin: 0 }}
                            >
                                <Input
                                    defaultValue={landingLink}
                                    value={landingLink}
                                    placeholder={
                                        'Компания не доступна для просмотра'
                                    }
                                    addonAfter={
                                        landingLink ? (
                                            <CopyOutlined
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    handleCopyLink(landingLink)
                                                }
                                            />
                                        ) : undefined
                                    }
                                />
                            </FormItemWithLabel>
                        </Form>
                        {profileRole !== 'user' ? (
                            <>
                                <div
                                    style={{
                                        marginTop: 15,
                                        fontWeight: '600',
                                        fontSize: 16
                                    }}
                                    className={'ant-card-head-title'}
                                >
                                    {'Информация о компании'}
                                </div>
                                <br />
                                <Text style={{ fontSize: 12 }}>
                                    {'Дата создания: '}
                                    {currentCompany.created_at
                                        ? dayjs(
                                              currentCompany.created_at
                                          ).format('DD.MM.YYYY HH:mm')
                                        : 'Не установлено'}
                                </Text>
                                <br />
                                <Text style={{ fontSize: 12 }}>
                                    {'Дата последнего обновления: '}
                                    <b>
                                        {dayjs(
                                            currentCompany.updated_at
                                        ).format('DD.MM.YYYY HH:mm')}
                                    </b>
                                </Text>
                            </>
                        ) : null}
                        {isAdminOrFranchisee ? (
                            <div style={{ marginTop: 15 }}>
                                <Checkbox
                                    disabled={isFetchingCompany || isSaving}
                                    checked={
                                        !!currentCompany.display_in_catalog
                                    }
                                    onChange={event =>
                                        handleChangeParam(
                                            'display_in_catalog',
                                            event.target.checked
                                        )
                                    }
                                >
                                    {'Показывать в каталоге'}
                                </Checkbox>
                                <Checkbox
                                    disabled={isFetchingCompany || isSaving}
                                    checked={
                                        !!currentCompany.available_for_deletion
                                    }
                                    onChange={event =>
                                        handleChangeParam(
                                            'available_for_deletion',
                                            event.target.checked
                                        )
                                    }
                                >
                                    {'Доступен к удалению'}
                                </Checkbox>
                            </div>
                        ) : null}
                        {isAdmin ? (
                            <>
                                <div
                                    style={{
                                        marginTop: 15,
                                        fontWeight: '600',
                                        fontSize: 16
                                    }}
                                    className={'ant-card-head-title'}
                                >
                                    {'Стоимость заявки'}
                                </div>
                                <br />
                                <InputNumber
                                    value={general.lead_coast}
                                    placeholder={'Стоимость заявки'}
                                    style={{ width: '100%' }}
                                    addonAfter={'₽'}
                                    onChange={value =>
                                        changeGeneralField('lead_coast', value)
                                    }
                                />
                            </>
                        ) : null}
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        title={'Выбор MAMADO'}
                        variant={'borderless'}
                        style={{
                            width: '100%',
                            height: '100%',
                            background: '#FFF'
                        }}
                        styles={{
                            header: { padding: '12px 15px', minHeight: 0 },
                            body: { padding: 15 }
                        }}
                    >
                        <FormItemWithLabel
                            name={'checked_by_mamado'}
                            label={'Выбор MAMADO'}
                            style={{ margin: 0 }}
                        >
                            <Switch
                                defaultChecked={general.checked_by_mamado}
                                checked={general.checked_by_mamado}
                                onChange={value =>
                                    changeGeneralField(
                                        'checked_by_mamado',
                                        value
                                    )
                                }
                            />
                        </FormItemWithLabel>
                        <Form layout={'vertical'}>
                            <FormItemWithLabel
                                name={'on_mamado_title'}
                                label={'Заголовок'}
                            >
                                <Input
                                    disabled={!general.checked_by_mamado}
                                    defaultValue={general.on_mamado_title ?? ''}
                                    value={general.on_mamado_title ?? ''}
                                    placeholder={'Заголовок'}
                                    onChange={({ target }) =>
                                        changeGeneralField(
                                            'on_mamado_title',
                                            target.value
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                            <FormItemWithLabel
                                name={'on_mamado_content'}
                                label={'Описание'}
                            >
                                <TextArea
                                    disabled={!general.checked_by_mamado}
                                    defaultValue={
                                        general.on_mamado_content ?? ''
                                    }
                                    value={general.on_mamado_content ?? ''}
                                    placeholder={'Описание'}
                                    onChange={({ target }) =>
                                        changeGeneralField(
                                            'on_mamado_content',
                                            target.value
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                            {/*<FormItemWithLabel*/}
                            {/*    name={'on_mamado_video'}*/}
                            {/*    label={'Ссылка'}*/}
                            {/*>*/}
                            {/*    <Input*/}
                            {/*        disabled={!general.checked_by_mamado}*/}
                            {/*        defaultValue={general.on_mamado_video ?? ''}*/}
                            {/*        value={general.on_mamado_video ?? ''}*/}
                            {/*        placeholder={'Ссылка'}*/}
                            {/*        onChange={({ target }) =>*/}
                            {/*            changeGeneralField(*/}
                            {/*                'on_mamado_video',*/}
                            {/*                target.value*/}
                            {/*            )*/}
                            {/*        }*/}
                            {/*    />*/}
                            {/*</FormItemWithLabel>*/}
                            <FormItemWithLabel
                                label={'Изображения и видео'}
                                name={'media'}
                            >
                                <>
                                    <Paragraph style={{ fontSize: 13 }}>
                                        {
                                            'Можно добавить 5 элементов изображений и/или видео'
                                        }
                                    </Paragraph>
                                    <Row gutter={[10, 10]}>
                                        {generalMedia.length < 5 ? (
                                            <Col
                                                className={styles['col-box']}
                                                span={8}
                                            >
                                                <Button
                                                    style={{
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                    type={'primary'}
                                                    icon={
                                                        <PlusOutlined
                                                            style={{
                                                                fontSize: 20
                                                            }}
                                                        />
                                                    }
                                                    onClick={() =>
                                                        setIsAddMediaModal(true)
                                                    }
                                                    loading={
                                                        isUploadingMediaImage
                                                    }
                                                />
                                            </Col>
                                        ) : null}
                                        {generalMedia.map((item, index) => (
                                            <Col
                                                key={`media-${index}`}
                                                className={styles['col-box']}
                                                span={8}
                                            >
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <Button
                                                        disabled={
                                                            isUploadingMediaImage
                                                        }
                                                        icon={
                                                            <DeleteOutlined />
                                                        }
                                                        size={'small'}
                                                        className={
                                                            styles.remove
                                                        }
                                                        type={'primary'}
                                                        onClick={() =>
                                                            dispatch(
                                                                actions.removeVideo(
                                                                    index
                                                                )
                                                            )
                                                        }
                                                    />
                                                    {item.url.includes(
                                                        'youtube.com'
                                                    ) ? (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                borderRadius: 6,
                                                                backgroundImage: `url('https://i.ytimg.com/vi/${getYouTubeID(
                                                                    item.url
                                                                )}/hqdefault.jpg')`,
                                                                backgroundSize:
                                                                    '200%',
                                                                backgroundPosition:
                                                                    'center'
                                                            }}
                                                            className={
                                                                styles.video
                                                            }
                                                            onClick={() =>
                                                                setSelectedVideo(
                                                                    item.url
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <Image
                                                            style={{
                                                                borderRadius: 6
                                                            }}
                                                            src={item.url}
                                                            className={
                                                                styles[
                                                                    'button-box'
                                                                ]
                                                            }
                                                            preview={{
                                                                toolbarRender:
                                                                    () => null,
                                                                mask: (
                                                                    <EyeOutlined />
                                                                )
                                                            }}
                                                            rootClassName={
                                                                styles[
                                                                    'outer-image'
                                                                ]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </>
                            </FormItemWithLabel>
                        </Form>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        title={'Владелец'}
                        bordered={true}
                        style={{
                            width: '100%',
                            height: '100%',
                            background: '#FFF'
                        }}
                        styles={{
                            header: { padding: '12px 15px', minHeight: 0 },
                            body: { padding: 15 }
                        }}
                    >
                        {currentCompany.businessAccount ? (
                            <Collapse
                                style={{ marginBottom: 15 }}
                                items={[
                                    {
                                        key: '1',
                                        label: 'Профиль',
                                        children: (
                                            <>
                                                <UserFiled
                                                    title={'Имя:'}
                                                    value={
                                                        currentCompany
                                                            .businessAccount
                                                            .user.full_name
                                                    }
                                                />
                                                <UserFiled
                                                    title={'День рождения:'}
                                                    value={
                                                        currentCompany
                                                            .businessAccount
                                                            .user.birthday
                                                            ? dayjs(
                                                                  currentCompany
                                                                      .businessAccount
                                                                      .user
                                                                      .birthday
                                                              ).format(
                                                                  'DD.MM.YYYY'
                                                              )
                                                            : null
                                                    }
                                                />
                                                <UserFiled
                                                    title={'Номер телефона:'}
                                                    value={
                                                        currentCompany
                                                            .businessAccount
                                                            .user.phone ?? null
                                                    }
                                                />
                                                <UserFiled
                                                    title={'Электронная почта:'}
                                                    value={
                                                        currentCompany
                                                            .businessAccount
                                                            .user.email ?? null
                                                    }
                                                />
                                                <UserFiled
                                                    title={'Создан:'}
                                                    value={dayjs(
                                                        currentCompany
                                                            .businessAccount
                                                            .user.created_at
                                                    ).format(
                                                        'DD.MM.YYYY HH:mm'
                                                    )}
                                                />
                                            </>
                                        )
                                    },
                                    {
                                        key: '2',
                                        label: 'Бизнес профиль',
                                        children: (
                                            <>
                                                <UserFiled
                                                    title={'Телефон:'}
                                                    value={
                                                        currentCompany
                                                            .businessAccount
                                                            .phone
                                                    }
                                                />
                                                <UserFiled
                                                    title={'Email:'}
                                                    value={
                                                        currentCompany
                                                            .businessAccount
                                                            .email
                                                    }
                                                />
                                                <UserFiled
                                                    title={'Метод связи:'}
                                                    value={
                                                        currentCompany
                                                            .businessAccount
                                                            .communication_method
                                                    }
                                                />
                                                <UserFiled
                                                    title={'Активные подписки:'}
                                                    emptyText={'Не найдено'}
                                                    value={
                                                        currentCompany.businessAccount?.planSubscriptions
                                                            ?.map(
                                                                item =>
                                                                    plans.find(
                                                                        plan =>
                                                                            plan.planId ===
                                                                            item.planId
                                                                    )?.name
                                                                        ?.title
                                                            )
                                                            .join('\n') || null
                                                    }
                                                />
                                                <UserFiled
                                                    title={'Создан:'}
                                                    value={dayjs(
                                                        currentCompany
                                                            .businessAccount
                                                            .created_at
                                                    ).format(
                                                        'DD.MM.YYYY HH:mm'
                                                    )}
                                                />
                                            </>
                                        )
                                    }
                                ]}
                            />
                        ) : (
                            <Paragraph
                                style={{
                                    margin: 10,
                                    textAlign: 'center'
                                }}
                                type={'danger'}
                            >
                                {'Владелец не найден!'}
                            </Paragraph>
                        )}
                        <Button
                            onClick={() =>
                                dispatch(actions.toggleModalChangeOwner(true))
                            }
                            style={{ width: '100%' }}
                            icon={
                                currentCompany.businessAccount ? (
                                    <SyncOutlined />
                                ) : (
                                    <PlusOutlined />
                                )
                            }
                        >
                            {currentCompany.businessAccount
                                ? 'Сменить владельца'
                                : 'Добавить владельца'}
                        </Button>
                        {currentCompany.businessAccount ? (
                            <Popconfirm
                                placement={'top'}
                                title={'Удаление владельца'}
                                description={'Вы уверены?'}
                                okText={'Да'}
                                cancelText={'Нет'}
                                icon={
                                    <QuestionCircleOutlined
                                        style={{ color: 'red' }}
                                    />
                                }
                                onConfirm={() =>
                                    currentCompany
                                        ? dispatch(
                                              actions.removeOwner({
                                                  company_id:
                                                      currentCompany.company_id
                                              })
                                          )
                                        : null
                                }
                            >
                                <Button
                                    style={{ width: '100%', marginTop: 7 }}
                                    type={'primary'}
                                    loading={isProcessRemoveOwner}
                                    icon={<DeleteOutlined />}
                                >
                                    {'Удалить владельца'}
                                </Button>
                            </Popconfirm>
                        ) : null}
                        <div style={{ marginTop: 15 }}>
                            <Text
                                style={{
                                    display: 'block',
                                    marginBottom: 5
                                }}
                            >
                                {'Франчайзи: '}
                                <Text strong>
                                    {currentCompany.franchisee
                                        ? currentCompany.franchisee.name
                                        : 'Не установлен'}
                                </Text>
                            </Text>
                            <Button
                                type={'dashed'}
                                onClick={() =>
                                    dispatch(
                                        actions.toggleModalChangeFranchisee(
                                            true
                                        )
                                    )
                                }
                                style={{ width: '100%' }}
                                icon={
                                    currentCompany.franchisee ? (
                                        <SyncOutlined />
                                    ) : (
                                        <PlusOutlined />
                                    )
                                }
                            >
                                {currentCompany.franchisee
                                    ? 'Сменить франчайзи'
                                    : 'Установить франчайзи'}
                            </Button>
                            {currentCompany.franchisee ? (
                                <Button
                                    style={{ width: '100%', marginTop: 8 }}
                                    loading={isProcessChangeFranchisee}
                                    type={'primary'}
                                    onClick={handleChangeFranchisee}
                                    icon={<DeleteOutlined />}
                                >
                                    {'Удалить франчайзи'}
                                </Button>
                            ) : null}
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        title={'Данные менеджера'}
                        bordered={true}
                        style={{
                            width: '100%',
                            height: '100%',
                            background: '#FFF'
                        }}
                        styles={{
                            header: { padding: '12px 15px', minHeight: 0 },
                            body: { padding: 15 }
                        }}
                    >
                        <Form layout={'vertical'}>
                            {companyPropertiesIsFetching ? (
                                <Row
                                    style={{ margin: '20px 0' }}
                                    justify={'center'}
                                >
                                    <Spin />
                                </Row>
                            ) : (
                                companyProperties.map(property => {
                                    const companyProperty =
                                        currentCompany.companyPropertyOptionValues?.find(
                                            companyOption =>
                                                companyOption.company_property_id ===
                                                property.company_property_id
                                        )

                                    return (
                                        <FormItemWithLabel
                                            key={property.company_property_id}
                                            name={property.key}
                                            label={property.caption}
                                        >
                                            <CompanyPropertySelector
                                                selectedValue={
                                                    companyProperty?.selected_value
                                                }
                                                propertyId={
                                                    property.company_property_id
                                                }
                                                loading={sendChangeCompanyProperties.includes(
                                                    property.company_property_id
                                                )}
                                                placeholder={property.caption}
                                                onChange={({ value }) =>
                                                    handleChangeCompanyProperty(
                                                        property.company_property_id,
                                                        value
                                                    )
                                                }
                                            />
                                        </FormItemWithLabel>
                                    )
                                })
                            )}
                            <FormItemWithLabel
                                name={'manager_status'}
                                label={'Комментарий'}
                            >
                                <TextArea
                                    style={{
                                        minHeight: 120
                                    }}
                                    defaultValue={general.manager_status ?? ''}
                                    value={general.manager_status ?? ''}
                                    placeholder={'Комментарий'}
                                    onChange={({ target }) =>
                                        changeGeneralField(
                                            'manager_status',
                                            target.value
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                            <FormItemWithLabel
                                name={'admin_message'}
                                label={'Сообщение для компании'}
                            >
                                <TextArea
                                    style={{
                                        minHeight: 120
                                    }}
                                    defaultValue={general.admin_message ?? ''}
                                    value={general.admin_message ?? ''}
                                    placeholder={'Сообщение для компании'}
                                    onChange={({ target }) =>
                                        changeGeneralField(
                                            'admin_message',
                                            target.value
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Form>
                    </Card>
                </Col>
            </Row>
            <NextStepButton
                hideNext={true}
                disabled={isDisabledSaveButton && !hasChangesMedia}
                onClick={handleSaveGeneral}
                loading={isSaving}
            >
                {'Сохранить изменения'}
            </NextStepButton>
            <Modal
                open={isModalChangeOwner}
                title={
                    currentCompany.businessAccount
                        ? 'Сменить владельца'
                        : 'Добавить владельца'
                }
                destroyOnClose={true}
                okText={'Сменить'}
                okButtonProps={{
                    disabled: !selectedBusinessUserId,
                    loading: isProcessChangeOwner
                }}
                onOk={handleChangeUser}
                onCancel={() => dispatch(actions.toggleModalChangeOwner(false))}
            >
                <BusinessUserSelector
                    onChange={setSelectedBusinessUserId}
                    style={{ width: '100%' }}
                />
            </Modal>
            <Modal
                open={isModalChangeFranchisee}
                title={
                    currentCompany.franchisee
                        ? 'Сменить франчайзи'
                        : 'Установить франчайзи'
                }
                destroyOnClose={true}
                footer={[
                    <Button
                        key={'1'}
                        onClick={() =>
                            dispatch(actions.toggleModalChangeFranchisee(false))
                        }
                    >
                        {'Отмена'}
                    </Button>,
                    <Button
                        disabled={!selectedFranchisee}
                        key={'2'}
                        type={'primary'}
                        loading={isProcessChangeFranchisee}
                        onClick={handleChangeFranchisee}
                        icon={
                            currentCompany.franchisee ? (
                                <SyncOutlined />
                            ) : (
                                <PlusOutlined />
                            )
                        }
                    >
                        {currentCompany.franchisee
                            ? 'Сменить франчайзи'
                            : 'Установить франчайзи'}
                    </Button>
                ]}
                onCancel={() =>
                    dispatch(actions.toggleModalChangeFranchisee(false))
                }
            >
                <FranchiseeSelector
                    onChange={setSelectedFranchisee}
                    style={{ width: '100%' }}
                />
            </Modal>
            <Modal
                open={isAddMediaModal}
                title={'Добавить изображение или видео'}
                onCancel={() => setIsAddMediaModal(false)}
                footer={null}
                width={400}
                destroyOnClose
            >
                <Row style={{ marginTop: 15 }} gutter={[15, 15]}>
                    <Col className={styles['col-box']} span={12}>
                        <ImgCrop
                            rotationSlider={false}
                            modalTitle={'Выбрать область'}
                            modalOk={'Выбрать'}
                        >
                            <Upload
                                showUploadList={false}
                                accept={'image/*'}
                                multiple
                                style={{ marginBottom: 10 }}
                                className={styles['button-box']}
                                disabled={false}
                                beforeUpload={image => {
                                    setIsAddMediaModal(false)
                                    dispatch(
                                        actions.uploadMediaImage({ image })
                                    )
                                    return false
                                }}
                            >
                                <Button className={styles['button-box']}>
                                    <FileImageOutlined
                                        style={{
                                            fontSize: 25,
                                            marginBottom: 10
                                        }}
                                    />
                                    <br />
                                    {'Добавить картинку'}
                                </Button>
                            </Upload>
                        </ImgCrop>
                    </Col>
                    <Col className={styles['col-box']} span={12}>
                        <Button
                            className={styles['button-box']}
                            onClick={() => setIsAddVideoModal(true)}
                        >
                            <VideoCameraAddOutlined
                                style={{ fontSize: 25, marginBottom: 10 }}
                            />
                            <br />
                            {'Добавить видео'}
                        </Button>
                    </Col>
                </Row>
                <Modal
                    open={isAddVideoModal}
                    onCancel={() => setIsAddVideoModal(false)}
                    title={'Добавить ссылку на YouTube'}
                    okText={'Добавить'}
                    okButtonProps={{
                        disabled:
                            !youTubeRegExp.test(youTubeLink) ||
                            generalMedia.some(item => item.url === youTubeLink)
                    }}
                    onOk={() => {
                        setIsAddVideoModal(false)
                        queueMicrotask(() => {
                            setIsAddMediaModal(false)
                            setYouTubeLink('')
                            dispatch(
                                actions.addMediaVideo({ url: youTubeLink })
                            )
                        })
                    }}
                >
                    <Input
                        value={youTubeLink}
                        onChange={event => setYouTubeLink(event.target.value)}
                        placeholder={'Ссылка на YouTube'}
                        allowClear
                    />
                    {generalMedia.some(item => item.url === youTubeLink) ? (
                        <Paragraph style={{ marginTop: 5 }} type={'danger'}>
                            {'Такое видео уже добавлено!'}
                        </Paragraph>
                    ) : null}
                </Modal>
            </Modal>
            <Modal
                open={!!selectedVideo}
                title={'Просмотр видео'}
                onCancel={() => setSelectedVideo('')}
                footer={null}
                destroyOnClose
            >
                <ReactPlayer
                    width={'100%'}
                    url={selectedVideo}
                    controls={true}
                />
            </Modal>
            {contextHolder}
        </>
    )
}
