import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    SearchPayload,
    SearchSuccessPayload,
    SearchFailurePayload
} from './types'

const initialState: InitialStateType = {
    companies: [],
    isFetching: false,
    error: null
}

const myRequestsCompanySelectorSlice = createSlice({
    name: 'myRequestsCompanySelector',
    initialState,
    reducers: {
        search(state: InitialStateType, _action: PayloadAction<SearchPayload>) {
            state.isFetching = true
            state.error = null
        },
        searchSuccess(
            state: InitialStateType,
            action: PayloadAction<SearchSuccessPayload>
        ) {
            state.companies = action.payload.companies
            state.isFetching = false
        },
        searchFailure(
            state: InitialStateType,
            action: PayloadAction<SearchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        }
    }
})

export const actions = myRequestsCompanySelectorSlice.actions

export default myRequestsCompanySelectorSlice.reducer
