import type { AxiosResponse } from 'axios'
import { del, get, patch, post } from '../../utils/api.ts'
import type { IForm } from './types.ts'

export const fetch = (
    page: number,
    page_size: number
): Promise<AxiosResponse> =>
    get('/admin/lead-prices', {
        page,
        page_size,
        include: 'area',
        sort: '-created_at'
    })

export const getDefaultPrice = (): Promise<AxiosResponse<void>> =>
    get('/admin/lead-prices/get-default')

export const setDefaultPrice = (coast: number): Promise<AxiosResponse<void>> =>
    post('/admin/lead-prices/set-default', { coast })

export const create = (form: IForm): Promise<AxiosResponse<void>> =>
    post('/admin/lead-prices', {
        area_id: form.area_id.split('{d}')[1],
        coast: form.coast
    })

export const edit = (
    lead_price_id: string,
    form: IForm
): Promise<AxiosResponse<void>> =>
    patch(`/admin/lead-prices/${lead_price_id}`, {
        area_id: form.area_id.split('{d}')[1],
        coast: form.coast
    })

export const remove = (lead_price_id: string): Promise<AxiosResponse<void>> =>
    del(`/admin/lead-prices/${lead_price_id}`)
