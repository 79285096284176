import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api.ts'
import type { IMeta } from '../../types'
import type { IEvent } from '../Events/types.ts'

export const fetchEvents = (
    search: string
): Promise<AxiosResponse<IMeta<IEvent>>> =>
    get('/admin/leads/event-autocomplete', {
        'filter[query]': search
    })
