import type { ReactElement } from 'react'
import type { IUserData } from '../../types.ts'
import type { Dispatch } from 'redux'
import { Button, Result } from 'antd'
import { actions } from '../../slice.ts'
import CustomEmpty from '../../../../components/CustomEmpty'
import dayjs from 'dayjs'
import FieldView from '../FieldView'
import {
    CalendarOutlined,
    DollarOutlined,
    HomeOutlined,
    HourglassOutlined,
    MailOutlined,
    PhoneOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons'

const fieldsTitles = {
    name: {
        title: 'ФИО',
        icon: <UserOutlined style={{ fontSize: 22 }} />
    },
    email: {
        title: 'Электронная почта',
        icon: <MailOutlined style={{ fontSize: 22 }} />
    },
    phone: {
        title: 'Номер телефона',
        icon: <PhoneOutlined style={{ fontSize: 22 }} />
    },
    city: {
        title: 'Город',
        icon: <HomeOutlined style={{ fontSize: 22 }} />
    },
    has_children: {
        title: 'Есть дети',
        icon: <TeamOutlined style={{ fontSize: 22 }} />
    },
    age: {
        title: 'Возраст детей',
        icon: <HourglassOutlined style={{ fontSize: 22 }} />
    },
    bday: {
        title: 'День рождения',
        icon: <CalendarOutlined style={{ fontSize: 22 }} />
    }
}

const ignoreCopyable = ['has_children', 'age']

interface Props {
    lead_id: string
    userData: IUserData | null
    isPaid: boolean
    isAdmin: boolean
    isProcessPaid: boolean
    dispatch: Dispatch
}

export default function UserInfo({
    lead_id,
    userData,
    isPaid,
    isAdmin,
    isProcessPaid,
    dispatch
}: Props): ReactElement | ReactElement[] {
    if (!isPaid && !isAdmin) {
        return (
            <Result
                status={'error'}
                title={'Ваша заявка не оплачена!'}
                subTitle={
                    'Для просмотра информации клиента, необходимо оплатить заявку.'
                }
                extra={[
                    <Button
                        loading={isProcessPaid}
                        type={'primary'}
                        key={'paid'}
                        icon={<DollarOutlined />}
                        onClick={() => dispatch(actions.pay({ lead_id }))}
                    >
                        {'Оплатить заявку'}
                    </Button>
                ]}
            />
        )
    }

    if (!userData) {
        return <CustomEmpty description={'Данные клиента, не найдены!'} />
    }

    const fields: {
        key: keyof typeof fieldsTitles
        title: string
        value: string
        icon: ReactElement
    }[] = []

    for (const [key, value] of Object.entries(userData)) {
        if (!(key in fieldsTitles)) {
            continue
        }

        let formatedValue = 'Не указано'

        if (value !== null) {
            switch (key) {
                case 'bday':
                    formatedValue = dayjs(value).format('DD.MM.YYYY')
                    break
                case 'age':
                    if (
                        Array.isArray(value) &&
                        value.length === 2 &&
                        value.every(age => typeof age === 'number')
                    ) {
                        formatedValue = `от ${value[0]} до ${value[1]}`
                    }
                    break
                case 'city':
                    formatedValue =
                        typeof value === 'object' ? value.label : value
                    break
                case 'has_children':
                    if (typeof value === 'boolean') {
                        formatedValue = value ? 'Есть' : 'Нет'
                    }
                    break
                default:
                    formatedValue = value
            }
        }

        fields.push({
            key: key as keyof typeof fieldsTitles,
            title: fieldsTitles[key as keyof typeof fieldsTitles].title,
            value: formatedValue,
            icon: fieldsTitles[key as keyof typeof fieldsTitles].icon
        })
    }

    return fields
        .filter(
            field =>
                userData[field.key as keyof typeof userData] !== null &&
                field.value !== null
        )
        .sort(
            (a, b) =>
                Object.keys(fieldsTitles).indexOf(a.key) -
                Object.keys(fieldsTitles).indexOf(b.key)
        )
        .map((field, index) => (
            <FieldView
                key={field.key}
                isFirst={index === 0}
                icon={field.icon}
                title={field.title}
                value={field.value}
                copyable={!ignoreCopyable.includes(field.key)}
            />
        ))
}
