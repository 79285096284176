import { Button, notification, Table } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import TitleStep from '../../../../components/TitleStep'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companyWizardSelector } from '../../selectors'
import { useEffect, useState } from 'react'
import type { ColumnsType } from 'antd/es/table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapLocation } from '@fortawesome/free-solid-svg-icons'
import NextStepButton from '../../../../components/NextStepButton'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import AreaSelector from '../../../AreaSelector'
import CustomEmpty from '../../../../components/CustomEmpty'

interface DataType {
    area_id: string
    name: string
    onRemove: (indexRemove: number) => void
}

const columns: ColumnsType<DataType> = [
    {
        title: '',
        dataIndex: '',
        key: 'icon',
        align: 'center',
        render: () => <FontAwesomeIcon icon={faMapLocation} color={'#FF9D05'} />
    },
    {
        title: 'Регион',
        dataIndex: 'name',
        key: 'name',
        width: '100%'
    },
    {
        title: '',
        dataIndex: '',
        key: 'remove',
        render: (data, _, index) => (
            <Button danger onClick={() => data.onRemove(index)}>
                <DeleteOutlined />
            </Button>
        )
    }
]

export default function RegionStep() {
    const [regions, setRegions] = useState<DataType[]>([])
    const [isModal, setIsModal] = useState(false)

    const dispatch = useDispatch()

    const { isSaving, hasStepChanges, currentCompany } = useAppSelector(
        companyWizardSelector
    )

    const onRemove = (indexRemove: number): void => {
        setRegions(prev => prev.filter((_, index) => index !== indexRemove))
    }

    useEffect(() => {
        if (currentCompany) {
            if (
                Array.isArray(currentCompany.companyAreas) &&
                currentCompany.companyAreas.length > 0
            ) {
                const hasChanges =
                    currentCompany.companyAreas.length !== regions.length ||
                    currentCompany.companyAreas.some(address =>
                        regions.some(item => item.area_id !== address.area_id)
                    )

                dispatch(actions.setHasStepChanges(hasChanges))
            } else {
                dispatch(actions.setHasStepChanges(regions.length > 0))
            }
        }
    }, [currentCompany, regions])

    const handleUndoChanges = () => {
        if (currentCompany && Array.isArray(currentCompany.companyAreas)) {
            const result: DataType[] = []
            for (const item of currentCompany.companyAreas) {
                result.push({
                    area_id: item.area_id,
                    name: item.name,
                    onRemove
                })
            }
            setRegions(result)
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleSaveRegions = (isTrusted: boolean): void => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        areas: regions.map(area => area.area_id)
                    },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    const handleAddRegion = (
        data: { area_id: string; title: string }[]
    ): void => {
        const hasAdded = regions.some(region =>
            data.some(item => item.area_id === region.area_id)
        )

        if (hasAdded) {
            notification.open({
                type: 'error',
                message: 'Ошибка',
                description: 'Регион уже добавлен!',
                placement: 'top'
            })
            return
        }

        setRegions(prev => [
            ...prev,
            ...data.map(item => ({
                area_id: item.area_id,
                name: item.title,
                onRemove
            }))
        ])

        setIsModal(false)
    }

    return (
        <>
            <TitleStep
                rightElement={
                    <Button
                        onClick={() => setIsModal(true)}
                        icon={<PlusOutlined />}
                        loading={isSaving}
                    >
                        {'Добавить регионы'}
                    </Button>
                }
            >
                {'Регион показа'}
            </TitleStep>
            <Table
                columns={columns}
                dataSource={regions}
                rowKey={record => record.area_id}
                pagination={false}
                locale={{
                    emptyText: (
                        <CustomEmpty description={'Регионы не добавлены'} />
                    )
                }}
            />
            <NextStepButton
                disabled={regions.length === 0 || !hasStepChanges}
                loading={isSaving}
                onClick={handleSaveRegions}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            <AreaSelector
                showModal={isModal}
                onClose={() => setIsModal(false)}
                onAdd={handleAddRegion}
            />
        </>
    )
}
