import type { SagaIterator } from '@redux-saga/core'
import {
    all,
    call,
    fork,
    put,
    takeEvery,
    select,
    getContext
} from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type {
    FetchAddressPayload,
    FetchCategoriesPayload,
    FetchEventPayload,
    RemovePhotoPayload,
    RemoveVideoPayload,
    SaveVideoPayload,
    SortGalleryPayload,
    UploadGalleryPayload,
    SaveEventPayload,
    CroppingPhotoPayload
} from './types'
import { eventWizardSelector } from './selectors'
import type { createBrowserRouter } from 'react-router-dom'

export function* handleFetchPlaceCompanies(): SagaIterator {
    try {
        const { data } = yield call(api.fetchPlaceCompanies)
        yield put(actions.fetchPlaceCompaniesSuccess({ data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchPlaceCompaniesFailure({
                error: message
            })
        )
    }
}

export function* watchFetchPlaceCompanies(): SagaIterator {
    yield takeEvery(actions.fetchPlaceCompanies, handleFetchPlaceCompanies)
}

export function* handleFetchCategories(action: {
    payload: FetchCategoriesPayload
}): SagaIterator {
    try {
        const { data } = yield call(api.fetchCategories, action.payload.type)
        yield put(actions.fetchCategoriesSuccess({ data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchCategoriesFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCategories(): SagaIterator {
    yield takeEvery(actions.fetchCategories, handleFetchCategories)
}

export function* handleCreateEvent(): SagaIterator {
    try {
        const { preCreateData } = yield select(eventWizardSelector)
        const { data } = yield call(api.createEvent, preCreateData)
        yield put(actions.createEventSuccess())
        const router: ReturnType<typeof createBrowserRouter> =
            yield getContext('router')
        void router.navigate(`/events/${data.event_id}/edit/?page=categories`)
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createEventFailure({
                error: message
            })
        )
    }
}

export function* watchCreateEvent(): SagaIterator {
    yield takeEvery(actions.createEvent, handleCreateEvent)
}

export function* handleFetchEvent(action: {
    payload: FetchEventPayload
}): SagaIterator {
    try {
        const { data } = yield call(api.fetchEvent, action.payload.event_id)

        const isCreated =
            action.payload.isSendForModeration &&
            data.status === 'draft' &&
            data.summary_status === null

        let leadCoast = data.lead_coast

        if (
            !data.lead_coast &&
            Array.isArray(data.companies) &&
            data.companies.length > 0
        ) {
            const { data: dataLeadCoast } = yield call(
                api.getEventLeadCoast,
                data.companies[0].company_id,
                data.event_id
            )
            leadCoast = dataLeadCoast.data.price
        }

        if (isCreated) {
            yield call(api.sendFinishCreationEvent, action.payload.event_id)
            yield put(
                actions.fetchEvent({
                    event_id: action.payload.event_id
                })
            )
        }

        yield put(
            actions.fetchEventSuccess({
                data: isCreated
                    ? {
                          ...data,
                          status: 'active'
                      }
                    : data,
                leadCoast
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchEventFailure({
                error: message
            })
        )
    }
}

export function* watchFetchEvent(): SagaIterator {
    yield takeEvery(actions.fetchEvent, handleFetchEvent)
}

export function* handleSaveEvent(action: {
    payload: SaveEventPayload
}): SagaIterator {
    try {
        yield call(api.saveEvent, action.payload.event_id, action.payload.form)

        yield put(
            actions.saveEventSuccess({
                isLastStep: action.payload.isLastStep
            })
        )

        yield put(
            actions.fetchEvent({
                event_id: action.payload.event_id,
                isSendForModeration: true
            })
        )

        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.saveEventFailure({
                error: message
            })
        )
    }
}

export function* watchSaveEvent(): SagaIterator {
    yield takeEvery(actions.saveEvent, handleSaveEvent)
}

export function* handleUploadGallery(action: {
    payload: UploadGalleryPayload
}): SagaIterator {
    try {
        const { event_id, files } = action.payload
        const { data } = yield call(api.uploadGallery, event_id, files)
        yield put(actions.uploadGallerySuccess({ gallery: data.gallery }))
        yield put(actions.fetchEvent({ event_id }))
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.uploadGalleryFailure({
                error: message
            })
        )
    }
}

export function* watchUploadGallery(): SagaIterator {
    yield takeEvery(actions.uploadGallery, handleUploadGallery)
}

export function* handleRemovePhoto(action: {
    payload: RemovePhotoPayload
}): SagaIterator {
    const { event_id, media_id } = action.payload
    try {
        const { data } = yield call(api.removeMedia, event_id, media_id)
        yield put(
            actions.removePhotoSuccess({ media_id, gallery: data.gallery })
        )
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removePhotoFailure({
                media_id,
                error: message
            })
        )
    }
}

export function* watchRemovePhoto(): SagaIterator {
    yield takeEvery(actions.removePhoto, handleRemovePhoto)
}

export function* handleSortGallery(action: {
    payload: SortGalleryPayload
}): SagaIterator {
    try {
        const { event_id, sort_ids } = action.payload
        yield call(api.sortGallery, event_id, sort_ids)
        yield put(actions.sortGallerySuccess())
        yield put(actions.fetchEvent({ event_id }))
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.sortGalleryFailure({
                error: message
            })
        )
    }
}

export function* watchSortGallery(): SagaIterator {
    yield takeEvery(actions.sortGallery, handleSortGallery)
}

export function* handleSaveVideo(action: {
    payload: SaveVideoPayload
}): SagaIterator {
    try {
        const { event_id, videos } = action.payload
        const { data } = yield call(api.saveVideo, event_id, videos)
        yield put(actions.saveVideoSuccess({ videos: data.videos }))
        yield put(actions.fetchEvent({ event_id }))
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.saveVideoFailure({
                error: message
            })
        )
    }
}

export function* watchSaveVideo(): SagaIterator {
    yield takeEvery(actions.saveVideo, handleSaveVideo)
}

export function* handleRemoveVideo(action: {
    payload: RemoveVideoPayload
}): SagaIterator {
    const { event_id, media_id } = action.payload
    try {
        const { data } = yield call(api.removeMedia, event_id, media_id)
        yield put(actions.removeVideoSuccess({ media_id, videos: data.videos }))
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeVideoFailure({
                media_id,
                error: message
            })
        )
    }
}

export function* watchRemoveVideo(): SagaIterator {
    yield takeEvery(actions.removeVideo, handleRemoveVideo)
}

export function* handleFetchAddress(action: {
    payload: FetchAddressPayload
}): SagaIterator {
    try {
        const { data } = yield call(api.fetchAddress, action.payload.company_id)
        yield put(
            actions.fetchAddressSuccess({
                company_id: action.payload.company_id,
                data
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchAddressFailure({
                error: message
            })
        )
    }
}

export function* watchAddress(): SagaIterator {
    yield takeEvery(actions.fetchAddress, handleFetchAddress)
}

export function* handleCroppingPhoto(action: {
    payload: CroppingPhotoPayload
}): SagaIterator {
    try {
        yield call(api.croppingPhoto, action.payload)
        yield put(actions.croppingPhotoSuccess())
        yield put(
            actions.fetchEvent({
                event_id: action.payload.event_id
            })
        )
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.croppingPhotoFailure({
                error: message
            })
        )
    }
}

export function* watchCroppingPhoto(): SagaIterator {
    yield takeEvery(actions.croppingPhoto, handleCroppingPhoto)
}

export default function* watchEventWizard(): SagaIterator {
    yield all([
        fork(watchFetchPlaceCompanies),
        fork(watchFetchCategories),
        fork(watchCreateEvent),
        fork(watchFetchEvent),
        fork(watchSaveEvent),
        fork(watchUploadGallery),
        fork(watchRemovePhoto),
        fork(watchSortGallery),
        fork(watchSaveVideo),
        fork(watchRemoveVideo),
        fork(watchAddress),
        fork(watchCroppingPhoto)
    ])
}
