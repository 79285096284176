import type { ReactElement } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Button, Pagination, Row, Table, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../hooks/useAppSelector.ts'
import { companySelector } from '../../selectors.ts'
import { columns } from '../../../Events'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice.ts'
import CustomEmpty from "../../../../components/CustomEmpty";

const { Title } = Typography

export default function TabEvents(): ReactElement {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        currentCompany,
        companyEvents,
        companyEventsMeta,
        isFetchingCompanyEvents
    } = useAppSelector(companySelector)

    useEffect(() => {
        if (currentCompany) {
            dispatch(
                actions.fetchCompanyEvents({
                    page,
                    pageSize,
                    companyId: currentCompany.company_id
                })
            )
        }
    }, [page, pageSize, currentCompany])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        pageValue && setPage(pageValue)
        pageSizeValue && setPageSize(pageSizeValue)
    }

    return (
        <>
            <Row
                style={{ marginBottom: 15 }}
                justify={'space-between'}
                align={'middle'}
            >
                <Title style={{ margin: 0 }} level={4}>
                    {'Предложения компании'}
                </Title>
                <Button
                    onClick={() =>
                        navigate(
                            `/events/create?place=${encodeURIComponent(currentCompany?.name ?? '')}{d}${currentCompany?.company_id}`
                        )
                    }
                    icon={<PlusOutlined />}
                >
                    {'Создать предложение'}
                </Button>
            </Row>
            <Table
                dataSource={companyEvents}
                columns={columns(navigate).filter(
                    column => column.key !== 'companies'
                )}
                locale={{
                    emptyText: <CustomEmpty />
                }}
                rowKey={'event_id'}
                loading={isFetchingCompanyEvents}
                pagination={false}
                footer={() => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Pagination
                            showQuickJumper
                            current={page}
                            pageSize={pageSize}
                            defaultCurrent={1}
                            defaultPageSize={pageSize}
                            total={companyEventsMeta?.total}
                            onChange={handleChangePagination}
                        />
                    </div>
                )}
            />
        </>
    )
}
