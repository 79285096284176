import type { AxiosResponse } from 'axios'
import { del, get, post } from '../../utils/api.ts'

export const fetch = (
    page: number,
    page_size: number,
    filter: Record<string, string>
): Promise<AxiosResponse> => {
    const filterParam: Record<string, string> = {}

    const dateKeys = ['lead']

    for (const key in filter) {
        if (dateKeys.includes(key)) {
            const [from, to] = filter[key].split(',')

            const regExp = /(\d{4})(\d{2})(\d{2})/

            const formatFrom = from.replace(regExp, '$1$2$3')
            const formatTo = to.replace(regExp, '$1$2$3')

            filterParam[key] = `${formatFrom},${formatTo}`
            continue
        }
        filterParam[key] = filter[key].includes('{d}')
            ? filter[key].split('{d}')[1]
            : filter[key]
    }

    return get('/admin/leads', {
        page,
        page_size,
        sort: '-created_at',
        filter: {
            ...filterParam
        },
        include: ['company', 'event']
    })
}

export const remove = (lead_id: string): Promise<AxiosResponse<void>> =>
    del(`/admin/leads/${lead_id}`)

export const pay = (lead_ids: string[]): Promise<AxiosResponse<void>> =>
    post('/admin/leads/pay', { lead_ids })

export const downloadExcel = async (
    page: number,
    page_size: number,
    filter: Record<string, string>
): Promise<void> => {
    const filterParam: Record<string, string> = {}

    const dateKeys = ['lead']

    for (const key in filter) {
        if (dateKeys.includes(key)) {
            const [from, to] = filter[key].split(',')

            const regExp = /(\d{4})(\d{2})(\d{2})/

            const formatFrom = from.replace(regExp, '$1$2$3')
            const formatTo = to.replace(regExp, '$1$2$3')

            filterParam[key] = `${formatFrom},${formatTo}`
            continue
        }
        filterParam[key] = filter[key].includes('{d}')
            ? filter[key].split('{d}')[1]
            : filter[key]
    }

    const response = await get(
        '/admin/leads/export',
        {
            page,
            page_size,
            ...filterParam
        },
        {
            responseType: 'blob'
        }
    )

    const timestamp = new Date().getTime()
    const fileName = `my-requests-${timestamp}.xlsx`

    const url = window.URL.createObjectURL(
        new Blob([response.data as BlobPart])
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()

    link.parentNode?.removeChild(link)
}
