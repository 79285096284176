import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { actions } from '../../containers/SalesSettings/slice.ts'
import { Form, InputNumber, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { salesSettingsSelector } from '../../containers/SalesSettings/selectors.ts'
import { useForm } from 'antd/es/form/Form'
import RegionSelector from '../../containers/RegionSelector'
import type { IForm } from '../../containers/SalesSettings/types.ts'
import { fromRuble, toRuble } from '../../utils/helpers.ts'

export default function SalesSettingsWizardModal(): ReactElement {
    const { isModalWizardOpen, selectedAreaSettings } = useAppSelector(
        salesSettingsSelector
    )

    const dispatch = useDispatch()

    const [form] = useForm()

    const watchRegion = Form.useWatch('area_id', form)

    useEffect(() => {
        if (selectedAreaSettings) {
            form.setFieldsValue({
                area_id: `${selectedAreaSettings.area.name}{d}${selectedAreaSettings.area.area_id}`,
                coast: toRuble(selectedAreaSettings.coast)
            })
        } else {
            form.resetFields()
        }
    }, [selectedAreaSettings])

    const isEdit = !!selectedAreaSettings

    const handleSendForm = (values: IForm) => {
        if (selectedAreaSettings) {
            dispatch(
                actions.edit({
                    lead_price_id: selectedAreaSettings?.lead_price_id,
                    form: {
                        ...values,
                        coast: fromRuble(parseInt(values.coast)).toString()
                    }
                })
            )
        } else {
            dispatch(
                actions.create({
                    form: {
                        ...values,
                        coast: fromRuble(parseInt(values.coast)).toString()
                    }
                })
            )
        }
    }
    return (
        <Modal
            open={isModalWizardOpen}
            title={`${isEdit ? 'Редактирование' : 'Создание'} настройки продаж`}
            okText={isEdit ? 'Сохранить' : 'Создать'}
            onOk={form.submit}
            onCancel={() =>
                dispatch(actions.toggleModalWizardOpen({ isOpen: false }))
            }
            destroyOnClose={true}
        >
            <Form form={form} layout={'vertical'} onFinish={handleSendForm}>
                <Form.Item
                    label={'Регион цены'}
                    name={'area_id'}
                    style={{ marginBottom: 10 }}
                    rules={[
                        {
                            required: true,
                            message: 'Необходимо выбрать регион!'
                        }
                    ]}
                >
                    <RegionSelector
                        initialValue={watchRegion}
                        onChange={value => form.setFieldValue('area_id', value)}
                    />
                </Form.Item>
                <Form.Item
                    label={'Цена'}
                    name={'coast'}
                    rules={[
                        {
                            required: true,
                            message: 'Необходимо ввести цену!'
                        }
                    ]}
                >
                    <InputNumber
                        addonAfter={'₽'}
                        placeholder={'Укажите цену'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}
