export const RequestStatus = {
    new: 'Новая',
    processed: 'Обработана',
    in_progress: 'В работе',
    closed: 'Закрыта'
}

export const TypeStatus = {
    promocode: 'Получение промокода',
    booking: 'Бронирование',
    purchase: 'Покупка'
}
