import type { ReactElement } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import {
    Affix,
    Anchor,
    Button,
    Col,
    Collapse,
    DatePicker,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Slider,
    Spin,
    Typography
} from 'antd'
import { Link, useParams } from 'react-router-dom'
import {
    ArrowLeftOutlined,
    AuditOutlined,
    BulbOutlined,
    EditOutlined,
    FieldTimeOutlined,
    FireOutlined,
    PercentageOutlined,
    PlusOutlined,
    TagOutlined
} from '@ant-design/icons'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { RequestStatus, TypeStatus } from './consts.ts'
import MaskedInput from 'antd-mask-input'
import CitySelector from '../CitySelector'
import dayjs from 'dayjs'
import type { IForm } from './types.ts'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { salesMyRequestsWizardSelector } from './selectors.ts'
import FieldView from './components/FieldView'
import UserInfo from './components/UserInfo'
import { authorizationSelector } from '../Authorization/selectors.ts'
import { getRole } from '../../utils/helpers.ts'

const { Title } = Typography
const { TextArea } = Input

interface Props {
    isCreate?: boolean
}

const promocodeViewFields = [
    {
        key: 'promocode',
        label: 'Промокод'
    },
    {
        key: 'description',
        label: 'Описание'
    },
    {
        key: 'expiration_at',
        label: 'Дата окончания'
    },
    {
        key: 'url',
        label: 'Ссылка'
    }
]

export default function SalesMyRequestsWizard({
    isCreate
}: Props): ReactElement {
    const [isEditForm, setIsEditForm] = useState(false)

    const dispatch = useDispatch()

    const params = useParams<{ lead_id?: string }>()

    const { profile } = useAppSelector(authorizationSelector)
    const { currentRequest, isFetching, isProcessWizard, isProcessPaid } =
        useAppSelector(salesMyRequestsWizardSelector)

    const isAdmin = profile ? getRole(profile.roles) === 'admin' : false

    const [form] = Form.useForm()

    const isExternalRequest =
        !isCreate &&
        currentRequest &&
        (!!currentRequest.company_id || !!currentRequest.event_id)

    useEffect(() => {
        if (!isCreate && params.lead_id) {
            dispatch(actions.fetch({ lead_id: params.lead_id }))
        }
        return () => {
            dispatch(actions.destroy())
        }
    }, [])

    useEffect(() => {
        if (currentRequest) {
            const { lead_at, status, type, coast, user_data, comment } =
                currentRequest

            const userDataFields: Record<string, unknown> = {}

            if (user_data) {
                for (const [key, value] of Object.entries(user_data)) {
                    if (key === 'city') {
                        userDataFields['lead_data_city'] =
                            typeof value === 'object'
                                ? value
                                : {
                                      label: value,
                                      value: value,
                                      key: value
                                  }
                    } else {
                        userDataFields[`lead_data_${key}`] =
                            key === 'bday'
                                ? value
                                    ? dayjs(value as string)
                                    : null
                                : value
                    }
                }
            }

            form.setFieldsValue({
                lead_at: dayjs(lead_at),
                status,
                type,
                coast,
                comment,
                ...userDataFields
            })
        } else {
            form.resetFields()
        }
    }, [currentRequest])

    const handleFinishForm = (values: IForm) => {
        const leadData: Record<string, unknown> = {}

        for (const [key, value] of Object.entries(values)) {
            if (key.startsWith('lead_data') && value) {
                leadData[key.replace('lead_data_', '')] =
                    key === 'lead_data_city' ? value.label : value
                delete values[key as keyof typeof values]
            }
        }

        values.user_data = leadData

        if (isCreate) {
            dispatch(
                actions.create({
                    form: { ...values, source: 'company', coast: '0' }
                })
            )
        } else if (params.lead_id) {
            dispatch(
                actions.edit({
                    lead_id: params.lead_id,
                    form:
                        isExternalRequest && !isEditForm
                            ? {
                                  status: values.status,
                                  comment: values.comment
                              }
                            : values
                })
            )
        }
    }

    const generateChildrenPromocode = (key: string): ReactElement | null => {
        const current = promocodeViewFields.find(item => item.key === key)
        if (current && currentRequest?.promocode) {
            const promocodeData = currentRequest.promocode
            const value = promocodeData[key as keyof typeof promocodeData]
            if (['promocode', 'description'].includes(key)) {
                console.log('promocodeData', promocodeData)
                return <>{value}</>
            }
            if ('expiration_at' === key) {
                if (typeof value === 'string') {
                    return <>{dayjs(value).format('DD.MM.YYYY HH:mm')}</>
                } else {
                    return <>{'Не указано'}</>
                }
            }
            if ('url' === key && typeof value === 'string') {
                return (
                    <Link to={value} target={'_blank'}>
                        {value}
                    </Link>
                )
            }
        }

        return null
    }

    return (
        <>
            <Row justify={'space-between'} align={'middle'}>
                <Col span={8}>
                    <Title level={3} style={{ margin: 0 }}>
                        {isCreate ? 'Создание заявки' : 'Просмотр заявки'}
                    </Title>
                    <Row>
                        <Link className={styles.link} to={'/sales/my-requests'}>
                            <ArrowLeftOutlined
                                style={{ color: '#ff57a5', marginRight: 5 }}
                            />
                            {'Вернуться к списку'}
                        </Link>
                    </Row>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 20 }} />
            <Row gutter={[20, 20]}>
                <Col span={4}>
                    <Affix>
                        <div>
                            <Anchor
                                affix={false}
                                bounds={240}
                                targetOffset={50}
                                items={[
                                    {
                                        key: '1',
                                        href: `${location.pathname}#basic`,
                                        title: 'Основные данные'
                                    },
                                    {
                                        key: '2',
                                        href: `${location.pathname}#client`,
                                        title: 'Данные клиента'
                                    },
                                    {
                                        key: '3',
                                        href: `${location.pathname}#info`,
                                        title: 'Данные заявки'
                                    }
                                ]}
                            />
                        </div>
                    </Affix>
                </Col>
                <Col span={14}>
                    <Form
                        form={form}
                        layout={'vertical'}
                        initialValues={{
                            status: 'new',
                            type: 'promocode',
                            lead_data_age: [0, 18],
                            comment: ''
                        }}
                        disabled={isFetching}
                        onFinish={handleFinishForm}
                    >
                        <div id={'basic'} className={styles.block}>
                            <Title style={{ marginTop: 0 }} level={4}>
                                {'Основные данные'}
                            </Title>
                            <FieldView
                                isFirst={true}
                                value={
                                    <Form.Item
                                        name={'status'}
                                        label={''}
                                        className={styles.label}
                                        style={{
                                            width: 300,
                                            marginTop: 5
                                        }}
                                    >
                                        <Select
                                            options={Object.keys(
                                                RequestStatus
                                            ).map(statusKey => ({
                                                label: RequestStatus[
                                                    statusKey as keyof typeof RequestStatus
                                                ],
                                                value: statusKey
                                            }))}
                                            placeholder={'Статус заявки'}
                                        />
                                    </Form.Item>
                                }
                                icon={<TagOutlined style={{ fontSize: 22 }} />}
                                title={'Статус'}
                                copyable={false}
                            />
                            {!isCreate && isFetching ? (
                                <Spin />
                            ) : (
                                <FieldView
                                    isFirst={false}
                                    value={
                                        isExternalRequest ? (
                                            TypeStatus[
                                                currentRequest.type as keyof typeof TypeStatus
                                            ]
                                        ) : (
                                            <Form.Item
                                                name={'type'}
                                                label={''}
                                                className={styles.label}
                                                style={{
                                                    width: 300,
                                                    marginTop: 5
                                                }}
                                            >
                                                <Select
                                                    options={Object.keys(
                                                        TypeStatus
                                                    ).map(typeKey => ({
                                                        label: TypeStatus[
                                                            typeKey as keyof typeof TypeStatus
                                                        ],
                                                        value: typeKey
                                                    }))}
                                                    placeholder={'Тип заявки'}
                                                />
                                            </Form.Item>
                                        )
                                    }
                                    icon={
                                        <AuditOutlined
                                            style={{ fontSize: 22 }}
                                        />
                                    }
                                    title={'Тип заявки'}
                                    copyable={false}
                                />
                            )}
                        </div>
                        <div id={'client'} className={styles.block}>
                            <Title style={{ marginTop: 0 }} level={4}>
                                {'Данные клиента'}
                            </Title>
                            {!isCreate && isFetching ? (
                                <Spin />
                            ) : isExternalRequest && !isEditForm ? (
                                <>
                                    <UserInfo
                                        lead_id={currentRequest.lead_id}
                                        userData={currentRequest.user_data}
                                        isPaid={!!currentRequest.paid_at}
                                        isAdmin={isAdmin}
                                        isProcessPaid={isProcessPaid}
                                        dispatch={dispatch}
                                    />
                                    {currentRequest.paid_at || isAdmin ? (
                                        <Button
                                            icon={<EditOutlined />}
                                            size={'small'}
                                            type={'primary'}
                                            onClick={() => setIsEditForm(true)}
                                        >
                                            {'Редактировать заявку'}
                                        </Button>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <Form.Item
                                        name={'lead_data_name'}
                                        label={'ФИО'}
                                        className={styles.label}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Пожалуйста введите ФИО!'
                                            }
                                        ]}
                                    >
                                        <Input placeholder={'ФИО'} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'lead_data_email'}
                                        label={'Электронная почта'}
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'Некорректный email!'
                                            }
                                        ]}
                                        className={styles.label}
                                    >
                                        <Input
                                            placeholder={'Электронная почта'}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={'lead_data_phone'}
                                        label={'Номер телефона'}
                                        className={styles.label}
                                        key={isProcessWizard ? '1' : '0'}
                                    >
                                        <MaskedInput
                                            mask={'+7 (000) 000-00-00'}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={'lead_data_city'}
                                        label={'Город'}
                                        className={styles.label}
                                    >
                                        <CitySelector />
                                    </Form.Item>
                                    <Form.Item
                                        name={'lead_data_has_children'}
                                        label={'Есть дети'}
                                        className={styles.label}
                                    >
                                        <Select
                                            options={[
                                                { label: 'Да', value: true },
                                                { label: 'Нет', value: false }
                                            ]}
                                            placeholder={'Есть дети'}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={'lead_data_age'}
                                        label={'Возраст детей'}
                                        className={styles.label}
                                    >
                                        <Slider range max={18} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'lead_data_bday'}
                                        label={'Дата рождения'}
                                        className={styles.label}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            placeholder={'Дата рождения'}
                                            format={'DD.MM.YYYY'}
                                            maxDate={dayjs()}
                                        />
                                    </Form.Item>
                                </>
                            )}
                        </div>
                        <div id={'info'} className={styles.block}>
                            <Title style={{ marginTop: 0 }} level={4}>
                                {'Данные заявки'}
                            </Title>
                            {currentRequest ? (
                                <>
                                    {currentRequest.company_id ? (
                                        <FieldView
                                            isFirst={true}
                                            value={
                                                <Link
                                                    to={`/companies/${currentRequest.company_id}`}
                                                    className={styles.link}
                                                    target={'_blank'}
                                                >
                                                    {
                                                        currentRequest.company
                                                            .name
                                                    }
                                                </Link>
                                            }
                                            icon={
                                                <BulbOutlined
                                                    style={{ fontSize: 24 }}
                                                />
                                            }
                                            title={'Компания'}
                                            copyable={false}
                                        />
                                    ) : null}
                                    {currentRequest.event_id ? (
                                        <FieldView
                                            isFirst={false}
                                            value={
                                                <Link
                                                    to={`/events/${currentRequest.event_id}`}
                                                    className={styles.link}
                                                    target={'_blank'}
                                                >
                                                    {currentRequest.event.name}
                                                </Link>
                                            }
                                            icon={
                                                <FireOutlined
                                                    style={{ fontSize: 24 }}
                                                />
                                            }
                                            title={'Предложение'}
                                            copyable={false}
                                        />
                                    ) : null}
                                    {currentRequest.promocode ? (
                                        <FieldView
                                            isFirst={false}
                                            value={
                                                <Collapse
                                                    size={'small'}
                                                    style={{ marginTop: 7 }}
                                                    items={promocodeViewFields.map(
                                                        item => ({
                                                            key: item.key,
                                                            label: item.label,
                                                            children:
                                                                generateChildrenPromocode(
                                                                    item.key
                                                                )
                                                        })
                                                    )}
                                                />
                                            }
                                            icon={
                                                <PercentageOutlined
                                                    style={{ fontSize: 24 }}
                                                />
                                            }
                                            copyable={false}
                                            title={'Данные промокода'}
                                        />
                                    ) : null}
                                    <FieldView
                                        isFirst={false}
                                        value={dayjs(
                                            currentRequest.created_at
                                        ).format('DD.MM.YYYY HH:mm')}
                                        icon={
                                            <FieldTimeOutlined
                                                style={{ fontSize: 24 }}
                                            />
                                        }
                                        title={'Дата создания заявки'}
                                    />
                                </>
                            ) : null}
                            <Title
                                level={5}
                                style={{
                                    margin: currentRequest
                                        ? '10px 0'
                                        : '0 0 10px'
                                }}
                            >
                                {'Комментарий менеджера'}
                            </Title>
                            <Form.Item
                                name={'comment'}
                                label={''}
                                style={{ margin: '0 0 20px' }}
                            >
                                <TextArea
                                    disabled={isFetching}
                                    placeholder={'Комментарий менеджера'}
                                    style={{ height: 100 }}
                                />
                            </Form.Item>
                        </div>
                        <Row justify={'end'}>
                            <Button
                                disabled={isFetching}
                                loading={isProcessWizard}
                                htmlType={'submit'}
                                type={'primary'}
                                icon={
                                    isCreate ? (
                                        <PlusOutlined />
                                    ) : (
                                        <EditOutlined />
                                    )
                                }
                            >
                                {isCreate
                                    ? 'Создать заявку'
                                    : 'Сохранить изменения'}
                            </Button>
                        </Row>
                    </Form>
                </Col>
                <Col span={6}></Col>
            </Row>
        </>
    )
}
