import type { ReactElement } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { Select, type SelectProps, Spin } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { citySelector } from './selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import classNames from 'classnames'

interface Props {
    isTransparent?: boolean
}

export interface ICityValue {
    caption: string
    label: string
    location: string
    value: string
}

export default function CitySelector(props: Props & SelectProps): ReactElement {
    const [key, setKey] = useState(Date.now())
    const { data, isFetching } = useAppSelector(citySelector)

    const dispatch = useDispatch()

    const refSelect = useRef(null)

    useEffect(() => {
        if (!props.disabled && refSelect.current) {
            dispatch(actions.destroy())
            setKey(Date.now())
        }
    }, [props.disabled])

    const handleSearchCity = (query: string) => {
        dispatch(actions.fetch({ query }))
    }

    return (
        <Select
            ref={refSelect}
            key={key.toString()}
            labelInValue
            showSearch
            loading={isFetching}
            placeholder={'Начните вводить город'}
            onSearch={handleSearchCity}
            notFoundContent={
                data.length === 0 && isFetching ? <Spin size={'small'} /> : null
            }
            className={classNames({
                'authorization-city-selector': props.isTransparent
            })}
            suffixIcon={null}
            options={[...data]
                .filter(city => !!city.data.city_with_type)
                .map(city => ({
                    label: city.data.city_with_type,
                    value: city.data.city_with_type,
                    caption: city.data.city,
                    location: `${city.data.geo_lat}-${city.data.geo_lon}`
                }))}
            {...props}
        />
    )
}
