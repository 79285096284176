import type { SagaIterator } from '@redux-saga/core'
import {
    all,
    call,
    fork,
    getContext,
    put,
    select,
    takeEvery,
    delay
} from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import {
    formatApiError,
    getCompanySlug,
    sagaNotificationError
} from '../../utils/helpers'
import type {
    AddPlanSubscribePayload,
    ChangeCompanyFranchiseePayload,
    ChangeCompanyUserPayload,
    ChangePropertyCompanyPayload,
    ClearPropertyCompanyPayload,
    CreateInvitationPayload,
    CreatePropertyCompanyPayload,
    DeleteLogoPayload,
    DeletePlanSubscriptionsPayload,
    FetchAnalyticsPayload,
    FetchCompanyEventsPayload,
    FetchCompanyPayload,
    FetchInvitationPayload,
    FetchPaymentHistoryPayload,
    FetchPlanSubscriptionsPayload,
    GetPaymentUrlPayload,
    IClearAdminMessagePayload,
    IPayment,
    PaymentFromBalancePayload,
    RemoveOwnerPayload,
    SendPromoCodePayload,
    UploadLogoPayload,
    UploadMediaImagePayload
} from './types'
import type { SaveCompanyPayload } from '../CompanyWizard/types'
import type { PayloadAction } from '@reduxjs/toolkit'
import { companySelector } from './selectors'
import * as effects from 'redux-saga/effects'
import type { ICompany } from '../Companies/types.ts'

export function* handleFetchCompany(
    action: PayloadAction<FetchCompanyPayload>
): SagaIterator {
    try {
        const { data } = yield call(api.fetchCompany, action.payload)
        let leadCoast = data.lead_coast
        if (!data.lead_coast) {
            const { data: dataLeadCoast } = yield call(
                api.getCompanyLeadCoast,
                data.company_id
            )
            leadCoast = dataLeadCoast.data.price
        }
        yield put(
            actions.fetchCompanySuccess({
                data,
                leadCoast
            })
        )
        if (data.activePlanSubscription) {
            yield put(actions.fetchInvitation({ company_id: action.payload }))
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchCompanyFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCompany(): SagaIterator {
    yield takeEvery(actions.fetchCompany, handleFetchCompany)
}

export function* handleFetchCompanyEvents(
    action: PayloadAction<FetchCompanyEventsPayload>
): SagaIterator {
    try {
        const { data } = yield call(
            api.fetchCompanyEvents,
            action.payload.page,
            action.payload.pageSize,
            action.payload.companyId
        )
        yield put(
            actions.fetchCompanyEventsSuccess({
                data: data.data,
                meta: data.meta
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchCompanyEventsFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCompanyEvents(): SagaIterator {
    yield takeEvery(actions.fetchCompanyEvents, handleFetchCompanyEvents)
}

export function* handleSaveCompany(
    action: PayloadAction<SaveCompanyPayload>
): SagaIterator {
    try {
        yield call(
            api.saveCompany,
            action.payload.company_id,
            action.payload.form
        )

        yield put(
            actions.saveCompanySuccess({
                isLastStep: action.payload.isLastStep
            })
        )

        yield put(actions.fetchCompany(action.payload.company_id))

        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.saveCompanyFailure({
                error: message
            })
        )
    }
}

export function* watchSaveCompany(): SagaIterator {
    yield takeEvery(actions.saveCompany, handleSaveCompany)
}

function* fetchAnalytics(
    action: PayloadAction<FetchAnalyticsPayload>
): SagaIterator {
    try {
        const { only, company, events } = action.payload

        const calls = [
            call(
                api.fetchAnalyticsCompany,
                company.companyId,
                company.dateStart,
                company.dateEnd
            ),
            call(
                api.fetchAnalyticsEvents,
                events.events.join(','),
                events.dateStart,
                events.dateEnd
            )
        ]

        const fetchIndexes = only ? [only === 'company' ? 0 : 1] : [0, 1]

        const fetches = fetchIndexes.map(index => calls[index])

        const responses = yield all(fetches)

        const analyticsCompany = fetchIndexes.includes(0)
            ? responses[fetchIndexes.indexOf(0)].data
            : []
        const analyticsEvents = fetchIndexes.includes(1)
            ? responses[fetchIndexes.indexOf(1)].data
            : []

        yield put(
            actions.fetchAnalyticsSuccess({
                only,
                analyticsCompany,
                analyticsEvents
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchAnalyticsFailure({
                only: action.payload.only,
                error: message
            })
        )
    }
}

export function* watchFetchAnalytics(): SagaIterator {
    yield takeEvery(actions.fetchAnalytics, fetchAnalytics)
}

function* fetchPlanSubscriptions(
    action: PayloadAction<FetchPlanSubscriptionsPayload>
): SagaIterator {
    try {
        const planSubscriptions = yield call(
            api.fetchPlanSubscriptions,
            action.payload.company_id,
            action.payload.page
        )
        yield put(
            actions.fetchPlanSubscriptionsSuccess({
                data: planSubscriptions.data
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchPlanSubscriptionsFailure({
                error: message
            })
        )
    }
}

export function* watchFetchPlanSubscriptions(): SagaIterator {
    yield takeEvery(actions.fetchPlanSubscriptions, fetchPlanSubscriptions)
}

function* deletePlanSubscriptions(
    action: PayloadAction<DeletePlanSubscriptionsPayload>
): SagaIterator {
    const { company_id, plan_subscription_id } = action.payload

    try {
        yield call(
            api.deletePlanSubscriptions,
            company_id,
            plan_subscription_id
        )
        yield put(
            actions.deletePlanSubscriptionsSuccess({
                plan_subscription_id
            })
        )
        yield put(actions.fetchPlanSubscriptions({ company_id, page: 1 }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.deletePlanSubscriptionsFailure({
                plan_subscription_id,
                error: message
            })
        )
    }
}

export function* watchDeletePlanSubscriptions(): SagaIterator {
    yield takeEvery(actions.deletePlanSubscriptions, deletePlanSubscriptions)
}

function* createCompanyProperty(
    action: PayloadAction<CreatePropertyCompanyPayload>
): SagaIterator {
    const { company_id, company_property_id, value } = action.payload

    try {
        const { data } = yield call(
            api.createCompanyProperty,
            company_id,
            company_property_id,
            value
        )
        yield put(
            actions.createCompanyPropertySuccess({
                company_property_id,
                data
            })
        )
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createCompanyPropertyFailure({
                company_property_id,
                error: message
            })
        )
    }
}

export function* watchCreateCompanyProperty(): SagaIterator {
    yield takeEvery(actions.createCompanyProperty, createCompanyProperty)
}

function* changeCompanyProperty(
    action: PayloadAction<ChangePropertyCompanyPayload>
): SagaIterator {
    const {
        company_id,
        company_property_id,
        company_property_option_id,
        value
    } = action.payload

    try {
        const { data } = yield call(
            api.changeCompanyProperty,
            company_id,
            company_property_id,
            company_property_option_id,
            value
        )
        yield put(
            actions.changeCompanyPropertySuccess({
                company_property_id,
                data
            })
        )
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.changeCompanyPropertyFailure({
                company_property_id,
                error: message
            })
        )
    }
}

export function* watchChangeCompanyProperty(): SagaIterator {
    yield takeEvery(actions.changeCompanyProperty, changeCompanyProperty)
}

function* clearCompanyProperty(
    action: PayloadAction<ClearPropertyCompanyPayload>
): SagaIterator {
    const { company_id, company_property_id, company_property_option_id } =
        action.payload

    try {
        yield call(
            api.clearCompanyProperty,
            company_id,
            company_property_option_id
        )
        yield put(
            actions.clearCompanyPropertySuccess({
                company_property_id
            })
        )
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.clearCompanyPropertyFailure({
                company_property_id,
                error: message
            })
        )
    }
}

export function* watchClearCompanyProperty(): SagaIterator {
    yield takeEvery(actions.clearCompanyProperty, clearCompanyProperty)
}

function* changeCompanyOwner(
    action: PayloadAction<ChangeCompanyUserPayload>
): SagaIterator {
    try {
        const { company_id, business_account_id } = action.payload

        yield call(api.changeCompanyOwner, company_id, business_account_id)
        yield put(actions.changeCompanyOwnerSuccess())
        yield put(actions.fetchCompany(company_id))

        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.changeCompanyOwnerFailure({
                error: message
            })
        )
    }
}

export function* watchChangeCompanyOwner(): SagaIterator {
    yield takeEvery(actions.changeCompanyOwner, changeCompanyOwner)
}

function* handleChangeCompanyFranchisee(
    action: PayloadAction<ChangeCompanyFranchiseePayload>
): SagaIterator {
    try {
        const { company_id, franchisee_id } = action.payload
        yield call(api.changeCompanyFranchisee, company_id, franchisee_id)
        yield put(actions.changeCompanyFranchiseeSuccess())
        yield put(actions.fetchCompany(company_id))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.changeCompanyFranchiseeFailure({
                error: message
            })
        )
    }
}

export function* watchChangeCompanyFranchisee(): SagaIterator {
    yield takeEvery(
        actions.changeCompanyFranchisee,
        handleChangeCompanyFranchisee
    )
}

function* addPlanSubscribe(
    action: PayloadAction<AddPlanSubscribePayload>
): SagaIterator {
    try {
        const { company_id, plan, start, end, comment } = action.payload

        yield call(api.addPlanSubscribe, company_id, plan, start, end, comment)

        yield put(actions.addPlanSubscribeSuccess())
        yield put(actions.fetchPlanSubscriptions({ company_id, page: 1 }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.addPlanSubscribeFailure({
                error: message
            })
        )
    }
}

export function* watchAddPlanSubscribe(): SagaIterator {
    yield takeEvery(actions.addPlanSubscribe, addPlanSubscribe)
}

function* fetchPaymentHistory(
    action: PayloadAction<FetchPaymentHistoryPayload>
): SagaIterator {
    try {
        const { data } = yield call(
            api.fetchPaymentHistory,
            action.payload.company_id,
            action.payload.page,
            action.payload.perPage
        )
        yield put(
            actions.fetchPaymentHistorySuccess({
                data
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchPaymentHistoryFailure({
                error: message
            })
        )
    }
}

export function* watchFetchPaymentHistory(): SagaIterator {
    yield takeEvery(actions.fetchPaymentHistory, fetchPaymentHistory)
}

export function* handleFetchInvitation(
    action: PayloadAction<FetchInvitationPayload>
): SagaIterator {
    try {
        const { data } = yield call(
            api.fetchInvitation,
            action.payload.company_id
        )
        yield put(
            actions.fetchInvitationSuccess({
                data
            })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        if (code !== 404) {
            yield call(sagaNotificationError, message, error)
        }
        yield put(
            actions.fetchInvitationFailure({
                error: message
            })
        )
    }
}

export function* watchFetchInvitation(): SagaIterator {
    yield takeEvery(actions.fetchInvitation, handleFetchInvitation)
}

export function* handleCreateInvitation(
    action: PayloadAction<CreateInvitationPayload>
): SagaIterator {
    try {
        const { company_id, invitation_text } = action.payload
        const { data: createData } = yield call(
            api.createInvitation,
            company_id,
            invitation_text
        )

        const { currentCompany }: { currentCompany: ICompany } =
            yield select(companySelector)

        if (!currentCompany) {
            throw new Error('Компания не найдена!')
        }

        const {
            data: { shortLink }
        } = yield call(
            api.createShortDynamicLink,
            createData.referral_code,
            getCompanySlug(
                currentCompany.slug,
                currentCompany.short_id,
                currentCompany.company_id
            ) || '',
            currentCompany.name,
            currentCompany.about,
            currentCompany.main_image_thumbnail
        )

        const { data } = yield call(
            api.updateInvitation,
            company_id,
            shortLink.replace(/https:\/\/.*\//, '')
        )

        yield put(actions.createInvitationSuccess({ data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createInvitationFailure({
                error: message
            })
        )
    }
}

export function* watchCreateInvitation(): SagaIterator {
    yield takeEvery(actions.createInvitation, handleCreateInvitation)
}

export function* handleFetchPlans(): SagaIterator {
    try {
        const { data } = yield call(api.fetchPlan)
        yield put(
            actions.fetchPlansSuccess({
                data
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchPlansFailure({
                error: message
            })
        )
    }
}

export function* watchFetchPlans(): SagaIterator {
    yield takeEvery(actions.fetchPlans, handleFetchPlans)
}

export function* handleSendPromoCode(
    action: PayloadAction<SendPromoCodePayload>
): SagaIterator {
    try {
        const { data } = yield call(api.sendPromoCode, action.payload.code)
        yield put(
            actions.sendPromoCodeSuccess({
                data
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.sendPromoCodeFailure({
                error: message
            })
        )
    }
}

export function* watchSendPromoCode(): SagaIterator {
    yield takeEvery(actions.sendPromoCode, handleSendPromoCode)
}

export function* handleGetPaymentUrl(
    action: PayloadAction<GetPaymentUrlPayload>
): SagaIterator {
    try {
        const { plan_id, companies, promocode_id } = action.payload
        const { data }: { data: IPayment } = yield call(
            api.getPayment,
            plan_id,
            companies,
            promocode_id
        )
        yield put(
            actions.getPaymentUrlSuccess({
                payment_url: data.payments[0].payment_url
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.getPaymentUrlFailure({
                error: message
            })
        )
    }
}

export function* watchGetPaymentUrl(): SagaIterator {
    yield takeEvery(actions.getPaymentUrl, handleGetPaymentUrl)
}

export function* handleUploadLogo(
    action: PayloadAction<UploadLogoPayload>
): SagaIterator {
    try {
        yield call(
            api.uploadLogo,
            action.payload.company_id,
            action.payload.image
        )
        yield put(actions.uploadLogoSuccess())
        yield put(actions.fetchCompany(action.payload.company_id))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.uploadLogoFailure({
                error: message
            })
        )
    }
}

export function* watchUploadLogo(): SagaIterator {
    yield takeEvery(actions.uploadLogo, handleUploadLogo)
}

export function* handleDeleteLogo(
    action: PayloadAction<DeleteLogoPayload>
): SagaIterator {
    try {
        yield call(api.deleteLogo, action.payload.company_id)
        yield put(actions.deleteLogoSuccess())
        yield put(actions.fetchCompany(action.payload.company_id))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.deleteLogoFailure({
                error: message
            })
        )
    }
}

export function* watchDeleteLogo(): SagaIterator {
    yield takeEvery(actions.deleteLogo, handleDeleteLogo)
}

export function* handleRemoveOwner(
    action: PayloadAction<RemoveOwnerPayload>
): SagaIterator {
    try {
        yield call(api.removeOwner, action.payload.company_id)
        yield put(actions.removeOwnerSuccess())
        yield put(actions.fetchCompany(action.payload.company_id))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeOwnerFailure({
                error: message
            })
        )
    }
}

export function* watchRemoveOwner(): SagaIterator {
    yield takeEvery(actions.removeOwner, handleRemoveOwner)
}

export function* handleUploadMediaImage(
    action: PayloadAction<UploadMediaImagePayload>
): SagaIterator {
    try {
        const { data } = yield effects.call(
            api.uploadMediaImage,
            action.payload.image
        )
        yield put(
            actions.uploadMediaImageSuccess({
                data
            })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.uploadMediaImageFailure({
                error: message
            })
        )
    }
}

export function* watchUploadMediaImage(): SagaIterator {
    yield takeEvery(actions.uploadMediaImage, handleUploadMediaImage)
}

function* clearAdminMessage(
    action: PayloadAction<IClearAdminMessagePayload>
): SagaIterator {
    try {
        yield call(api.clearAdminMessage, action.payload.company_id)
        yield put(actions.clearAdminMessageSuccess())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.clearAdminMessageFailure({
                error: message
            })
        )
    }
}

export function* watchClearAdminMessage(): SagaIterator {
    yield takeEvery(actions.clearAdminMessage, clearAdminMessage)
}

export function* handlePaymentFromBalance(
    action: PayloadAction<PaymentFromBalancePayload>
): SagaIterator {
    try {
        const { plan_id, companies } = action.payload
        yield call(api.paymentFromBalance, plan_id, companies)
        yield delay(2000)
        yield put(actions.paymentFromBalanceSuccess())
        window.location.href = `/companies/${companies[0]}`
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.paymentFromBalanceFailure({
                error: message
            })
        )
    }
}

export function* watchPaymentFromBalance(): SagaIterator {
    yield takeEvery(actions.paymentFromBalance, handlePaymentFromBalance)
}

export default function* watchCompany(): SagaIterator {
    yield all([
        fork(watchFetchCompany),
        fork(watchFetchCompanyEvents),
        fork(watchSaveCompany),
        fork(watchFetchAnalytics),
        fork(watchFetchPlanSubscriptions),
        fork(watchDeletePlanSubscriptions),
        fork(watchCreateCompanyProperty),
        fork(watchChangeCompanyProperty),
        fork(watchClearCompanyProperty),
        fork(watchChangeCompanyOwner),
        fork(watchAddPlanSubscribe),
        fork(watchFetchPaymentHistory),
        fork(watchFetchInvitation),
        fork(watchCreateInvitation),
        fork(watchFetchPlans),
        fork(watchSendPromoCode),
        fork(watchGetPaymentUrl),
        fork(watchUploadLogo),
        fork(watchDeleteLogo),
        fork(watchRemoveOwner),
        fork(watchChangeCompanyFranchisee),
        fork(watchUploadMediaImage),
        fork(watchClearAdminMessage),
        fork(watchPaymentFromBalance)
    ])
}
