import { isAxiosError } from 'axios'
import type { Key, ReactElement, ReactNode } from 'react'
import type { MenuProps } from 'antd'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import type { IProfile } from '../containers/Authorization/types'
import type { DaDataAddress } from 'react-dadata'
import { getContext } from 'redux-saga/effects'
import * as Sentry from '@sentry/react'
import type { SagaIterator } from '@redux-saga/core'
import type { DataNode } from 'antd/es/tree'
import type { IBanner } from '../containers/BannersBlock/types.ts'
import 'dayjs/locale/ru.js'
import type { ICompany } from '../containers/Companies/types.ts'
import type { IEvent } from '../containers/Events/types.ts'

dayjs.extend(localeData)
dayjs.locale('ru')

export type MenuItem = Required<MenuProps>['items'][number]

export const youTubeRegExp =
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?=]*)?/

export const formatApiError = (error: unknown) => {
    let message = '',
        code

    if (isAxiosError(error)) {
        message = error.response?.data.message ?? error.message
        code = error.response?.status
    } else {
        message = String(error)
    }

    return {
        message,
        code
    }
}

export const mapMenuItem = (
    label: ReactNode,
    key: Key,
    icon?: ReactNode,
    children?: MenuItem[],
    type?: 'group'
): MenuItem => {
    return {
        key,
        icon,
        children,
        label,
        type
    } as MenuItem
}

export const getRoleName = (roles: string[]): string => {
    if (roles.includes('admin') || roles.includes('admin_ro')) {
        return 'Администратор'
    }
    if (roles.includes('franchisee')) {
        return 'Франчайзи'
    }
    return 'Пользователь'
}

export const getRole = (roles: string[]): 'admin' | 'franchisee' | 'user' => {
    if (roles.includes('admin') || roles.includes('admin_ro')) {
        return 'admin'
    }
    if (roles.includes('franchisee')) {
        return 'franchisee'
    }
    return 'user'
}

export const companyTypes = {
    offline: 'Офлайн',
    online: 'Онлайн'
}

export const eventTypes = {
    offline: 'Офлайн',
    online: 'Онлайн'
}

export const eventBadges = {
    offer: 'Акция',
    free: 'Бесплатно',
    online: 'Онлайн',
    accept_discount: 'Скидка',
    discount: 'Скидка',
    delivery: 'На дом'
}

export const eventStatuses = {
    active: 'Активный',
    draft: 'Черновик'
}

export const getYoutubeCover = (videoId: string): string => {
    return `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`
}

export const timeToDayJs = (time: string) => {
    const [hours, minutes] = time.split(':')
    return dayjs()
        .set('hours', parseInt(hours))
        .set('minutes', parseInt(minutes))
        .set('seconds', 0)
}

export const checkAdminRole = (profile: IProfile | null): boolean => {
    if (profile === null) {
        return false
    }
    return profile.roles.some(role => role === 'admin' || role === 'admin_ro')
}

export const checkFranchiseeRole = (profile: IProfile | null): boolean => {
    if (profile === null) {
        return false
    }
    return profile.roles.some(role => role === 'franchisee')
}

export const ignoreKeys = ['page', 'pageSize']

const SHORT_TYPES = new Set([
    'аобл',
    'респ',
    'вл',
    'г',
    'д',
    'двлд',
    'днп',
    'дор',
    'дп',
    'жт',
    'им',
    'к',
    'кв',
    'км',
    'комн',
    'кп',
    'лпх',
    'м',
    'мкр',
    'наб',
    'нп',
    'обл',
    'оф',
    'п',
    'пгт',
    'пер',
    'пл',
    'платф',
    'рзд',
    'рп',
    'с',
    'сл',
    'снт',
    'ст',
    'стр',
    'тер',
    'туп',
    'ул',
    'х',
    'ш'
])

function addDot(word: string): string {
    if (SHORT_TYPES.has(word)) {
        return word + '.'
    } else {
        return word
    }
}

export function dotify(address: string): string | undefined {
    const parts = address.split(',').map(function (part) {
        const words = part.split(' ').map(addDot)
        return words.join(' ')
    })
    return parts.join(',')
}

export const mapDadataGeoObjects = (
    suggestions: DaDataAddress
): { name: string | undefined; value: string | undefined } => {
    const {
        city,
        region_with_type,
        settlement,
        area_with_type,
        country,
        street_with_type,
        house,
        house_type,
        block_type,
        block,
        settlement_with_type
    } = suggestions

    const textComma = (value: string | null): string => {
        return value ? `${value}, ` : ''
    }

    const textCommaValue = (
        value: string | null,
        valueHouse: string | null
    ): string | null => {
        return value
            ? valueHouse
                ? `${textComma(value)} ${house_type} ${valueHouse} ${
                      block_type || ''
                  } ${block || ''}`
                : value
            : settlement_with_type || city
    }

    const valueDotify = dotify(
        `${textComma(area_with_type || city || settlement)}${textComma(
            region_with_type
        )}${country}`
    )

    return {
        name: dotify(textCommaValue(street_with_type, house) ?? '') || country,
        value: valueDotify !== 'undefined' ? valueDotify : undefined
    }
}

export const analyticsMainNames = {
    app_report_view: 'Просмотры компании',
    app_report_communicate: 'Связались (блок контакты)',
    app_report_site: 'Перешли на внешний сайт',
    app_report_click_action: 'Нажали кнопку действия'
}

export const analyticsSecondaryNames = {
    app_report_share: 'Поделились',
    app_report_favorites: 'Компания добавлена в закладки',
    api_fixed_company_users:
        'Количество клиентов, прикрепленных через реферальную ссылку',
    api_company_sent_push_notifications:
        'Отправлено push-уведомлений прикрепленным клиентам',
    api_favorite_companies: 'Предложение добавленное в избранное',
    app_report_show_discount: 'Воспользовались скидкой при показе приложения'
    // app_report_chat: 'Создали внутренний чат',
}

export const analyticsEventsNames = {
    app_report_view: 'Просмотры',
    app_report_communicate: 'Связались',
    app_report_promocode: 'Выдано Промокодов',
    app_report_site: 'Перешли на внешний сайт'
}

export const reportsJournalNames = {
    created_count: 'Создано статей',
    paid_count: 'Количество платных статей в периоде',
    published_count: 'Количество статей опубликованных за период',
    user_posts_count: 'Количество статей опубликованных компаниями',
    view_count: 'Количество прочтений итого по этим статьям',
    views_paid_count: 'Количество прочтений итого по платным статьям'
}

export function* sagaNotificationError(
    message: string,
    error: unknown,
    noSentry?: boolean,
    btn?: ReactElement
): SagaIterator {
    const notification = yield getContext('notification')

    const isErrorAuthorization = message?.startsWith(
        'По введенным данным пользователь не найден'
    )

    notification.open({
        type: isErrorAuthorization ? undefined : 'error',
        message: isErrorAuthorization
            ? 'Такой пользователь не найден'
            : 'Произошла ошибка',
        description: message,
        btn
    })

    if (noSentry) {
        Sentry.captureException(error)
    }
}

export const declOfNum = (n: number, titles: string[]): string => {
    return titles[
        n % 10 === 1 && n % 100 !== 11
            ? 0
            : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
              ? 1
              : 2
    ]
}

// Banners
export const userBlocks = ['blok_adminka_shagi']
export const adminFranchiseeBlocks = ['adminka_dly_admin_franch']
export const adminFranchiseeHorizontalBlocks = [
    'land_franch_1',
    'land_franch_2',
    'land_franch_3',
    'land_franch_4'
]
export const horizontalBlocks = [
    'blok_adminka_premium',
    'blok_adminka_novosti',
    'blok_adminka_avtor',
    'blok_adminka_partner',
    'blok_adminka_obuchenie'
]
export const verticalBlocks = ['blok_adminka_sovet']
export const additionalBlocks = ['blok_adminka_vopros']
export const gridBanners = ['blok_adminka_premium']

export const transparentBgContainerPaths = [
    '/',
    '/companies',
    '/events',
    '/promocodes',
    '/promocodes/report',
    '/promocodes/agents',
    '/advertising',
    '/advertising/report',
    '/blog',
    '/sales/my-requests'
]

export const pagesWithoutContainer = [
    '/companies',
    '/events',
    '/promocodes',
    '/promocodes/report',
    '/promocodes/agents',
    '/advertising',
    '/advertising/report',
    '/blog',
    '/sales/my-requests'
]

export const getTreeParentKey = (key: Key, tree: DataNode[]): Key => {
    let parentKey: Key
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        if (node.children) {
            if (node.children.some(item => item.key === key)) {
                parentKey = node.key
            } else if (getTreeParentKey(key, node.children)) {
                parentKey = getTreeParentKey(key, node.children)
            }
        }
    }
    return parentKey!
}

export const hasTreeChildrenChecked = (
    tree: DataNode,
    selectedKeys: Key[]
): boolean => {
    if (selectedKeys.includes(tree.key)) {
        return true
    }

    if (Array.isArray(tree.children)) {
        return tree.children.some(item =>
            hasTreeChildrenChecked(item, selectedKeys)
        )
    }

    return false
}

export const getCompanySlug = (
    slug: string | undefined | null,
    short_id: string | undefined | null,
    company_id: string | undefined | null
): string | undefined | null => {
    const shortId = short_id ? `id${short_id}` : undefined
    return slug ?? shortId ?? company_id
}

export const convertAge = (ageCategory: string, ages: number[]): string => {
    if (ageCategory === 'adult') {
        return '18+'
    }

    if (ageCategory === 'all') {
        return '0+'
    }

    return `${ages[0]}+`
}

export const formatAvg = (value: string): string | number => {
    return value ? parseFloat(value).toFixed(0) : 0
}

export const generatePromoCode = (): string => {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

    for (let i = 0; i < 8; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
}

export const sortBanners = (banners: IBanner[]): IBanner[] => {
    return banners.slice().sort((a, b) => a.position - b.position)
}

export const regExpTime = /^([01][0-9]|2[0-3]):([0-5][0-9])$/

export const weekdaysTemplate: {
    name: string
    time: [string, string]
    isOpen: boolean
}[] = dayjs.weekdays(true).map(name => ({
    name: name.charAt(0).toUpperCase() + name.slice(1),
    time: ['', ''],
    isOpen: false
}))

export const advertisingStatus = {
    1: 'Черновик',
    2: 'На модерации',
    3: 'Идут показы',
    4: 'Остановлена'
}

export const blogStatus = {
    1: 'Черновик',
    3: 'Опубликовано',
    4: 'Не опубликовано'
}

export const advertisingStatusColor = {
    1: 'rgba(0, 0, 0, 0.45)',
    2: '#faad14',
    3: '#52c41a',
    4: '#ff4d4f'
}

export const bannerTypes = {
    akcia: 'Акция',
    free: 'Бесплатно',
    promo: 'Промо',
    discount: 'Скидка',
    new: 'Новинка'
}

export const statsSlows = {
    app_report_view: 'Просмотров',
    app_report_share: 'Поделились',
    app_report_favorites: 'Добавлено в избранное',
    app_report_review: 'Оставили отзывов',
    app_report_communicate: 'Связались'
}

export function shortYandexGeocoder(address: string) {
    const TYPES = {
        Республика: 'Респ',
        область: 'обл.',
        район: 'р-н'
    }

    for (const [key, value] of Object.entries(TYPES)) {
        address = address.replace(key, value)
    }

    return address
}

export function getVkEmbedLink(url: string): string {
    const meta = url.match(/-\d+_\d+/)
    if (Array.isArray(meta)) {
        const [group, video] = meta[0].split('_')
        return `https://vk.com/video_ext.php?oid=${group}&id=${video}&hd=2`
    }
    return ''
}

export const getCroppedImg = async (
    src: string,
    pixelCrop: { x: number; y: number; width: number; height: number }
) => {
    const image: HTMLImageElement = await new Promise(resolve => {
        const img = new Image()
        img.setAttribute('crossorigin', 'anonymous')
        img.src = src
        img.onload = () => resolve(img)
    })

    const targetX = pixelCrop.x
    const targetY = pixelCrop.y
    const targetWidth = pixelCrop.width
    const targetHeight = pixelCrop.height

    const canvas = document.createElement('canvas')
    canvas.width = targetWidth
    canvas.height = targetHeight
    const ctx = canvas.getContext('2d')

    ctx?.drawImage(
        image,
        targetX,
        targetY,
        targetWidth,
        targetHeight,
        0,
        0,
        targetWidth,
        targetHeight
    )

    return canvas.toDataURL()
}

export const toRuble = (value: number): number => value / 100

export const fromRuble = (value: number): number => value * 100

export const makeURLCompany = (
    company: ICompany,
    isAddBaseUrl?: boolean
): string => {
    return `${isAddBaseUrl ? process.env.SITE_URL : ''}/${company.dadata_area ? (company.dadata_area?.short_slug ?? company.dadata_area?.slug) : 'rossiya'}/${company.slug_title}`
}

export const makeURLEvent = (event: IEvent, isAddBaseUrl?: boolean): string => {
    return `${isAddBaseUrl ? process.env.SITE_URL : ''}/${event.dadata_area ? (event.dadata_area.short_slug ?? event.dadata_area.slug) : 'rossiya'}/e/${event.slug_title}`
}

