import type { ReactElement } from 'react'
import { useEffect } from 'react'
import {
    Button,
    Col,
    Divider,
    Form,
    InputNumber,
    Modal,
    Pagination,
    Row,
    Table,
    Typography
} from 'antd'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks/useAppSelector'
import { actions } from './slice'
import type { ColumnsType } from 'antd/es/table'
import { useSearchParams } from 'react-router-dom'
import { ignoreKeys } from '../../utils/helpers'
import dayjs from 'dayjs'
import type { IUser } from './types'
import UsersFilter from '../../components/UsersFilter'
import { usersSelector } from './selectors.ts'
import {
    CheckOutlined,
    CloseOutlined,
    CloudDownloadOutlined,
    EditOutlined
} from '@ant-design/icons'
import CustomEmpty from '../../components/CustomEmpty'

const { Title } = Typography

const columns = (openEditUser: (user: IUser) => void): ColumnsType<IUser> => [
    {
        title: 'Имя',
        dataIndex: 'first_name',
        key: 'first_name',
        render: data => data ?? '—'
    },
    {
        title: 'Фамилия',
        dataIndex: 'last_name',
        key: 'last_name',
        render: data => data ?? '—'
    },
    {
        title: 'Бизнес',
        dataIndex: '',
        key: 'businessAccount',
        align: 'center',
        render: data => (data.businessAccount ? 'Да' : 'Нет')
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 180,
        render: data => data ?? '—'
    },
    {
        title: 'Телефон',
        dataIndex: '',
        key: 'phone',
        width: 200,
        align: 'center',
        render: (user: IUser): string =>
            user.businessAccount?.phone ?? user.phone ?? '—'
    },
    {
        title: 'День рождения',
        dataIndex: 'birthday',
        key: 'birthday',
        align: 'center',
        render: value => (value ? value.replace(/-/g, '.') : '—')
    },
    {
        title: 'Баланс',
        dataIndex: 'balance',
        key: 'balance',
        align: 'center',
        render: value =>
            value ? `${(value.amount / 100).toLocaleString()} ₽` : '—'
    },
    {
        title: 'Создана компания',
        dataIndex: 'companies_count',
        key: 'companies_count',
        align: 'center',
        render: value =>
            value > 0 ? (
                <CheckOutlined style={{ color: 'green' }} />
            ) : (
                <CloseOutlined style={{ color: 'red' }} />
            )
    },
    {
        title: 'Город',
        dataIndex: 'homeAddress',
        key: 'homeAddress',
        align: 'center',
        render: data => data?.caption ?? '—'
    },
    {
        title: 'Дата регистрации',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        render: value => dayjs(value).format('DD.MM.YYYY HH:mm')
    },
    {
        title: 'Обновлен',
        dataIndex: 'updated_at',
        key: 'updated_at',
        align: 'center',
        render: value => dayjs(value).format('DD.MM.YYYY HH:mm')
    },
    {
        title: '',
        key: 'nav',
        align: 'center',
        render: value => (
            <Button
                onClick={() => openEditUser(value)}
                type={'primary'}
                icon={<EditOutlined />}
            />
        )
    }
]

export default function Users(): ReactElement {
    const [searchParams, setSearchParams] = useSearchParams()
    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    const dispatch = useDispatch()

    const {
        data,
        meta,
        isFetching,
        isDownloadExcel,
        editUser,
        isUserEditProcess
    } = useAppSelector(usersSelector)

    const getSearchFilter = (): Record<string, string> => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        return filter
    }

    useEffect(() => {
        const filter = getSearchFilter()
        dispatch(actions.fetch({ page, pageSize, filter }))
        setSearchParams({
            ...filter,
            ...(page !== 1 ? { page: page.toString() } : {}),
            ...(pageSize !== 10 ? { pageSize: pageSize.toString() } : {})
        })
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        setSearchParams({
            ...filter,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    const handleDownloadExcel = () => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        dispatch(actions.downloadExcel({ page, pageSize, filter }))
    }

    const openEditUser = (user: IUser): void => {
        dispatch(actions.setEditUser(user))
    }

    const handleSaveUser = (form: Record<string, unknown>): void => {
        if (editUser) {
            const filter = getSearchFilter()
            dispatch(
                actions.editUser({
                    userId: editUser.userId,
                    form: {
                        ...form,
                        balance: form.balance
                            ? parseInt(form.balance as string) * 100
                            : undefined
                    },
                    fetchPage: {
                        page,
                        pageSize,
                        filter
                    }
                })
            )
        }
    }

    return (
        <>
            <Title level={3} style={{ margin: '0 0 25px' }}>
                {'Поиск пользователей'}
            </Title>
            <UsersFilter />
            <Divider style={{ background: '#ff57a5' }} />
            <Row
                style={{ marginBottom: 20 }}
                justify={'space-between'}
                align={'middle'}
            >
                <Col>
                    {'Найдено пользователей: '}
                    <b>{meta?.total ?? 0}</b>
                </Col>
                <Col>
                    <Button
                        loading={isDownloadExcel}
                        onClick={handleDownloadExcel}
                        type={'primary'}
                        icon={<CloudDownloadOutlined />}
                    >
                        {'Скачать (.xlsx)'}
                    </Button>
                </Col>
            </Row>
            <Table
                dataSource={data}
                columns={columns(openEditUser)}
                rowKey={'user_id'}
                loading={isFetching}
                pagination={false}
                sticky={{ offsetHeader: 0 }}
                locale={{
                    emptyText: <CustomEmpty description={'Нет данных'} />
                }}
                footer={() => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Pagination
                            showQuickJumper
                            current={page}
                            pageSize={pageSize}
                            defaultCurrent={1}
                            defaultPageSize={pageSize}
                            total={meta?.total}
                            onChange={handleChangePagination}
                        />
                    </div>
                )}
            />
            <Modal
                open={!!editUser}
                title={'Редактирование пользователя'}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onCancel={() => dispatch(actions.setEditUser(null))}
            >
                <Form
                    key={editUser?.userId}
                    initialValues={{
                        balance: editUser?.balance?.amount
                            ? editUser.balance.amount / 100
                            : undefined
                    }}
                    onFinish={handleSaveUser}
                    layout={'vertical'}
                >
                    <Form.Item name={'balance'} label={'Баланс'}>
                        <InputNumber addonAfter={'₽'} placeholder={'Баланс'} />
                    </Form.Item>
                    <Row justify={'end'}>
                        <Button
                            htmlType={'submit'}
                            loading={isUserEditProcess}
                            type={'primary'}
                        >
                            {'Сохранить изменения'}
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
